function SlimNav()
{

	var self = this,
		w = $( window ).width(),
		$slimNav = $('.slim-nav'),
		$slimNavLinks = $("nav.slim-nav-bar ul.navbar-nav>li[data-target*='subnav']>a.nav-link");

	self.resize = function()
	{
		w = $( window ).width();
	};

	self.ready = function()
	{

		if ($slimNav.length && w >= BREAKPOINT_SM) {

			$slimNavLinks.on('touchstart', function( event ){
				var $this = $(this);
				
				if ( !$this.hasClass('touched') ) {
					event.preventDefault();
					$slimNavLinks.removeClass('touched');
					$slimNavLinks.parent('li').removeClass('hover');
					$this.addClass('touched');
					$this.parent('li').addClass('hover');
				}
			});

		}
	};

	ApplicationObject.call( this );
}
SlimNav.prototype = Object.create( ApplicationObject.prototype );