  /**
* ------------------------------------------------------------------------
* Filtery Filter
* 
* Creates a simple filter via filter-nav class and data tags.
*  - Add "filter-nav" class to a div containing the filter buttons. Must have the data-filter-selector attribute which specifies which elements on the page to filter. (E.g. data-filter-selector="[data-categories]")
*  - Add "data-filter" attribute to each button/child element. Value may be a comma separated list of values to filter by. Use "all" to display all. (E.g. data-filter="Equity,Outlook")
*  - Add elements to filter specified by data-filter-selector attribute. (E.g. <div data-categories="Equity,Outlook">)
*
* Example usage:
	<ul class="nav nav-tabs nav-tabs-arrow filter-nav my-3" data-filter-selector="[data-categories]" role="tablist">
		<li class="nav-item"><a href="#" class="nav-link active" data-filter="all" data-toggle="tab" role="tab">All</a></li>
		<li class="nav-item"><a href="#" class="nav-link" data-filter="Equity" data-toggle="tab" role="tab">Equity</a></li>
		<li class="nav-item"><a href="#" class="nav-link" data-filter="Outlook" data-toggle="tab" role="tab">Outlook</a></li>
		<li class="nav-item"><a href="#" class="nav-link" data-filter="Fixed income" data-toggle="tab" role="tab">Fixed income</a></li>
	</ul>
	<div data-categories="Equity">Equity</div>
	<div data-categories="Equity,Outlook">Equity, Outlook</div>
	<div data-categories="Outlook">Outlook</div>
	<div data-categories="Equity,Fixed income">Equity, Fixed income</div>
	<div data-categories="Fixed income">Fixed income</div>
* 
* TODO: Add ability to filter by toggle buttons. Should apply filters according to filters toggled on, and all when all are toggled off.
* 
* ------------------------------------------------------------------------
*/

application.filteryFilter = new FilteryFilter();
function FilteryFilter() {

	var self = this;
	self.filterNavClassName = '.filter-nav';
	self.filterNavNodeArray;
	self.filtererArray = [];

	self.ready = function() {
		self.filterNavNodeArray = document.querySelectorAll(self.filterNavClassName);
		for (let filterNavNode of self.filterNavNodeArray) {
			self.filtererArray.push( new Filterer( filterNavNode ) );
		}
	}

	ApplicationObject.call( this );
}
FilteryFilter.prototype = Object.create( ApplicationObject.prototype );


function Filterer( _filterNavNode ) {

	var self = this;
	self.filterNavNode = _filterNavNode;

	var _filterTargetSelector;
	var _filterTargets;
	
	function init() {
		var _filterButtons = self.filterNavNode.querySelectorAll('[data-filter]');
		_filterTargetSelector = self.filterNavNode.dataset.filterSelector;
		_filterTargets = document.querySelectorAll(_filterTargetSelector);

		for (let filterButton of _filterButtons) {
			filterButton.addEventListener('click', handleFilterNavClick);
			if( window.location.hash !== undefined )
			{
				var sanitizedFilterName = $(filterButton).attr('data-filter').toLowerCase().replace(/ /g, '-');
				if( window.location.hash.indexOf( sanitizedFilterName ) > -1 )
				{
					setTimeout(function(){
						filterButton.click();
					}, 500);
				}
			}
		}

	}
	init();

	function handleFilterNavClick(e) {
		console.log('funck do it')
		var filterArray = this.dataset.filter.split(',');
		self.filterByArray(filterArray);
	}

	self.filterByArray = function(filterArray) {
		for (var i = 0; i < _filterTargets.length; i++) {
			// Get filters from specified data-filter-selector attribute. Regex removes data selector brackets.
			var filters = _filterTargets[i].getAttribute(_filterTargetSelector.replace(/\[|\]/g, ''));
			var filtersArray = filters.split(',');
			var showThis = false;
			for (var j = 0; j < filterArray.length; j++) {
				if (filterArray[j] == 'all' || filtersArray.indexOf(filterArray[j]) > -1) {
					showThis = true;
				}
			}
			_filterTargets[i].hidden = !showThis;
		}
	}

}