/*jshint loopfunc: true */
function Polls()
{
	var self = this;

	var cookieKey = 'putnam-poll'; // Site identity header for specific polls

	var pollMap = new Dictionary();

	self.ready = function()
	{
		$( 'form[ data-content = "poll" ]' ).each( function(){

			var form = $( this );

			form.on( 'submit', onPollSubmit );

			var tid = form.attr( 'data-topic' ),
				qid = form.attr( 'data-question' );
			var pollID = '.poll-{0}-{1}'.format( tid, qid );
			var cookie = '{0}-{1}-{2}'.format( cookieKey, tid, qid );

			if( checkReset() ) application.cookies.delete( cookie );

			var pollData = {};
				pollData.containerSelector = pollID;
				pollData.cookie = cookie;
				pollData.topicId = tid;
				pollData.questionId = qid;
				pollData.submitted = false;
				pollData.rendered = false;

			var oDescs = [];
			$( pollID ).find( 'input[ class = "poll-option" ]' ).each( function(){
				var obj = {};
				obj.optionId = $( this ).attr( 'value' );
				obj.label = $( this ).attr( 'description' );
				obj.colors = form.attr( 'data-colors' );
				oDescs.push( obj );
			} );

			pollData.config = oDescs;

			pollMap.add( pollID , pollData );

			if( application.cookies.read( cookie ) !== null )
			{
				pollData.submitted = true;
				requestPollResults( pollData );
			}
		} );
	};

	function requestPollResults( pData )
	{
		$.ajax( {
			headers: {
		        'Accept': 'application/json',
		        'Content-Type': 'application/json'
		    },
			url : '/survey/{0}'.format( pData.topicId ),
		    dataType : 'json',
			method : 'get'
		} )
		.done( function( response ){
			onRequestSuccess( response, 'get' );
		} )
		.fail( function(response){
			debug.log( 'didnt work' );
			debug.log( response );
		} );
	}

	function onPollSubmit( e )
	{
		e.preventDefault();



		var pollForm = e.currentTarget;

		var	tid = $( pollForm ).attr( 'data-topic' ),
				qid = $( pollForm ).attr( 'data-question' ),
				selectedOption = $( pollForm ).find( 'input[ class = "poll-option" ]:checked' ).val();
				pollID = '.poll-{0}-{1}'.format( tid, qid );

		if( selectedOption === undefined ) return;

		var pollData = pollMap.get( pollID );
		pollData.submitted = true;

		application.cookies.create( pollData.cookie, selectedOption, 365 );

		var requestData = {
			"topicId" : tid,
			"questionId" : qid,
			"optionId" : selectedOption
		};
		var requestJson = JSON.stringify( requestData );

		$( pollID ).fadeTo( 400, 0.3, function(){
			$.ajax( {
				headers: {
			        'Accept': 'application/json',
			        'Content-Type': 'application/json'
			    },
				url : '/survey/save',
				data : requestJson,
			    dataType : 'json',
				method : 'post'
			} )
			.done( function( response ){
				onRequestSuccess( response, 'post' );
			} )
			.fail( function(response){
				debug.log( 'didnt work' );
				debug.log( response );
			} );
		} );
		$( pollID ).addClass( 'mouse-disable' );
	}

	function onRequestSuccess( response, type )
	{
		if( type === 'post' && !application.cookies.exists( 'pollUId' ) )
			application.cookies.create( 'pollUId', response.userId, 365 );

		for( var i = 0; i < pollMap.length; i++ )
		{
			var pr = pollMap.getByIndex( i );

			if( pr.submitted && !pr.rendered )
			{
				var chartElement = pr.containerSelector,
					optionConfig = pr.config,
					tid = parseInt( pr.topicId ),
					qid = parseInt( pr.questionId );

				var pollSummary = response.pollSummary.filter( function(v){
					//debug.log( '{0}-{1}  :  {2}-{3}'.format( v.topidId, tid, v.quesId, qid ) );
					return ( v.topidId === tid && v.quesId === qid );
				} );

				if( pollSummary.length <= 0 ) continue;

				cData = parseResponseOptions( pollSummary, optionConfig );

				pr.chartData = cData;

				drawChart( pr, i );
			}
		}
	}

	function parseResponseOptions( oData, config )
	{

		var data = [];

		for( var i = 0; i < config.length; i++ )
		{

			var  od = oData.filter( function( v ){
				return( v.optionId == config[i].optionId );
			} )[0];

			var obj = {};
				obj.id = config[i].optionId;
				obj.label = config[i].label;
				obj.colors = config[i].colors;
				obj.value = ( od !== undefined ) ? od.optionCount : 0;
				data.push( obj );
		}
		return data;
	}

	function drawChart( pr, i )
	{
		pr.rendered = true;

		$( pr.containerSelector ).fadeTo( 400, 0, 'swing', function(){
			application.dispatchEvent( Event.ChartRequest, { element : pr.containerSelector, data : pr.chartData } );
		} );
	}

	function checkReset()
	{
		var url = window.location.href;
		if(url.indexOf('?clearpollcookies') != -1)
    		return true;
	}

	ApplicationObject.call( this );
}
Polls.prototype = Object.create( ApplicationObject.prototype );
