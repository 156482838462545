function AutoChartController()
{
	var self 	= this;
	self.charts = [];

	self.ready = function()
	{
		$('.auto-chart').each(function(index, chartElement) {
			// console.log("AutoChartController :: auto chart element", chartElement);
			self.createChart(chartElement);
		});
	}

	// Extracts series data from html table
	self.extractTableSeries = function($table, extractType, config, callback)
	{
		var series 			= [],
			rows 			= $table.find('tbody tr'),
			headerCells 	= $table.find('thead tr th, thead tr td'),
			columnData 		= [],
			hasAssetClass 	= false,
			showRow			= false;

		if($(rows).find('th:first').attr('asset-class')){
			hasAssetClass = true;
		}
		for (var i = 0; i < rows.length; i++) {
			var cells 	= $(rows[i]).find('th,td'),
				isPair 	= cells.length==2,
				label 	= $.trim($(cells[0]).text()),
				data 	= {};
			
			// Reverse Pair - Labels on left - piechart, holdings
				// [ [label, value], ... ] 
				// [ { name: label, color:#hex, y:value }, ... ] - has asset class
			if (isPair || extractType == 'holdings') {
				var value = $.trim($(cells[1]).text());
				value = parseFloat(value.substring(0, value.length - 1));
				data  = hasAssetClass ? { name: label, color:$(rows[i]).find("th > div").css("background-color"), y:value } : [label, value];
				series.push(data);

			// Point data
				// [ { x:point, y:point, z:size, name:label } ]
			} else if(config.data.chartType == 'bubble' || config.data.extractType == 'pointChart') {
				var cellData=[],
					$row=$(cells).parent('tr');
				for (m=1; m < cells.length; m++){
					var mPoint=parseFloat( $(cells[m]).html() );
					cellData.push((isNaN(mPoint)) ? null : mPoint);
				}
				data  = { 
					name:label, 
					data:[{
							x:cellData[0], 
							y:cellData[1], 
							z:cellData[2]||1,
							id:String(i)
						}], 
						color:$row.data('color'),
						xOffset:$row.data('xOffset'),
						yOffset:$row.data('yOffset')
					};
				series.push(data);

			} else if( config.data.chartType == 'mountain' ) {
				var cellData=[],
					$row=$(cells).parent('tr');

				for( var i = 1; i < cells.length; i++ )
				{
					console.log( $(cells[i]).find('[data-x]').html() );
					cellData.push( [ Date.parse( $(cells[i]).find('[data-x]').html() ),  parseFloat($(cells[i]).find('[data-y]').html()) ] );
				}

				data  = { 
					name:label, 
					data: cellData, 
					color:$row.data('color'),
					xOffset:$row.data('xOffset'),
					yOffset:$row.data('yOffset')
				};
				series.push(data);

			} 
			// Reversed rows and columns from normal
			else if(config.data.switchRowsAndColumns) {
				// Get Header
				for (var j=1; j < headerCells.length; j++) {
					if (columnData[j-1] == undefined) {
						columnData[j-1] = { name: $.trim($(headerCells[j]).text()), data: [] };
					}
				}
				// Get data
				for (k=1; k < cells.length; k++){
					var kPoint = parseFloat( $(cells[k]).html() );
					if (columnData[k-1] == undefined) {
						dataError('Format table correctly. Header/data column mismatch.')
					}
					columnData[k-1].data.push((isNaN(kPoint)) ? null : kPoint);
				}

			// Normal - Labels on top - bar chart, performance
				// [ { name: label, data:[ point, ... ]  }, ... ]
			} else {
				data.name 	= label;
				showRow 	= (data.name.indexOf("After sales charge") != -1) ? false : true;
				if (showRow) {
					data.data = [];
					for (l=1; l < cells.length; l++){
						var point=parseFloat( $(cells[l]).html() );
						data.data.push((isNaN(point)) ? null : point);
					}
					series.push(data);
				}
			}
		}
		
		console.log(series)
		callback( config, config.data.switchRowsAndColumns ? columnData : series );
	}

	self.extractURLSeries = function( element, url, config, callback )
	{
		var series = false;

		$.ajax( {
			url : url,
			method : 'GET',
			dataType : 'json',
			crossDomain : true
		} ).done( function( data ){

			if( element.data('series-parser') )
			{
				var fnName = element.data('series-parser');
				var parseFunction = window[ fnName ];
				series = parseFunction( data );
				callback( config, series );
			}
			else
			{
				switch(config.data.chartType) {
					case 'mountain':
						series =  AutoMountainChart.parseURLSeries( data );
						callback( config, series );
				}
			}

		} ).fail( function(  jqXHR, textStatus  ){
			callback( config, false );
			console.error( "failed: " + textStatus );
			console.error( jqXHR );
		} );
	}

	// Extracts x-axis categories from table headers or first column if switched
	self.extractCategories = function($table, config)
	{
		var categories 	= [],
			categoryCell = config.data.switchRowsAndColumns ? $table.find('tbody tr td:first-child') : $table.find("thead tr:first-child th"),
			startIndex 	= config.data.switchRowsAndColumns ? 0 : 1;
		for (var i = startIndex; i < categoryCell.length; i++){
			categories.push($(categoryCell[i]).text());
		}
		return categories;
	}

	// Extracts data from html element to build chart config
	self.extractOptions = function( config, ele, callback)
	{
		var $container 	= $(ele),
			extractType	= $container.data('extractType') || '',
			oldConfig	= config.data,
			newConfig	= $container.data();
		newConfig		= Object.assign(newConfig, {
			container: 				$container,
			colors: 				$container.data('colors')	? JSON.parse( $container.data('colors').replace(/'/gi,'"') ) : oldConfig.colors,
			switchRowsAndColumns: 	$container.data('switchRowsAndColumns'), // Defaults false
			tooltip: 				$container.data('tooltip')	== undefined || false, // Defaults true
			hover: 					$container.data('hover')	== undefined || false
		})
		config.data.element 	= $(ele);
		config.data 			= newConfig;
		config.data.xPlotLines = $container.data('x-plot-lines') ? window[$container.data('x-plot-lines')] : [];
		config.data.yPlotLines = $container.data('y-plot-lines') ? window[$container.data('y-plot-lines')] : [];
		config.data.yPlotBands = $container.data('y-plot-bands') ? window[$container.data('y-plot-bands')] : [];
		config.data.annotations = $container.data('annotations') ? window[$container.data('annotations')] : [];
		config.data.yLabelFormat = $container.data('y-label-format') ? window[$container.data('y-label-format')] : undefined;
		config.data.xLabelFormat = $container.data('x-label-format') ? window[$container.data('x-label-format')] : undefined;
		config.data.xTickPositioner = $container.data('x-tick-positioner') ? window[$container.data('x-tick-positioner')] : undefined;
		config.data.categories = $container.data('table') ? self.extractCategories( $( $container.data('table') ), config ) : false;
		$container.data('table')	? self.extractTableSeries( $( $container.data('table') ), extractType, config, callback ) : $container.data('url') ? self.extractURLSeries( $container, $container.data('url'), config, callback ) : false;
	}

	// Trigger to resize all charts
	self.resizeCharts = function()
	{
		for(var i=0; i < self.charts.length; i++){
			self.charts[i].resize();
		}
	}

	// Creates a highchart based on currently configured config
	self.createChart = function(chartElement)
	{
		var config = new AutoChartConfig();
		// Extract options
		self.extractOptions( config, chartElement, onOptionsExtracted );
	}

	function onOptionsExtracted( config, series )
	{
		config.data.series = series;

		if (series) {
			var chart;

			switch(config.data.chartType) {
				case 'bar':
					chart = new AutoBarChart(config);
					break;
				case 'donut':
					chart = new AutoDonutChart(config);
					break;
				case 'scatter':
					chart = new AutoScatterChart(config);
					break;
				case 'bubble':
					chart = new AutoBubbleChart(config);
					break;
				case 'mountain':
					chart = new AutoMountainChart(config);
					break;
			}
			if (chart) {
				chart.buildChart();
				self.charts.push(chart);

				if( config.data.element.data( 'ui' ) )
				{
					var chartUI = new AutoChartUI( chart, config );
					chartUI.renderUI();
				}
			} else {
				dataError('Chart type "' + config.data.chartType + '" not supported.');
			}
		} else {
			dataError("No data provided. Provide either a target table or url, and chart.");
		}
		
	}
	
	function dataError(e)
	{
		console.log('AutoChartController :: Error loading chart: ', e);
	}

	ApplicationObject.call( this );
}
AutoChartController.prototype = Object.create( ApplicationObject.prototype );