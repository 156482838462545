function BackToTop()
{
	var self = this;

	self.ready = function()
	{
		var btnHtml=$("<div id='back-to-top'><span class='putnamicon putnamicon-angle-up'></span><div class='back-text'>Top</div></div>")
		.on('click', function(event) {
			event.preventDefault();
			$('body, html').animate({
				scrollTop: 0,
			});
		});
		$( document.body ).append( btnHtml );
	};

	ApplicationObject.call( this );
}
BackToTop.prototype = Object.create( ApplicationObject.prototype );
