/* jshint loopfunc:true */
function CollapseTabs()
{

	var self = this,
		w = $( window ).width(),
		$collapseTabs = $('.collapse-tabs:not(.do-nothing)');

	self.ready = function()
	{
		self.setupCollapseTabs();
	};

	self.loaded = function()
	{
		self.setupCollapseTabs();
	};

	self.resize = function()
	{
		w = $( window ).width();

		self.setupCollapseTabs();
	};

	self.setupCollapseTabs = function () {

		if ( $collapseTabs.length && w < BREAKPOINT_SM ) {
			$collapseTabs.each( function(){
				self.setupAccordion( $(this) );
			});
		} else if ( $collapseTabs.length && w >= BREAKPOINT_SM ) {
			$collapseTabs.each( function(){
				self.setupTabs( $(this) );
			});
		}
	};

	self.setupTabs = function ( $element ) {
		var $thisCollapseTabsElement = $element;

		if ( $thisCollapseTabsElement.data('manifestation') != "tabs" ) {

			var $tabBar = $thisCollapseTabsElement.find('.nav-tabs'),
				$tabPanes = $thisCollapseTabsElement.find('.tab-pane'),
				$accordionContent = $thisCollapseTabsElement.find('.accordion-content'),
				$accordionTogglers = $thisCollapseTabsElement.find('.tab-accordion-toggler'),
				$accordionLinks = $thisCollapseTabsElement.find('.tab-accordion-link');

			$accordionTogglers.remove();
			$accordionLinks.remove();
			$tabPanes.removeClass('collapse').removeClass('show').attr("aria-expanded", "false").eq( 0 ).addClass('active');
			$accordionContent.removeClass('accordion-content').addClass('tab-content');
			$tabBar.show();

			$thisCollapseTabsElement.data('manifestation', 'tabs');
		}
	};

	self.setupAccordion = function ( $element ) {
		var $thisCollapseTabsElement = $element,
			thisCollapseTabsID = $thisCollapseTabsElement.attr('id');
		
		if ( $thisCollapseTabsElement.data('manifestation') != "accordion") {

			var $tabBar = $thisCollapseTabsElement.find('.nav-tabs'),
				$tabLinks = $thisCollapseTabsElement.find('.nav-link'),
				$tabContent = $thisCollapseTabsElement.find('.tab-content'),
				$tabPanes = $thisCollapseTabsElement.find('.tab-pane'),
				$moreAccordionLinks = $thisCollapseTabsElement.find('.additional-mobile-links a');

			$tabPanes.removeClass('active').addClass('collapse');
			$tabContent.removeClass('tab-content').addClass('accordion-content');
			$tabBar.hide();

			$tabLinks.each( function(){

				var $thisTabLink = $(this),
					$targetedTabPane = $( $thisTabLink.attr('href') ),
					newAccordionLinkHTML = '' +
						'<a href="#" class="collapsed tab-accordion-toggler" data-toggle="collapse" data-target="' + $thisTabLink.attr('href') + '" data-parent="#' + thisCollapseTabsID + '" aria-controls="strategies-dropdown" aria-expanded="false" aria-label="Toggle tab content">' +
							$thisTabLink.text() +
						'	<span class="putnamicon putnamicon-angle-up"></span>' +
						'</a>';

				$targetedTabPane.before( newAccordionLinkHTML );
			});

			if ( $moreAccordionLinks.length ) {
				$moreAccordionLinks.each( function(){
					var $thisAddtionalLink = $(this),
						newAccordionLinkHTML = '' +
							'<a href="' + $thisAddtionalLink.attr("href") + '" class="tab-accordion-link" >' +
								$thisAddtionalLink.text() +
							'</a>';

					$tabContent.append( newAccordionLinkHTML );
				});
			}

			$thisCollapseTabsElement.data('manifestation', 'accordion');
		}
	};


	ApplicationObject.call( this );
}
CollapseTabs.prototype = Object.create( ApplicationObject.prototype );