/**
* 	EventHandler
*	- Defines the methods that control the event handling logic
*/
function EventHandler(){}
EventHandler.prototype = {

	events : {},

	registerObject : function( object )
	{
		object.events = this.events;
		object.registerEvent = this.registerEvent;
		object.dispatchEvent = this.dispatchEvent;
		object.addEventListener = this.addEventListener;
		object.removeEventListener = this.removeEventListener;
	},

	registerEvent : function( eventName )
	{
		var event = new Event( eventName );
		this.events[ eventName ] = event;
	},

	dispatchEvent : function( eventName, eventArgs )
	{
		var self = this;

		if( this.events[ eventName ] === undefined )
			this.registerEvent( eventName );

		this.events[ eventName ].message = eventArgs;

		this.events[ eventName ].callbacks.forEach( function( callback ){
			if( callback !== undefined ) callback( self.events[ eventName ] );
		});
	},

	addEventListener : function( eventName, callback )
	{
		if( this.events[eventName] === undefined )
			this.registerEvent( eventName );

		this.events[ eventName ].registerCallback( callback );
	},

	removeEventListener : function( eventName, callback )
	{
		if( this.events[ eventName ] !== undefined )
			this.events[ eventName ].removeCallback( callback );
	}

};