function AutoChartConfig()
{
	// Configuration properties used in highcharts creation
	this.config = {
		bgColor:'#ffffff',
		chartType:'bar',
		colors:["#7cb5ec","#434348","#90ed7d","#f7a35c","#8085e9","#f15c80","#e4d354","#2b908f","#f45b5b","#91e8e1"],
		container:'',
		enableXLabels:true,
		enableYLabels:true,
		hover:true,
		innerDiameter:'70%',
		maxSize:10,
		pointRadius:5,
		series:'',
		switchRowsAndColumns:false,
		title:'',
		tooltip:true,
		yLabelAppend:'',

		// Options with no defaults
		// extractType: null,
		// height:null,
		// width:null,
		// custom:null
		// annotations:''

	};
}

AutoChartConfig.prototype	= {
	get data() {
		return this.config;
	},
	set data(data) {
		return Object.assign(this.config, data);
	}
};