/*
Unlike modal.js, this file is for dynamically generated modals, without the need for a <modal> tag in the DOM
*/

function DynamicModal()
{
	let self = this;

	let $dynamicModal = $(
		'<div class="modal dynamic-modal" id="pg-modal" tabindex="-1" role="dialog">' +
			'<div class="modal-dialog" role="document">' + 
				'<div class="modal-content">' +
					'<div class="modal-header">' +
						'<h4 class="modal-title"></h4>' +
						'<button type="button" class="close hide-modal-button"  data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">' +
							'<span class="putnamicon putnamicon-close"></span>' +
						'</button>' +
					'</div>' +
					'<div class="modal-body"><!-- gets filled by ajax --></div>' +
					'<div class="modal-footer">' +
					'</div>' +
				'</div>' +
			'</div>' +
		'</div>'
	);

	/*
		Ready
	*/
	self.ready = function()
	{
		
		// Define listeners
		$(document).on( 'click', '.dynamic-modal-link', function( e ) {
			e.preventDefault();
			
			let $clickedLink	= $(this),
				modalSettings	= {
					modalTitle:				$clickedLink.data('title'),
					modalBody:				$clickedLink.data('body'),
					modalBodyClasses:		$clickedLink.data('body-classes'),
					modalFooter:			$clickedLink.data('footer'),
					modalHard:				$clickedLink.data('hard'),
					modalAjaxURL:			$clickedLink.data('ajax-url'),
					modalAjaxData:			$clickedLink.data('ajax-data'),
					modalAjaxType:			$clickedLink.data('ajax-type'),
					modalAjaxDataType:		$clickedLink.data('ajax-data-type'),
					modalAjaxContentType:	$clickedLink.data('ajax-content-type'),
					modalAjaxWait:			$clickedLink.data('ajax-wait'),
					modalClass:				$clickedLink.data('class'),
					modalWidth:				$clickedLink.data('width'),
					modalHideClose:			$clickedLink.data('hide-close'),
					modalHideFooter:		$clickedLink.data('hide-footer'),
					modalHideHeader:		$clickedLink.data('hide-header')
				};

			self.show( modalSettings );
		} );
	};

	/*
		Show
		(Parameter) - modalSettings<Object> : Defaults are overridden by the supplied object
		Appends/Updates a modal object with the supplied data
	*/
	self.show = function( {
		modalTitle				= '',
		modalBody				= '',
		modalBodyClasses		= '',
		modalFooter				= '',
		modalHard				= false,
		modalAjaxURL			= '',
		modalAjaxData			= {},
		modalAjaxType			= 'GET',
		modalAjaxDataType		= 'html',
		modalAjaxContentType	= 'application/x-www-form-urlencoded',
		modalAjaxWait			= false,
		modalAjaxSuccess		= function() {},
		modalAjaxError			= function() {},
		modalClass				= '',
		modalWidth				= '',
		modalHideClose			= false,
		modalHideFooter			= false,
		modalHideHeader			= false
	} = {} )
	{
		let modalSettings = {
			modalTitle:				modalTitle,
			modalBody:				modalBody,
			modalBodyClasses:		modalBodyClasses,
			modalFooter:			modalFooter,
			modalHard:				modalHard,
			modalAjaxURL:			modalAjaxURL,
			modalAjaxData:			modalAjaxData,
			modalAjaxType:			modalAjaxType,
			modalAjaxDataType:		modalAjaxDataType,
			modalAjaxContentType:	modalAjaxContentType,
			modalAjaxWait:			modalAjaxWait,
			modalAjaxSuccess:		modalAjaxSuccess,
			modalAjaxError:			modalAjaxError,
			modalClass:				modalClass,
			modalWidth:				modalWidth,
			modalHideClose:			modalHideClose,
			modalHideFooter:		modalHideFooter,
			modalHideHeader:		modalHideHeader
		};

		if ( modalAjaxWait ) {
			// Don't immediately show the modal, wait for the ajax to finish and leave previous contents in place
			$.ajax({
				url: modalAjaxURL,
				type: modalAjaxType,
				data: modalAjaxData,
				headers: {
					'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN')
				},
				xhrFields: {
					withCredentials: true
				},
				success: function( response ) {
					console.log(modalAjaxData);
					modalSettings.modalBody = response;
					self.setModalContents( $dynamicModal, modalSettings );
					if(modalAjaxData.passwordChangeStatusCode == '1003'){
						$('.hide-modal-button').hide();
						$('#skip-password-update').hide();
					}
					// trigger modal ajax complete
					modalAjaxSuccess();
				},
				error: function( response ) {
					// trigger modal ajax error
					modalAjaxError();
				}
			});
		} else {
			self.setModalContents( $dynamicModal, modalSettings );

			// Ajax in content to the modal body
			if ( modalAjaxURL != '' ) {
				$.ajax({
					url: modalAjaxURL,
					type: modalAjaxType,
					data: modalAjaxData,
					headers: {
						'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN')
					},
					xhrFields: {
						withCredentials: true
					},
					success: function( response ) {
						$dynamicModal.find( '.modal-body' ).html( response );
						// trigger modal ajax complete
						modalAjaxSuccess();
					},
					error: function( response ) {
						// trigger modal ajax error
						modalAjaxError();
					}
				});
			}
		}

		// Define listeners
		// If $dynamicModal.on( 'hidden.bs.modal' is not yet already defined, define it, so we don't attach the listener multiple times
		if ( $dynamicModal.attr('hiddenListener') !== 'true') {
			$dynamicModal.attr('hiddenListener', 'true');
			
			$dynamicModal.on( 'hidden.bs.modal', function() {
				$dynamicModal.attr('hiddenListener', 'false');  // Reset the listener so it can be redefined, since the modal was closed

				// Shut down the login flow
				application.authentication.clearAuthentication();

				// Remove the modal from the dom
				$dynamicModal.remove();
			} );
		}

		// Hide any existing modal before loading in the new one, this allows us to control the modal "hardness", whether or not it can be clicked outside to close, or press the escape key to close
		self.hide();

		// Show the modal
		if ( modalHard ) { // If the modal is hard, don't allow it to be closed except by the close button
			$dynamicModal.modal( {
				keyboard: false,
				backdrop: 'static', 
				show: true
			} );
		} else {
			$dynamicModal.modal( 'show' );
		}

		return $dynamicModal;
	};

	/*
		SetModalContents
		(Parameter) - $dynamicModal<jQuery> : The modal to set the contents of
	*/
	self.setModalContents = function( $dynamicModal, modalSettings )
	{
		// Set the contents of the dom elements
		$dynamicModal.find( '.modal-title' ).html( modalSettings.modalTitle );
		$dynamicModal.find( '.modal-body' ).html( modalSettings.modalBody ).addClass( modalSettings.modalBodyClasses );
		$dynamicModal.find( '.modal-footer' ).html( modalSettings.modalFooter );
		$dynamicModal.find( '.modal-dialog' ).attr('class', 'modal-dialog ' + modalSettings.modalClass );

		// If there was no footer content provided, assume they want to hide
		if ( modalSettings.modalFooter === '' ) modalSettings.modalHideFooter = true;

		// Show/hide dom elements as defined
		modalSettings.modalHideFooter ? $dynamicModal.find( '.modal-footer' ).hide() : $dynamicModal.find( '.modal-footer' ).show();
		modalSettings.modalHideHeader ? $dynamicModal.find( '.modal-header' ).hide() : $dynamicModal.find( '.modal-header' ).show();
		modalSettings.modalHideClose ? $dynamicModal.find( '.hide-modal-button' ).hide() : $dynamicModal.find( '.hide-modal-button' ).show();

		// Set a custom width if defined
		modalSettings.modalWidth != '' ? $dynamicModal.find( '.modal-dialog' ).css( 'width', modalSettings.modalWidth ) : $dynamicModal.find( '.modal-dialog' ).css( 'width', '' );

		// Remove any loader overlays
		$dynamicModal.find( '.putnam-loader-overlay' ).remove();
	};

	/*
		Hide
		Hides Modal
	*/
	self.hide = function()
	{
		$dynamicModal.modal( 'hide' );
		$dynamicModal.remove();
	};

	ApplicationObject.call( this );
}
DynamicModal.prototype = Object.create( ApplicationObject.prototype );