function BlogPosts()
{

	var self = this,
		w = $( window ).width();

	self.feedURL = "/feeds/json/journaled-content/";

	self.resize = function()
	{
		w = $( window ).width();
	};

	self.formatFirstPost = function ( post )
	{
		var featuredImage = post.featuredImage,
			// postTag = post.postTag,
			postDate = post.postDate,
			postExcerpt = post.postExcerpt,
			postTitle = post.postTitle,
			postURL = post.postURL;

		var newHTML = '';
			newHTML += '<div class="card h-100 border-0">';
			newHTML += '	<a href="' + postURL + '"><img src="' + featuredImage + '" alt="' + postTitle + '" class="featured-image" width="773" height="435"></a>';
			newHTML += '	<div class="card-block white-background">';
			newHTML += '		<p class="post-tag"></p>';
			newHTML += '		<h2 class="post-title text-light h1"><a href="' + postURL + '">' + postTitle + '</a></h2>';
			newHTML += '		<p class="post-details">';
			newHTML += '			' + postDate;
			newHTML += '		</p>';
			newHTML += '		<p class="post-excerpt">' + postExcerpt + '</p>';
			newHTML += '		<p class="post-link"><a href="' + postURL + '">More &raquo;</a></p>';
			newHTML += '	</div>';
			newHTML += '</div>';

		$('#first-post').animate( { opacity: 0 }, function(){
			$('#first-post').html( newHTML ).animate({ opacity: 1 });
		});
	};

	self.formatAdditionalPost = function ( post )
	{
		var featuredImage = post.featuredImage,
			// postTag = post.postTag,
			postDate = post.postDate,
			postExcerpt = post.postExcerpt,
			postTitle = post.postTitle,
			postURL = post.postURL;

		var newHTML = '';
			newHTML += '<div class="col-md-6 col-lg-4 mb-2 additional-post" style="display:none;">';
			newHTML += '	<div class="card h-100">';
			newHTML += '		<a href="' + postURL + '" class="flex-shrink-zero"><img src="' + featuredImage + '" alt="' + postTitle + '" class="featured-image" width="773" height="435"></a>';
			newHTML += '		<div class="card-block pt-1">';
			newHTML += '			<p class="post-tag mb-0"></p>';
			newHTML += '			<h4 class="post-title mb-0"><a href="' + postURL + '">' + postTitle + '</a></h4>';
			newHTML += '			<p class="post-excerpt pt-1">';
			newHTML += '				' + postExcerpt;
			newHTML += '			</p>';
			newHTML += '			<p class="post-link"><a href="' + postURL + '">More &raquo;</a></p>';
			newHTML += '		</div>';
			newHTML += '	</div>';
			newHTML += '</div>';

		$('#posts').append( newHTML );

		$('.additional-post:visible').fadeOut( function(){
			$(this).remove();
			$('.additional-post:hidden').fadeIn();
		});

		/*if ( noPosts ) {
			noPosts = false;
			
			setTimeout(function(){
				$('.additional-post').fadeIn();
			}, 300);
		} else {
			$('.additional-post:visible').fadeOut( function(){
				$(this).remove();
				$('.additional-post:hidden').fadeIn();
			});
		}*/
	};

	/*self.formatNoPosts = function ()
	{
		noPosts = true;

		var newHTML = '';
			newHTML += '<div class="card h-100">';
			newHTML += '	<div class="card-block" style="display: flex; align-items: center;>';
			newHTML += '		<div class="card h-100">';
			newHTML += '			<p class="d-flex justify-content-around w-100 no-posts"><a>No posts here.</a></p>';
			newHTML += '		</div>';
			newHTML += '	</div>';
			newHTML += '	</div>';
			newHTML += '</div>';


		$('#first-post').animate( { opacity: 0 }, function(){
			$('#first-post').html( newHTML ).animate({ opacity: 1 });
		});

		$('.additional-post').fadeOut( function(){
			$(this).remove();
		});
	};*/

	self.fetchContent = function ( dataObject )
	{
		$.ajax({
			url: self.feedURL,
			method: 'POST',
			dataType: "jsonp",
			jsonpCallback: "content",
			data: dataObject,
			success: function( data ) {
				if (data.content.length === 0) {
					self.formatNoPosts();
				} else {
					$.each( data.content, function( i, item ) {
						if ( i === 0 ) {
							self.formatFirstPost( data.content[i] );
						} else {
							self.formatAdditionalPost( data.content[i] );
						}
					});
				}
			}
		});
	};

	self.filterAll = function ()
	{
		self.fetchContent({
			"sources": [
				{
					"source": "perspectives",
					"site": "advisor",
					"count": "40",
					"url": "advisor/content/perspectives"
				}
			]	
		});	
	};

	self.filterSome = function ( $element )
	{
		self.fetchContent({
			"sources": [
				{
					"source": $element.data('filter-source'),
					"site": $element.data('filter-site'),
					"count": $element.data('filter-count'),
					"filter": $element.data('filter-filters'),
					"url": $element.data('filter-url')
				}
			]
		});
	};

	ApplicationObject.call( this );
}
BlogPosts.prototype = Object.create( ApplicationObject.prototype );