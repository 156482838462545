function FatNav()
{

	var self = this,
		w = $( window ).width(),
		$fatNav = $('.fat-nav'),
		$fatNavListItems = $("nav.fat-nav-bar ul.navbar-nav>li:not(.ignore)"),
		$fatNavLinks = $("nav.fat-nav-bar ul.navbar-nav>li[data-target*='subnav']>a.nav-link"),
		hoverDelay = 150,
		hoverTimeout;

	self.resize = function()
	{
		w = $( window ).width();
	};

	self.ready = function()
	{

		if ($fatNav.length && w >= BREAKPOINT_SM) {

			$fatNavLinks.on('touchstart', function( event ){
				var $this = $(this);
				
				if ( !$this.hasClass('touched') ) {
					event.preventDefault();
					$fatNavLinks.removeClass('touched');
					$this.addClass('touched');
				}
			});

			$fatNavListItems.on('mouseenter touchstart', function( event ){
				var $this = $(this);

				if ( $this.data('target') ){
					if ( !$this.hasClass("hover") ) {

						clearTimeout( hoverTimeout );

						hoverTimeout = setTimeout( function(){

							$fatNavListItems.removeClass('hover');
							$this.addClass('hover');
							var $fatNavListItemsets = $fatNav.find('.fatnav-linkset');
							$fatNavListItemsets.addClass('hidden');
							$('#' + $this.data('target')).removeClass('hidden');
							$fatNav.show();
							return false;
						}, hoverDelay );
					}
				} else {
					clearTimeout( hoverTimeout );
					self.closeFatNav();
				}
			});

			// Hide the nav
			$('header, #main, footer, .ignore').on('mouseenter touchstart', function( event ){
				self.closeFatNav();
			});
		}
	};

	self.closeFatNav = function ()
	{
		clearTimeout( hoverTimeout );
		$fatNav.hide();
		$fatNavListItems.removeClass('hover');
	};

	ApplicationObject.call( this );
}
FatNav.prototype = Object.create( ApplicationObject.prototype );