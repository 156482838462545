/**
* 	Event
*	- Contains the name, callback, and message for events that
*	  are dispatched from an EventHandler object
*
*	(param) name
*			- Name of the event being fired
*/
function Event( name )
{
	this.name = name;
	this.callbacks = [];
	this.message = undefined;
}

Event.prototype = {

	registerCallback : function( callback )
	{
		this.callbacks.push( callback );
	},

	removeCallback : function( callback )
	{
		var rm;
		while ((rm = this.callbacks.indexOf(callback)) !== -1) {
		    this.callbacks.splice(rm, 1);
		}
	}

};
Event.prototype.constructor = Event;


// EVENT IDs
Event.ApplicationLoaded = 'application_loaded';
Event.ApplicationReady = 'application_ready';
Event.ApplicationResize = 'application_resize';
Event.ApplicationScroll = 'application_scroll';

Event.ChartRequest = 'chart_request';