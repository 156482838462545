function ModalData()
{
	
	var self = this;

	self.target = '';
	self.class = '';
	self.title = '';
	self.body = '';
	self.buttons = [];
	self.videoid = -1;

	self.convertHTML = function( html )
	{
		self.target			= $( html ).attr( 'data-target' );
		self.class			= $( html ).attr( 'class' );
		self.title			= $( html ).attr( 'title' );
		self.body			= $( html ).attr( 'body' );
		self.videoid		= $( html ).attr( 'videoid' );
		self.ignoreSocial	= $( html ).data( 'ignore-social' );
		
		// Modal tag innerhtml body
		if( $( html ).html() !== '' )
		{
			var tagBody = $( html ).clone();
			tagBody.find( 'buttons' ).remove();
			self.body = $( tagBody ).html();

			// Inner Button Declaration
			var buttonsTag = $( html ).find( 'buttons' );
			if( buttonsTag.length )
			{
				$( buttonsTag ).find( 'a, button' ).each( function(){
					addButton( $( this ).html(), $( this ).attr( 'class' ), $( this ).attr( 'href' ) );
				} );
			}
		}

		// Basic Inline Button Declaration
		for( var i = 0; i < $( html )[0].attributes.length; i++ ){
			var attr = $( html )[0].attributes[i];
			if( attr.name.indexOf( 'button:' ) !== -1 )
			{
				var btnName = attr.name.replace( 'button:', '' );
				addButton( btnName, 'btn-default', attr.value );
			}
		}

	};

	function addButton( text, cssClass, href )
	{
		self.buttons.push({
			text : text,
			class : cssClass,
			href : href
		});
	}

}