function AutoMountainChart(config)
{
	var self = this;

	self.config = config;
	self.chart = null;

	AutoChart.call( this );

	self.buildFormatterFunction = function()
	{
		return function(){
			return 	Highcharts.dateFormat( "%m/%d/%y", this.x ) + '<br/>' +
				this.series.name + ': ' + this.y.toFixed(2) + self.config.data.yLabelAppend;
		};
	}

	self.getPlotLines = function(plotLines)
	{
		if (plotLines.data != undefined && plotLines.data.length > 0) {
			for (var i = 0; i < plotLines.data.length; i++) {

				var plotLine = plotLines.data[i];

				var series = JSON.parse(JSON.stringify(config.data.series));
				if (plotLines.custom) {
					plotLines.custom[i](series[i], plotLine);
				}
			}
		}
	}
}

AutoMountainChart.prototype = Object.create( AutoChart.prototype );
AutoMountainChart.prototype.constructor = AutoMountainChart;

AutoMountainChart.prototype.buildChart = function()
{
	var self = this,
		config = self.config;
		
	self.getPlotLines(config.data.xPlotLines);
	self.getPlotLines(config.data.yPlotLines);
	self.getPlotLines(config.data.yPlotBands);

	self.chart = new Highcharts.Chart({
		colors:config.data.colors,
		chart:{
			renderTo:config.data.container[0],
			backgroundColor:config.data.bgColor,
			width:config.data.width,
			height:config.data.height,
			plotBorderWidth:null,
			plotShadow:false,
			type:'area',
			events: {
				load: function() {
					var chart = this,
					yAxis = chart.yAxis[0],
					yExtremes = yAxis.getExtremes(),
					newMin = yExtremes.dataMin,
					newMax = yExtremes.dataMax;
					yAxis.setExtremes(newMin, newMax, true, false);
				}
			}
		},	
		plotOptions:{
			series:{
				turboThreshold : 0,
				fillOpacity : 0.2,
				marker: {
					enabled: false
				}
			}
		},
		title : 
		{
			text : ''
		},
		xAxis: {
			type:'datetime',
			showLastLabel: config.data.showLastLabel,
			plotLines: config.data.xPlotLines.data,
			tickInterval: config.data.xTickInterval,
			tickPositioner: config.data.xTickPositioner,
			labels: {
				rotation: config.data.xLabelRotation || 0,
				formatter: config.data.xLabelFormat
			}
		},
		yAxis: {
			title: {
				enabled : config.data.yTitle != undefined,
				text : config.data.yTitle
			},
			plotLines: config.data.yPlotLines.data,
			plotBands: config.data.yPlotBands,
			labels:{
				enabled:config.data.enableYLabels,
				formatter:function(){
					return this.value+config.data.yLabelAppend;
				}
			}
			// type: 'logarithmic',
			// minorTickInterval: 0.1,
		},
		tooltip:{
			formatter: config.data.disableTooltipFormatter == true ? undefined : self.buildFormatterFunction(),
			enabled: config.data.tooltip
		},
		series: config.data.series,
		annotations: config.data.annotations,
		legend:
		{
			enabled : false
		},
		exporting:{
			enabled:false
		},
		credits:{
			enabled:false
		}
	});
	
}

AutoMountainChart.parseURLSeries = function( responseData )
{
	console.log("AutoMountainChart :: No default series set for URL");
	return [];
}