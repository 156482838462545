// INCOMPLETE

function AutoScatterChart(config)
{
	var self 	= this;

	self.config = config;
	self.chart 	= null;

	AutoChart.call(this);
}

AutoScatterChart.prototype = Object.create( AutoChart.prototype );
AutoScatterChart.prototype.constructor = AutoScatterChart;

// Builds highchart donut chart based on config
AutoScatterChart.prototype.buildChart = function()
{
	var self 	= this,
		config 	= self.config;
		
	console.log("AutoScatterChart :: buildChart :: config", config, "this", self);

	self.chart = new Highcharts.Chart({
		colors:config.data.colors,
		chart:{
			type:'scatter',
			renderTo:config.data.container[0],
			backgroundColor:config.data.bgColor,
			width:config.data.width,
			height:config.data.height
		},
		credits:{
			enabled:false
		},
		plotOptions:{
			scatter: {
				marker: {
					radius: config.data.pointRadius,
					states: {
						hover: {
							enabled: true
						}
					}
				},
				tooltip: {
					headerFormat: '<b>{series.name}</b><br>',
					pointFormat: '{point.x} | {point.y}'
				}
			}
		},
		series:config.data.series,
		title:{
			text:config.data.title,
			align:'center',
			verticalAlign:'middle',
			y:-10
		},
		tooltip:{
			enabled:config.data.tooltip
		},
		exporting:{
			enabled:false
		}
	});
	console.log("SCATTER DATA", config.data.series);
}