/* jshint loopfunc:true */

/*

How To Use Via Markup:

-Simple-
<button id="simpleModal">Open Simple Modal</button>
<modal data-target="simpleModal" title="This is a title" body="<div>This is the body</div>"></modal>

-Inline Buttons-
Inline buttons are defined as an attribute like this -> button:[name]="[href]"
<modal data-target="inlineButtonsModal" button:btnOne="http://site.com" button:btnTwo="http://site2.com"></modal>

-Open on Load-
<modal data-target="onloadModal" class="onload" title="This is a title" body="<div>This is the body</div>"></modal>

-Video Player-
<modal data-target="videoModal" title="This is a title" videoid="0000000000000"></modal>

-Inner HTMl Body and Buttons-
Inner HTML modals should have the 'hidden' attribute to keep the DOM content from appearing on load
<modal data-target='innerButtons' title="This is a title" hidden>
	<div>This is the body</div>
	<buttons>
		<a href="#" class="btn btn-default">This is an anchor button</a>
		<button class="btn btn-primary">This is a normal button</button>
	</buttons>
</modal>

Note: All modals can be opened using the ID/data-target relation shown in the -Simple- example

*/

function Modal()
{

	var self = this;

	//var defaultModalClass = 'modal fade';  Bring this back when Bootstrap fixes modals with "fade"
	var defaultModalClass = 'modal';

	var videoAccountID = '31193518001';
	var videoPlayerID = 'fSGPreHed';

	var modalWrapper = $('<div class="' + defaultModalClass + '" id="pg-modal" tabindex="-1" role="dialog">' +
						'<div class="modal-dialog" role="document">' + 
							'<div class="modal-content">' +
								'<div class="modal-header">' +
									'<h4 class="modal-title"></h4>' +
									'<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
										'<span aria-hidden="true">&times;</span>' +
									'</button>' +
								'</div>' +
								'<div class="modal-body"><!-- gets filled by ajax --></div>' +
								'<div class="modal-footer">' +
								'</div>' +
							'</div>' +
						'</div>' +
					'</div>'),
		settingsObj = '';

	var modalMap = new Dictionary();

	var previousModalClass = '';

	/*
		Ready
		Combs the DOM for <modal> tags, adds it's attributes to the hashmap, and removes it from the DOM
		Finds any id that share the date-target attribute of any <modal>, and adds a click event to show
	*/
	self.ready = function()
	{
		$( 'modal' ).each( function(){
			var modalData = new ModalData();
			modalData.convertHTML( $(this) );
			modalMap.add( modalData.target, modalData );
			if( $( this ).hasClass( 'onload' ) ) self.show( modalData );
			$( this ).remove();
		} );

		for ( var i = 0; i < modalMap.length; i++ )
		{
			$( document ).on( 'click', '#' + modalMap.getByIndex( i ).target, function(){
				self.show( modalMap.get( $(this).attr( 'id' ) ) );
			} );
		}
	};

	/*
		Show
		(Parameter) - modalData<ModalData> : Instance of parsed data taken from a <modal> tag, or defined directly
		Appends/Updates a modal object with the supplied data
	*/
	self.show = function( modalData )
	{
		//debug.log( modalData );
		if( $( 'body' ).find( '#pg-modal' ) === undefined )
		{
			$( 'body' ).append( modalWrapper );
		}
		else
		{
			modalWrapper.on( 'hidden.bs.modal', function(){
				modalWrapper.find( '.modal-title' ).empty();
				modalWrapper.find( '.modal-body' ).empty();
				modalWrapper.find( '.modal-footer' ).empty();
				modalWrapper.find( '.modal-dialog' ).removeClass( previousModalClass );
			} ).modal( 'hide' );
		}
		
		modalWrapper.find( '.modal-dialog' ).addClass( modalData.class );
		previousModalClass = modalData.class;

		modalWrapper.find( '.modal-title' ).html( modalData.title );

		if ( modalData.videoid !== undefined && modalData.videoid.length > 4 )
		{
			var videoObj =	
				'<div class="embed-responsive embed-responsive-16by9">' +
				'	<div class="embed-responsive-item">' +
				'		<video data-video-id="'+ modalData.videoid +'" data-account="' + videoAccountID + '" data-player="' + videoPlayerID + '" data-embed="default" class="video-js" controls=""></video>' +
				'	</div>' +
				'</div>',
				videoShareButtons = '';

			if ( !modalData.ignoreSocial ) {
				videoShareButtons =
					'<div class="video-modal-share-links pt-1 text-right">' +
					'	<span class="video-modal-share-text">Share video:</span>' +
					'	<a href="#" class="video-modal-share-link copy-text" data-copy="' + window.location.href.split('?')[0] + '?video-id=' + modalData.videoid + '" title="Copy URL"><span class="putnamicon putnamicon-link"></span></a>' +
					'	<a href="' + window.location.href.split('?')[0] + '?video-id=' + modalData.videoid + '" data-ignore-canonical="true" data-use-href="true" class="video-modal-share-link launch-facebook-popup" title="Share on Facebook"><span class="putnamicon putnamicon-facebook_r"></span></a>' +
					'	<a href="' + window.location.href.split('?')[0] + '?video-id=' + modalData.videoid + '" data-ignore-canonical="true" data-use-href="true" class="video-modal-share-link launch-linkedin-popup" title="Share on LinkedIn"><span class="putnamicon putnamicon-linkedin_r"></span></a>' +
					'	<a href="' + window.location.href.split('?')[0] + '?video-id=' + modalData.videoid + '" data-ignore-canonical="true" data-use-href="true" class="video-modal-share-link launch-twitter-popup" title="Share on Twitter"><span class="putnamicon putnamicon-twitter_r"></span></a>' +
					'</div>';
			}

			modalWrapper.find( '.modal-body' ).append( videoObj ).append( videoShareButtons );
			
			/* Wait a tiny amount of time to load the Brightcove scripts, so that it can find the <video> element generated above */
			setTimeout( function(){
				modalWrapper.find( '.modal-body' ).append( '<script src="//players.brightcove.net/'+ videoAccountID +'/' + videoPlayerID + '_default/index.min.js"></script>' );
			}, 1 );
		}

		modalWrapper.find( '.modal-body' ).append( modalData.body );

		for( var i = 0; i < modalData.buttons.length; i++ )
		{
			var bType = ( modalData.buttons[i].href !== undefined ) ? 'a' : 'button type="button"';
			var aTarget = ( modalData.buttons[i].anchorTarget !== undefined ) ? modalData.buttons[i].anchorTarget : '_self';
			var button = $( '<' + bType + ' target="' + aTarget + '" href="' + modalData.buttons[i].href + '" class="modal-btn btn ' + modalData.buttons[i].class + '">' +
								'<span aria-hidden="true">' + modalData.buttons[i].text + '</span>' +
							'</' + bType + '>' );
			if( modalData.buttons[i].eventCallback !== undefined ) button.on( 'click', modalData.buttons[i].eventCallback );
			modalWrapper.find( '.modal-footer' ).append( button );
		}

		modalWrapper.modal( 'show' );
	};

	/*
		Hide
		Hides Modal
	*/
	self.hide = function()
	{
		modalWrapper.modal( 'hide' );
	};

	ApplicationObject.call( this );
}
Modal.prototype = Object.create( ApplicationObject.prototype );