function Debug( debug )
{

	var self = this;

	var logQueue = {};
	var warnQueue = {};
	var errorQueue = {};

	self.log = function( message )
	{
		if( !debug ) return;

		console.log( message );
	};

	self.logWarning = function( message )
	{
		if( !debug ) return;

		console.warn( message );
	};

	self.logError = function( message )
	{
		if( !debug ) return;

		console.error( message );
	};
}