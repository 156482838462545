function LabelListRenderer( container, selector, config )
{
	var self = this;

	var labels = [];
	var totalValues = 0;

	self.addValue = function( index, data, color )
	{
		totalValues += data.value;
		labels.push( { value : data.value, text : data.label, color : color } );
		updateLabels();
	}

	function updateLabels()
	{
		$( selector ).empty();

		var totalPct = 0;
		for( var i = 0; i < labels.length; i++ )
		{
			var pct = Math.ceil( ( labels[i].value / totalValues ) * 100 );
			totalPct += pct;
			if( totalPct > 100 ){
				pct -= (totalPct - 100);
			}
			if( pct < 0 ) pct = 0;
			else if( pct > 100 ) pct = 100;
			addlabel( labels[i].text, labels[i].color, pct );
		}
	}

	function addlabel( label, color, pct )
	{
		var el = 	'<div class="chart-label">' +
								'<div style="margin-top: 8px; width: 8px; height: 8px; position: absolute; background-color: ' + color + ';"></div>' +
								'<div style="margin-left: 15px;">' + '{0} ({1}%)'.format( label, pct ) + '</div>' +
							'</div>';
		$( selector ).addClass( 'chart-label-section' ).append( el );
	}

}
