function FVSearch()
{
	var self = this;

	self.ready = function()
	{
		$( '.fund-search-input' ).each( function( index, element ){
			self.handleFVSearchInput( element );
		} );
	}

	self.handleFVSearchInput = function( e )
	{
		var element = $( e );
		var resultsElement = $( element.attr( 'data-results' ) );

		element.on( 'input', function(){
			var searchVal = element.val();

			$.ajax( {
				url : '/advisor/fv-search?pSearchTerm=' + searchVal,
				method : 'GET',
				crossDomain : true
			} ).done( function( data ){
				self.populateResults( resultsElement, data );
			} ).fail( function(  jqXHR, textStatus  ){
				console.error( "failed: " + textStatus );
				console.error( jqXHR );
			} );

		} );

		element.on( 'focus', function(){
			if( resultsElement.find( '.fund-search-results-items li' ).length > 0 )
			{
				resultsElement.removeAttr( 'hidden' );
			}
		} );

		$( document ).ready( function() {
			$( document ).click( function( event ) {
				var clickTarget = $( event.target );
				var root = element.parent();
				
				var clickedOff = ( root.has( clickTarget ).length == 0 );
				if( clickedOff )
				{
					resultsElement.attr( 'hidden', true );
				}
			} );
		} );
	}

	self.populateResults = function( resultsElement, data )
	{
		var results = data.results;
		var resultItems = resultsElement.find( '.fund-search-results-items' );

		if( results.length > 0 )
			resultsElement.removeAttr( 'hidden' );
		else
			resultsElement.attr( 'hidden', 'true' );

		var fundTicker = $( '.fund-search-input' ).attr( 'data-compare' );
		var compareString = ( fundTicker != undefined ) ? ',' + fundTicker : '';

		resultItems.empty();
		for( var i = 0; i < results.length; i++ )
		{
			var fvLocalURL = '/advisor/tools/fundvisualizer?preselected_fund=' + results[i].tradingSymbol + compareString + '&start_view=5yearperformance';
			var resultItemString = '<li>' +
										'<a href="' + fvLocalURL + '" target="_blank">' +
											results[i].investmentVehicleName + ' (' + results[i].tradingSymbol + 
										')</a>' +
									'</li>';
			var resultItem = $( resultItemString );
			resultItems.append( resultItem );
		}
	}

	ApplicationObject.call( this );
}
FVSearch.prototype = Object.create( ApplicationObject.prototype );

window.application.fvSearch = new FVSearch();