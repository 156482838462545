function SocialLinks()
{
	var self = this;

	self.ready = function()
	{
		$(document).on( 'click', '.copy-text', function( e ){
			e.preventDefault();

			var $this = $(this),
				$temp = $('<input>'),
				$videoModalShareText = $('.video-modal-share-text');

			$this.append( $temp );
			$temp.val( $this.data('copy') ).select().trigger( "focus" );
			document.execCommand("copy");
			$temp.remove();

			$videoModalShareText.html("<strong>Link copied!</strong>");
			setTimeout( function(){
				$videoModalShareText.html("Share video:");
			}, 2000 );

		});
		$(document).on( 'click', '#social-icons .icon-social-facebook, .launch-facebook-popup', function( e ){
			e.preventDefault();

			var $this = $(this),
				ignoreCanonical = false,
				locationToShare = window.location.href;

			if ( $this.data('ignore-canonical') ) { ignoreCanonical = true; }
			if ( $this.data('use-href') ) { locationToShare = $this.attr('href'); }

			self.socialSharePopup( "facebook", locationToShare, ignoreCanonical );
		});
		$(document).on( 'click', '#social-icons .icon-social-twitter, .launch-twitter-popup', function( e ){
			e.preventDefault();

			var $this = $(this),
				ignoreCanonical = false,
				locationToShare = window.location.href;

			if ( $this.data('ignore-canonical') ) { ignoreCanonical = true; }
			if ( $this.data('use-href') ) { locationToShare = $this.attr('href'); }

			self.socialSharePopup( "twitter", locationToShare, ignoreCanonical );
		});
		$(document).on( 'click', '#social-icons .icon-social-linkedin, .launch-linkedin-popup', function( e ){
			e.preventDefault();

			var $this = $(this),
				ignoreCanonical = false,
				locationToShare = window.location.href;

			if ( $this.data('ignore-canonical') ) { ignoreCanonical = true; }
			if ( $this.data('use-href') ) { locationToShare = $this.attr('href'); }

			self.socialSharePopup( "linkedin", locationToShare, ignoreCanonical );
		});
		$(document).on( 'click', '#social-icons .icon-social-google, .launch-google-popup', function( e ){
			e.preventDefault();

			var $this = $(this),
				ignoreCanonical = false,
				locationToShare = window.location.href;

			if ( $this.data('ignore-canonical') ) { ignoreCanonical = true; }
			if ( $this.data('use-href') ) { locationToShare = $this.attr('href'); }
				
			self.socialSharePopup( "google-plus", locationToShare, ignoreCanonical );
		});
	};

	self.socialSharePopup = function ( shareSite, shareLink, ignoreCanonical )
	{
		console.log('ignoreCanonical: '+ignoreCanonical);
		var popupShareURL	= "",
			urlToShare		= encodeURIComponent( shareLink ),
			pageCanonical	= document.querySelector("link[rel='canonical']");

		console.log(shareLink);
		
		if ( pageCanonical !== null && !ignoreCanonical ) {
			urlToShare = pageCanonical.getAttribute("href");
		}

		switch(shareSite) {
			case "facebook":
				popupShareURL = "http://www.facebook.com/sharer.php?u=";
				break;
			case "twitter":
				popupShareURL = "http://twitter.com/share?url=";
				break;
			case "linkedin":
				popupShareURL = "http://www.linkedin.com/shareArticle?mini=true&url=";
				break;
			case "google-plus":
				popupShareURL = "https://plus.google.com/share?url=";
				break;
			default:
				return false;
		}

		window.open( popupShareURL + urlToShare, '_blank', 'toolbar=yes,location=yes,directories=yes,resizable=yes,scrollbars=yes,width=600,height=400' );
	};

	ApplicationObject.call( this );
}
SocialLinks.prototype = Object.create( ApplicationObject.prototype );