function AutoDonutChart(config)
{
	var self 	= this;

	self.config = config;
	self.chart 	= null;

	AutoChart.call(this);
}

AutoDonutChart.prototype = Object.create( AutoChart.prototype );
AutoDonutChart.prototype.constructor = AutoDonutChart;

// Builds highchart donut chart based on config
AutoDonutChart.prototype.buildChart = function()
{
	var self 	= this,
		config 	= self.config;
		
	// console.log("AutoDonutChart :: buildChart :: config", config, "this", self);

	self.chart = new Highcharts.Chart({
		colors:config.data.colors,
		chart:{
			renderTo:config.data.container[0],
			backgroundColor:config.data.bgColor,
			width:config.data.width,
			height:config.data.height,
			plotBorderWidth:null,
			margin:[0, 0, 0, 0],
			plotShadow:false
		},
		credits:{
			enabled:false
		},
		plotOptions:{
			pie:{
				allowPointSelect:false,
				dataLabels:{
					enabled:false
				}
			},
			series:{
				states:{
					hover:{
						enabled:config.data.hover
					}
				}
			},
		},
		series:[{
			type:'pie',
			innerSize:config.data.innerDiameter,
			data:config.data.series
		}],
		title:{
			text:config.data.title,
			align:'center',
			verticalAlign:'middle',
			y:-10
		},
		tooltip:{
			formatter:function(){
				return '<strong>'+self.formatTooltip(this.point.name)+'</strong><br/>'+this.y+'%';
			},
			style:{
				marginLeft:'0px'
			},
			enabled:config.data.tooltip
		},
		exporting:{
			enabled:false
		}
	});
}