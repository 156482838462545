function ExternalLinkModal()
{

	var self = this;

	var mData = new ModalData();

	self.ready = function()
	{

		mData.target = 'externalLinkModal';
		mData.title = 'You are now leaving putnam.com';
		mData.body = 'Putnam Investments is not responsible for content or services offered on the site you are about to visit.';

		$( document ).on( 'click', 'a[rel="external"]', function( e ){
			e.preventDefault();
			debug.log( e.currentTarget.getAttribute( 'href' ) );
			var linkURL = e.currentTarget.getAttribute( 'href' );
			
			mData.buttons = [ 
				{
					text : 'Close', 
					class : 'btn-default',
					eventCallback : function(){
						application.modal.hide();
					}
				}, 
				{
					text : 'Continue', 
					class : 'btn-primary',
					href : linkURL,
					anchorTarget : "_blank"
				}
			];

			application.modal.show( mData );
		} );
	};

	ApplicationObject.call( this );
}
ExternalLinkModal.prototype = Object.create( ApplicationObject.prototype );