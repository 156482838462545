function AutoChart()
{
	var self 	= this;

	self.chart 	= null;
	
	// Formatter for highchart tooltip (from previous donutChart)
	self.formatTooltip = function(text)
	{
		if (text.length > 20){
			var pos=text.substring(0, text.length / 2 ).lastIndexOf(" ");
			if (pos==-1){
				pos=text.indexOf(" ");
			}
			return text.substring(0, pos)+"</strong><br/><strong>"+text.substring(pos+1, text.length);
		}
		return text;
	}

	// Formatter for highchart tooltip (from previous barChart)
	self.buildFormatterFunction = function()
	{
		return function(){
			return this.series.name + '<br/>' + "<strong>" + this.x + ": " + this.y.toFixed(2) + self.config.data.yLabelAppend + "</strong>";
		};
	}

	// Resizes chart based on size of parent element
	self.resize = function()
	{
		if (self.chart) {
			var chartParentWidth=$(self.chart.container.parentElement).width();
			if(chartParentWidth > 0)
				self.chart.setSize( chartParentWidth, self.chart.chartHeight);
		}
	}

	ApplicationObject.call( this );
}

AutoChart.prototype = Object.create( ApplicationObject.prototype );

AutoChart.prototype.buildChart = function()
{
	console.log("AutoChart :: Instantiate a subclass e.g. AutoBarChart before trying to render a chart");
}

AutoChart.prototype.setDateRange = function()
{
	console.log("AutoChart :: Instantiate a subclass e.g. AutoBarChart before trying to set a Date range");
}