function AutoBarChart(config)
{
	var self 	= this;

	self.config = config;
	self.chart 	= null;

	AutoChart.call(this);
}

AutoBarChart.prototype = Object.create( AutoChart.prototype );
AutoBarChart.prototype.constructor = AutoBarChart;

// Builds highchart bar chart based on config
AutoBarChart.prototype.buildChart = function()
{
	var self 	= this,
		config 	= self.config;

	// console.log("AutoBarChart :: buildChart :: config", config, "this", self);

	self.chart = new Highcharts.Chart({
		colors:config.data.colors,
		chart:{
			renderTo:config.data.container[0],
			backgroundColor:config.data.bgColor,
			width:config.data.width,
			height:config.data.height,
			defaultSeriesType:'column',
			marginTop:50,
			shadow:false
		},
		credits:{
			enabled:false
		},
		legend:{
			align:'top',
			verticalAlign:'top',
			layout:'horizontal',
			borderColor:'#fff',
			margin:0,
			y:-10,
			x:-10,
			labelFormatter:function(){
				if (this.name=="Before sales charge" || this.name=="At net asset value" || this.name.indexOf("Gross") > -1 || this.name.indexOf("bps") > -1){
					if (typeof overrideFundName !== 'undefined') {
						return overrideFundName;
					} else {
						return $("meta[name='fundName']").attr("content");
					}
				}else{
					return this.name;
				}
 			},
			itemStyle:{
				fontSize:'10px',
				overflow:'auto',
				width:'auto'
			},
			itemMarginBottom: 2
		},
		plotOptions:{
			series:{
				states:{
					hover:{
						enabled:config.data.hover
					}
				}
			},
			column:{
				shadow:false,
				pointPadding:0.02,
				groupPadding: 0.11,
				borderWidth:0.5,
				dataLabels:{
					enabled:config.data.labels,
					style:{
						fontSize:'9px'
					},
					formatter:function(){
						return this.y.toFixed(2);
					}
				}
			},
			bar:{
				dataLabels:{
					enabled:true,
					style:{
						fontSize:'9px'
					},
					formatter:function(){
						return this.y.toFixed(2);
					}
				}
			}
		},
		series:config.data.series,
		title:{
			text:config.data.title,
			align:'center',
			verticalAlign:'middle',
			y:-10
		},
		tooltip:{
			formatter:self.buildFormatterFunction(),
			enabled:config.data.tooltip
		},
		xAxis:{
			categories: config.data.categories,
			showLastLabel: config.data.showLastLabel,
			gridLineColor:'#f0f0f0',
			gridLineWidth:1,
			gridLineDashStyle: 'dash',
			tickInterval: config.data.xTickInterval,
			labels:{
				enabled:config.data.enableXLabels
			}
		},
		yAxis:{
			title:{
				text:'',
				style:{
					color:'#4572A7'
				}
			},
			allowDecimals:false,
			gridLineColor:'#CCCCCC',
			labels:{
				enabled:config.data.enableYLabels,
				formatter:function(){
					return this.value+config.data.yLabelAppend;
				}
			}
		},
		exporting:{
			enabled:false
		}
	});
}