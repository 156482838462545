application.async = new Async();
function Async()
{

	var self = this;

	self.awake = function()
	{
		/* Call async javascript in here */
	};

	/*
	self.ready = function()
	{
		
	};

	self.loaded = function()
	{
		
	};

	self.resize = function( e )
	{
		debug.log( e.message );
	};
	*/

	ApplicationObject.call( this );
}
Async.prototype = Object.create( ApplicationObject.prototype );