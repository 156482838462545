function Cookies()
{
	var self = this;

	self.ready = function()
	{
		self.hideShowContent();
	};

	self.hideShowContent = function()
	{
		/* This was added for Accounts redesign */
		var userLoggedIn = $( 'meta[name="loggedIn"]' ).attr( 'content' );

		// If the site is "individual" and the user is logged in
		if ( application.site === 'individual' && userLoggedIn === 'true' ) {

			// Set an event listener to control the display of the mailing options link
			window.addEventListener( 'onAccountsCookiesSet', function () {

				// If the "disabletab" cookie exists
				if ( application.cookies.exists( 'disabletab' ) ) {

					// If disabletab cookie is set to "true"
					if ( application.cookies.read( 'disabletab' ) === 'true' ) {

						// Hide mailing options link
						$('.mailing-options-link').hide();
					} else {
						
						// Show mailing options link
						$('.mailing-options-link').show();
					}
				}

				// If the "primaryAccount" cookie exists
				if ( application.cookies.exists( 'primaryAccount' ) ) {

					// If primaryAccount cookie is set to "false"
					if ( application.cookies.read( 'primaryAccount' ) === 'false' ) {

						// Hide alerts link
						$('.alerts-link').hide();
					} else {
						
						// Show alerts link
						$('.alerts-link').show();
					}
				}
			});

			// Fire the onAccountsCookiesSet event on page load, it will also load on accounts react app load, but this will hide the nav for other pages on our site
			window.dispatchEvent( new CustomEvent( 'onAccountsCookiesSet' ) );

		}
	};

	self.create = function( name, value, duration, domain )
	{
		var expires = "",
			domainString = "";

		if ( duration )
		{
			var date = new Date();
			date.setTime( date.getTime() + ( duration * 24 * 60 * 60 * 1000 ) );
			expires = "; expires=" + date.toUTCString();
		}

		if ( typeof domain !== "undefined" ) {
			domainString = "; domain=" + domain;
		}
		
		document.cookie = name + "=" + value + expires + domainString + "; path=/";
	};

	self.read = function( name )
	{
		var nameEQ = name + "=";
	    var ca = document.cookie.split( ';' );

	    for( var i = 0; i < ca.length; i++ )
	    {
	        var c = ca[i];
	        while( c.charAt(0) == ' ' ) 
	        	c = c.substring( 1, c.length );

	        if( c.indexOf( nameEQ ) === 0 )
	        	return c.substring( nameEQ.length, c.length );
	    }

	    return null;
	};

	self.exists = function( name )
	{
		return ( application.cookies.read( name ) !== null );
	};

	self.delete = function( name )
	{
		self.create( name, "" , -1 );
	};

	ApplicationObject.call( this );
}
Cookies.prototype = Object.create( ApplicationObject.prototype );