function StickyNav()
{
	var self					= this,
		$stickyNav				= $( '.sticky-nav' ),
		$stickyNavLinkContainer	= $( '.sticky-nav>div:last-child' ),
		$stickyNavLinks			= $( '.sticky-nav>div:last-child a' ),
		$lastStickyContentArea	= $( '[data-sticky-content]' ).last(),
		stickyNavOffset			= $stickyNav.offset().top,
		stickyNavHeight			= $stickyNav.outerHeight(),
		stickyStoppingPoint		= $lastStickyContentArea.offset().top + $lastStickyContentArea.outerHeight( true ) - stickyNavHeight,
		clickedScrolling		= false,
		scrollPosition			= 0,
		scrollTimeout			= null;

	self.ready = function()
	{
		$stickyNavLinks.click( function( event ) {
			event.preventDefault();

			// Don't allow clicks while page is animating
			if ( !$('body:animated').length ) {
				clickedScrolling = true;

				var $this = $( this ),
					stickyTarget = $this.data( 'sticky-target' ),
					$stickyContent = $( '[data-sticky-content=\'' + stickyTarget + '\']' ),
					index = $stickyNavLinks.index( this );

				$stickyNavLinks.removeClass( 'active' );
				$this.addClass( 'active' );

				$( 'body, html' ).animate({
					scrollTop: $stickyContent.offset().top - stickyNavHeight
				}, 500, function () {
					// For some reason, a scroll event fires after the animate, so need a delay to prevent badness
					setTimeout( function(){
						clickedScrolling = false;
					}, 50 );
				});
				
				self.slideStickyNav( index );
			}
		});

		if ( application.utilities.getParameterByName('tab') ) {
			$( '[data-sticky-target=\'' + application.utilities.getParameterByName('tab') + '\']' ).click();
		}

		if ( window.location.hash ) {
			$( '[data-sticky-target=\'' + window.location.hash.replace("#", "") + '\']' ).click();
		}
	};

	self.loaded = function()
	{
		self.adjustStickyNav();

		if ( window.location.hash ) {
			var $clickTarget = $( '[data-sticky-target=\'' + window.location.hash.replace("#", "") + '\']' ),
				userMovement = false;
			
			$clickTarget.click();

			$( window ).scroll( function() {
				if ( !clickedScrolling ) {
					userMovement = true;
				}
			});

			setTimeout( function(){
				if ( !userMovement ) {
					$clickTarget.click();
				}
			}, 200 );
		}
	};

	self.resize = function( e )
	{
		stickyNavHeight = $stickyNav.outerHeight( true );
		stickyStoppingPoint = $lastStickyContentArea.offset().top + $lastStickyContentArea.outerHeight( true ) - stickyNavHeight;
		self.adjustStickyNav();
	};

	self.scroll = function( e )
	{
		scrollPosition = e.message;
		stickyStoppingPoint = $lastStickyContentArea.offset().top + $lastStickyContentArea.outerHeight( true ) - stickyNavHeight;
		self.adjustStickyNav();
	};

	self.slideStickyNav = function ( itemIndex ) {
		if ( !$('.sticky-nav>div:animated').length || $(window).scrollTop() + $(window).height() == $(document).height() ) {
			$stickyNavLinkContainer.animate({
				scrollLeft: $stickyNavLinks.eq( itemIndex )[0].offsetLeft - 15 
			}, 200 );
		}
	};

	self.adjustStickyNav = function () {

		if ( scrollTimeout ) clearTimeout( scrollTimeout );
		scrollTimeout = setTimeout( function(){ 
			if ( !$stickyNav.hasClass( 'sticky-scrolled' ) ) {
				stickyNavOffset = $stickyNav.offset().top;
				stickyNavHeight = $stickyNav.outerHeight( true );
			}
		}, 100 );

		if ( scrollPosition > stickyStoppingPoint ) {
			$stickyNav.removeClass( 'sticky-scrolled' );
			$('body').removeClass( 'sticky' );
			$stickyNav.next().css( 'padding-top', '' );
		} else if ( scrollPosition > stickyNavOffset ) {
			$stickyNav.addClass( 'sticky-scrolled' );
			$('body').addClass( 'sticky' );
			$stickyNav.next().css( 'padding-top', stickyNavHeight );
		} else {
			$stickyNav.removeClass( 'sticky-scrolled' );
			$('body').removeClass( 'sticky' );
			$stickyNav.next().css( 'padding-top', '' );
		}

		$stickyNavLinks.each( function( index ) {
			var $this = $( this ),
				stickyTarget = $this.data( 'sticky-target' );
			
			// Null check to keep non sticky anchor tags from throwing an error - AC
			if( stickyTarget !== undefined )
			{
				var	$stickyContent = $( '[data-sticky-content=\'' + stickyTarget + '\']' ),
				stickyContentTop = $stickyContent.offset().top - stickyNavHeight,
				stickyContentBottom = stickyContentTop + $stickyContent.outerHeight( true );
				if (!clickedScrolling) {
					if ( $(window).scrollTop() + $(window).height() == $(document).height() ) {
						$stickyNavLinks.removeClass( 'active' ).eq( $stickyNavLinks.length-1 ).addClass( 'active' );
						self.slideStickyNav( $stickyNavLinks.length-1 );
					} else if ( scrollPosition >= stickyContentTop && scrollPosition <= stickyContentBottom ) {
						$stickyNavLinks.removeClass( 'active' ).eq( index ).addClass( 'active' );
						self.slideStickyNav( index );
					}
				}
			}
		});
	};

	ApplicationObject.call( this );
}
StickyNav.prototype = Object.create( ApplicationObject.prototype );
