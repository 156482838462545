/**
* 	ApplicationObject
*	- Wrapper Class for handling application events
*	- Adds event listeners for application events
*	- When new application specific events are needed, and listeners here
*	- Inherits from EventHandler
*/
function ApplicationObject()
{
	if( typeof( this.awake ) == 'function' ){
		this.awake();
	} 

	if( application.started && typeof( this.loaded ) == 'function' ) this.loaded();

	if( !application.started && typeof( this.loaded ) == 'function' ) application.addEventListener( Event.ApplicationLoaded, this.loaded );
	if( typeof( this.ready ) == 'function' ) application.addEventListener( Event.ApplicationReady, this.ready );
	if( typeof( this.resize ) == 'function' ) application.addEventListener( Event.ApplicationResize, this.resize );
	if( typeof( this.scroll ) == 'function' ) application.addEventListener( Event.ApplicationScroll, this.scroll );
}
ApplicationObject.prototype = Object.create( EventHandler.prototype );
ApplicationObject.prototype.constructor = ApplicationObject;