function PieChartRenderer( container, selector, config )
{
	var self = this;

	var pieValues = [];

	$( selector ).css( 'padding', 0 );

	var svg = d3.select( selector ).append( 'svg' )
								   .attr( 'width', config.width )
								   .attr( 'height', config.height );

	var block = svg.append( 'g' ).classed( 'chart-container', true );

	var rawWidth = $( selector ).width();
	var rawHeight = $( selector ).height();

	self.addValue = function( index, data, color )
	{
		data.colorValue = color;
		pieValues.push( data );

		draw();
	}

	self.resize = function()
	{
		var nw = $( selector ).width();
		var nh = $( selector ).height();

		if( nw !== rawWidth || nh !== rawHeight )
		{
			rawWidth = nw;
			rawHeight = nh;
			draw();
		}
	}

	function draw()
	{
		block.selectAll( 'path' ).remove();

		var radius = ( Math.min( rawWidth, rawHeight ) / 2 );

		var arcConfig = d3.arc()
					  	  .innerRadius( radius / 1.75 )
						  .outerRadius( radius );

		var arcData = d3.pie()
						.value( function( d ){ return d.value; } )
						.sort( null )( pieValues );

		block.selectAll( 'path' )
			 .data( arcData )
			 .enter()
			 .append( 'path' )
			 .attr( 'd', arcConfig )
			 .style( 'fill', function( d ){ return d.data.colorValue; } );

		reposition();
	}

	function reposition()
	{
		block.attr( 'transform', 'translate( {0}, {1} ), scale( 0.9, 0.9 )'.format( rawWidth * 0.5, rawHeight * 0.5 ) );
	}
}
