function ChartController()
{
	var self = this;

	var charts = [];

	self.ready = function()
	{
		application.addEventListener( Event.ChartRequest, onChartRequest );
	}

	self.resize = function( width )
	{
		if( charts.length > 0 )
		{
			for( var i = 0; i < charts.length; i++ )
			{
				charts[i].resize();
			}
		}
	}

	function onChartRequest( request )
	{
		var m = request.message;
		self.generateChart( m.element, m.data, Chart.pie );
	}

	self.generateChart = function( containerSelector, data, type )
	{
		$( containerSelector ).empty();

		switch( type )
		{
			case Chart.pie:
				var chart = new Chart( containerSelector, data );
				charts.push( chart );
			break;

			default:
				debug.logWarning( '({0}) - no chart format found for specified type: {1}'.format( containerSelector, type ) );
			break;
		}
	}

	ApplicationObject.call( this );
}
ChartController.prototype = Object.create( ApplicationObject.prototype );

function Chart( selector, data )
{
	var self = this;

	// debug.log( selector );
	// debug.log( data );

	var config = {
		width : '100%',
		height : '100%'
	}

	var container = $( selector );
	var containerId = selector.replace( '.', '' );

	var sections = [];

	self.init = function()
	{
		addSection( 'label-section', Chart.sections.label_list, 7 );
		addSection( 'chart-section', Chart.sections.pie_chart, 5 );

		drawData();

		container.fadeTo( 400, 1 );
	}

	self.resize = function()
	{
		for( var i = 0; i < sections.length; i++ )
		{
			if( typeof( sections[i].renderer.resize ) == 'function' )
			{
				sections[i].renderer.resize();
			}
		}
	}

	function addSection( sectionID, type, colnum )
	{

		var sectionData = {};

		var sectionSelector = '{0}-{1}'.format( containerId, sectionID );

		sectionData.selector = '.'+sectionSelector;
		$( selector ).append( '<div class="col-{0} {1}">'.format( colnum, sectionSelector ) );

		switch( type )
		{
			case( Chart.sections.label_list ):
				sectionData.renderer = new LabelListRenderer( selector, sectionData.selector, config );
			break;
			case( Chart.sections.pie_chart ):
				sectionData.renderer = new PieChartRenderer( selector, sectionData.selector, config );
			break;
		}

		sections.push( sectionData );
	}

	function drawData()
	{
		for( var s = 0; s < sections.length; s++ )
		{
			for( var i = 0; i < data.length; i++ )
			{
				sections[s].renderer.addValue( i, data[i], getColor( data[i], i ) );
			}
		}
	}

	function getColor( d, i )
	{
		switch( d.colors )
		{
			case( 'minm' ):
				return Chart.colors.minm[i];

			case( 'yesno' ):
				return Chart.colors.yesno[i];
		}
		return Chart.colors.general[i];
	}

	self.init();

}

// Chart Type Consts
Chart.pie = 'pie';

// Section Type Consts
Chart.sections = {};
Chart.sections.label_list = 'LABEL_LIST';
Chart.sections.pie_chart = 'PIE_CHART';

// Color Pools
Chart.colors = {};
Chart.colors.general = [ '#ef3c3c', '#f1ac47', '#f7e431', '#acd037', '#4dc6e0', '#4573b9', '#7f54a2', '#bfbfbf' ];
Chart.colors.yesno = [ '#96152b', '#205681', '#bfbfbf' ];
Chart.colors.minm = [ '#f88325', '#e8b032', '#64278d', '#1d58bb' ];
