function AutoNav()
{

	var self = this,
		w = $( window ).width();

	self.awake = function()
	{
		//debug.log('awake');
	};

	self.ready = function()
	{
		/* Auto nav dropdown toggle */
		$('[class*=auto-nav]').each(function(e){

			var $autonav = $(this),
				breakpoint = "";

			if($autonav.hasClass('auto-nav-xs'))
				breakpoint = BREAKPOINT_XS;
			else if($autonav.hasClass('auto-nav-sm'))
				breakpoint = BREAKPOINT_SM;
			else if($autonav.hasClass('auto-nav-md'))
				breakpoint = BREAKPOINT_MD;
			else if($autonav.hasClass('auto-nav-lg'))
				breakpoint = BREAKPOINT_LG;

			$autonav.find('a, h4, h5, h6').on('click', function(e){
				var $this = $(this),
					subnav = $this.siblings('ul'),
					thisHref = $this.attr('href'),
					hasHref = !(thisHref === undefined || thisHref == '#');

				if(subnav[0] && !hasHref){
					if(w <= breakpoint || breakpoint === ""){
						e.preventDefault();

						// Close currently open, if any
						var parentId = $this.attr( 'data-parent' );
						if( parentId !== undefined && parentId !== '' )
						{
							$( parentId ).find( 'a, h4, h5, h6' ).each( function(){
								if( $(this)[0] !== $this[0] && $(this).attr( 'data-parent' ) === parentId )
								{
									$(this).find('.putnamicon').removeClass('putnamicon-angle-up').addClass('putnamicon-angle-down');
									$(this).removeClass('active');
									if( $(this).siblings('ul') !== undefined )
										$(this).siblings('ul').slideUp(300);
								}
							} );
						}
						
						// THIS ISN'T WORKING CURRENTLY
						/*var $current = $this.parent().siblings('li').children('.active');
						if($current.length && !$this.hasClass('active')) {
							$current.removeClass('active');
							$current.find('.putnamicon').toggleClass('putnamicon-angle-down putnamicon-angle-up');
							$current.siblings('ul').slideToggle(300);
						}*/

						// Open clicked
						$this.find('.putnamicon').toggleClass('putnamicon-angle-down putnamicon-angle-up');
						if ( !$this.hasClass('active') ) {
							$this.addClass('active');
							subnav.slideToggle(300);
						} else {
							subnav.slideToggle(300, function(){
								$this.removeClass('active');
							});
						}
					}
				}
			});
		});
	};

	/*self.loaded = function()
	{
		debug.log('loaded');
	};*/

	self.resize = function( e )
	{
		w = $( window ).width();

		// Removes style attribute of navs when transitioning from xs breakpoint (display:none)
		$('.auto-nav ul, .auto-nav-xs ul').each(function(){
			if(w > BREAKPOINT_XS){
				self.resetAutoNav( this );
			}
		});
		// Removes style attribute of navs when transitioning to small breakpoint (display:none)
		$('.auto-nav-sm ul').each(function(){
			if(w > BREAKPOINT_SM){
				self.resetAutoNav( this );
			}
		});
		// Removes style attribute of navs when transitioning to medium breakpoint (display:none)
		$('.auto-nav-md ul').each(function(){
			if(w > BREAKPOINT_MD){
				self.resetAutoNav( this );
			}
		});
		// Removes style attribute of navs when transitioning to large breakpoint (display:none)
		$('.auto-nav-lg ul').each(function(){
			if(w > BREAKPOINT_LG){
				self.resetAutoNav( this );
			}
		});
	};

	self.resetAutoNav = function ( navElement ) {
		var $navElement = $(navElement),
			$icon = $navElement.find('.putnamicon-angle-up');

		$navElement.removeAttr('style');
		$navElement.removeClass('show-me'); 
		$navElement.parent().find('a, h4, h5, h6').removeClass('active');
		$icon.removeClass('putnamicon-angle-up');
		$icon.addClass('putnamicon-angle-down');
	};

	ApplicationObject.call( this );
}
AutoNav.prototype = Object.create( ApplicationObject.prototype );
