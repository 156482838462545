function FatSearch(){

	var self					= this,
		w						= $(window).width(),
		siteID					= window.location.pathname.split('/')[1],
		$fatSearchLinks			= $('.start-search'),
		$fatSearch				= $('.fat-search'),
		$fatSearchArea			= $('.fat-search-area'),
		$fatSearchForm			= $('.fat-search-form'),
		$fatSearchInput			= $('.fat-search-input'),
		$fatNavResults			= $('.fat-nav-results'),
		$navBarToggler			= $('.navbar-toggler'),
		$mobileFatSearchLink	= $('.mobile-fat-search-link');

	self.isSearchPage = PG.pageMeta == 'search-results';
	self.fundResultsHeaderText = "Funds";

	self.ready = function(){

		// Fat search form handlers
		if ($fatSearchForm.length) {
			$fatSearchArea.on("click", ".fat-search-all-results", function(event){
				event.preventDefault();
				$(this).closest('.fat-nav-results').siblings('.fat-search-form').submit();
			});

			$fatSearchArea.on("click", ".fat-search-clear-results", function(event){
				event.preventDefault();
				$fatSearchInput.val('');
				$(this).closest('.fat-nav-results').empty();
			});

			$mobileFatSearchLink.click(function(event){
				event.preventDefault();
				$(this).closest('.fat-search-form').submit();
			});

			$fatSearchForm.submit(function(){
				const $searchInput = $(this).find('.fat-search-input')
				if($searchInput.val()===""){
					$searchInput.focus();
					return false;
				} else if(self.isSearchPage) {
					self.queryPGSearch($searchInput.val());
					return false;
				}
			});
			$fatSearchInput.on("change paste keyup", function(){
				var $this=$(this), delay=10;
				clearTimeout($this.data('timer'));
				$this.data('timer', setTimeout(function(){
					$this.removeData('timer');
					if($this.val() !== ""){
						if (self.isSearchPage) {
							self.queryPGSearch($this.val());
						} else {
							self.getSearchResults($this.val());
						}
					}else{
						self.clearSearchResults();
					}
				}, delay));
			});
		}

		// Fat search modal handlers
		if ($fatSearchLinks.length) {
			$fatSearchLinks.click(function(event){
				event.preventDefault();
				var  $this=$(this);
				if($this.hasClass('active')){
					self.closeFatSearch();
				}else{
					self.openFatSearch();
				}
			});

			if ( $fatSearch.hasClass('modal-treatment') ) {
				self.fundResultsHeaderText = "Putnam fund suggestions";
				if ( $fatSearch.is(':visible') ) {
					self.openModalySearch();
				}
				$fatSearchLinks.click( function (){
					self.openModalySearch();
				});
				$('body').on('touchend click', '.search-modal', function () {
					self.closeModalySearch();
				});
				$(document).keyup(function(e) {
					if (e.keyCode === 27) {
						self.closeModalySearch();
					}
				});
			}
		}
	};

	self.resize = function(){
		w=$(window).width();
	};

	self.queryPGSearch = function (searchTerm) {
		var suggestQuery = self.fusionEncoding(searchTerm);
		suggestQuery = encodeURIComponent(suggestQuery);
		
		if ('URLSearchParams' in window) {
			const searchParams = new URLSearchParams(window.location.search)
			searchParams.set("q", suggestQuery);
			const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
			history.pushState(null, '', newRelativePathQuery);
		}
		PGSearch.searchSite(suggestQuery);
	}

	self.openFatSearch = function(){
		if(!$navBarToggler.hasClass('collapsed')&&w<BREAKPOINT_SM){
			$navBarToggler.click();
		}
		$fatSearch.slideDown(300, function(){
			$fatSearchForm.fadeTo(150, 1);
		});
		$fatSearchInput.select();
		$fatSearchLinks.addClass('active');
	};

	self.closeFatSearch = function(){
		$fatSearch.slideUp(150);
		$fatSearchForm.fadeTo(150, 0);
		$fatSearchLinks.removeClass('active');
	};

	self.openModalySearch = function(){
		$('body').addClass('modal-open');
		$('<div class="search-modal"></div>').hide().appendTo( document.body ).fadeIn(300);
	};

	self.closeModalySearch = function(){
		self.closeFatSearch();
		$('.search-modal').fadeOut('300', function() {
			$('body').removeClass('modal-open');
			$('.search-modal').remove();
		});
	};

	// Some characters need to be encoded for Fusion, beside the regular encoding
	self.fusionEncoding = function(query){
		query = query.replace(/%/g,'%25');
		query = query.replace(/#/g,'%23');
		query = query.replace(/&/g,'%26');
		query = query.replace(/\?/g,'%3F');
		return query;
	};

	self.getSearchResults = function(query){
		var suggestQuery = self.fusionEncoding(query);
		suggestQuery = encodeURIComponent(suggestQuery);
		var fundQuery = encodeURIComponent(query);
		// Prod urls for data, uncomment when going to CMS
		var gsaUrl='/'+siteID+'/retail/suggest/?q=', fundSearchUrl='/'+siteID+'/search/search-funds/?q=', gsaResults = [], fundResults = [], dataType = 'json';

		if ( siteID != "institutional" ) {
			$.when(
				// GSA results
				$.ajax({
					url:gsaUrl+suggestQuery,
					dataType:dataType,
					method:'get',
					cache:false,
					success: function (data) {
						gsaResults = data;
						// console.log("GSA results", gsaResults);
					}
				}),
				// Fund Search results
				$.ajax({
					url:fundSearchUrl+fundQuery,
					dataType:dataType,
					method:'get',
					cache:false,
					success:function(data){
						console.log("Fund results", typeof data === 'string' ? JSON.parse(data) : data);
						fundResults=data;
					}
				})
			).then(function(){
				if ( (gsaResults && gsaResults.length > 0) || (fundResults && fundResults.length > 0) ){
					self.parseSearchResults( gsaResults, fundResults );
				}
			});
		} else {
			$.when(
				// GSA results
				$.ajax({
					url:gsaUrl+suggestQuery,
					dataType:'json',
					method:'get',
					cache:false,
					success:function(data){
						gsaResults=data;
					}
				})
			).then(function(){
				if ( gsaResults.length > 0 ){
					self.parseSearchResults( gsaResults );
				}
			});
		}		
	};

	self.parseSearchResults = function( gsaResults, fundResults ){
		var newHTML='<div class="row">';
		if( gsaResults && gsaResults.length > 0) {
			var gsaResultsLength=gsaResults.length;
			gsaResultsLength = gsaResultsLength > 5 ? 5 : gsaResultsLength;

			// Loop through all GSA results. If the result matches fundResults, remove it from the list
			if (typeof fundResults !== "undefined") {
				if ( typeof fundResults === "string" ) {
					fundResults = JSON.parse(fundResults);
				}
				for ( let i = 0; i < gsaResultsLength; i++ ) {
					for ( let j = 0; j < fundResults.length; j++ ) {
						if ( gsaResults[i] == fundResults[j].n.toLowerCase() ) {
							gsaResults.splice(i, 1);
							gsaResultsLength--;
						}
					}
				}
			}

			if (gsaResultsLength > 0) {
				newHTML+='<div class="col-md"><h4 class="mt-0">Results</h4><hr><ul>';
				for(let i=0; i<gsaResultsLength; i++){
					var suggestSpecialEncoded = self.fusionEncoding(gsaResults[i]);
					newHTML+='<li><a href="/'+siteID+'/search/?q='+suggestSpecialEncoded+'">'+gsaResults[i]+'</a></li>';
				}
				newHTML+='</ul></div>';
			}
		}
		
		if ( typeof fundResults !== "undefined" ){
			if ( typeof fundResults === "string" ) {
				fundResults = JSON.parse(fundResults);
			}
			if ( fundResults.length > 0 ) {
				var onlyFundResults = self.getSearchResultByType(fundResults, "Funds");
				var onlyETFResults = self.getSearchResultByType(fundResults, "ETFs");

				if (onlyFundResults.length > 0) {
					newHTML += self.getSearchResultsHTML(onlyFundResults, "Funds");
				}
				if (onlyETFResults.length > 0) {
					newHTML += self.getSearchResultsHTML(onlyETFResults, "ETFs");
				}
			}
		}
		newHTML+='</div><hr><a href="#" class="fat-search-clear-results hidden-md-up btn btn-outline-secondary mt-1 ml-1 mb-2">Clear results</a>';
		// See all results button
		// newHTML+='<a href="#" class="fat-search-all-results btn btn-outline-primary mt-1 mb-2">See all results</a> <a href="#" class="fat-search-clear-results hidden-md-up btn btn-outline-secondary mt-1 ml-1 mb-2">Clear results</a>';
		$fatNavResults.html(newHTML);
	};

	self.getSearchResultByType = function(resultsJSON, type){
		var results = [];
		for (let i = 0; i < resultsJSON.length; i++) {
			if (type && resultsJSON[i].c == type) {
				results.push(resultsJSON[i]);
			}
		}
		return results;
	};

	self.getSearchResultsHTML = function(results, type='Funds'){
		var fundPath = type == 'Funds' ? '/mutual-funds/funds/' : '/etf/';
		var html = '<div class="col-md"><h4 class="mt-0">' + type + '</h4><hr><ul>';
		var typedQuery = "";
		if (w > 768) {
			typedQuery = $fatSearchInput.eq(1).val();
		} else {
			typedQuery = $fatSearchInput.eq(0).val();
		}

		for (let j = 0; j < results.length; j++) {
			var fundName = results[j].n;
			html += '<li><a href="/' + siteID + fundPath + results[j].id + '/">' + fundName + '</a></li>';
		}
		html += '</ul></div>';
		return html;
	};

	self.clearSearchResults = function(){
		$fatNavResults.empty();
	};

	ApplicationObject.call(this);
}
FatSearch.prototype = Object.create(ApplicationObject.prototype);