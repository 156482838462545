/* jshint ignore:start */
function FundScreener()
{
	var self = this;

	var environment;
	var filterControls = [];
	var updateTimeout;
	var cleanFilter;

	var isIE11 = navigator.userAgent.match(/Trident\/7\./);

	var cleanDataSet = {}

	// Filter data sent to the API to configure the return values
	self.data = {
		assetClasses : [],
		annualizedReturns : { min : "-999", max : "999" },
		timePeriod : '',
		morningStar : 0,
		aboveLipper : false,
		portfolioManager : '',
		searchString : '',
		shareClass : '',
		characteristic : ''
	}

	self.ready = function()
	{
		environment = 	( window.location.href.indexOf( 'advisor' ) != -1 ) ? 'advisor' : 
						( window.location.href.indexOf( 'individual' ) != -1 ) ? 'individual' : 
						( window.location.href.indexOf( 'dcio' ) != -1 ) ? 'dcio' :
						( window.location.href.indexOf( 'institutional' ) != -1 ) ? 'institutional' : undefined;

		// Initialization filter components and beginning data state
		if( $( "#fund-screener-filter" ).length )
		{
			console.log( self.data );
			FundSearch();
			FundCategorySelects();
			FilterControls();

			InvokeInitialFilters();

			cleanDataSet = $.extend( true, {}, self.data );

			self.Update();
		}
	};

	// Handler for search input
	function FundSearch()
	{
		if( $( '#fundSearch .putnam-search-field' ).length )
		{
			$( '#fundSearch .putnam-search-field' ).on( 'input', function(){
				self.data.searchString = $(this).val();
				self.Update();
			} );
		}
	}

	// Handlers for share class and characteristic dropdowns
	function FundCategorySelects()
	{
		self.data.shareClass = $( '#share-class-select option:selected' ).val();
		self.data.characteristic = $( '#characteristics-select option:selected' ).val();

		$( '#share-class-select' ).on( 'change', function(){
			self.data.shareClass = $(this).val();
			ClearFilters();
		} );

		$( '#characteristics-select' ).on( 'change', function(){
			self.data.characteristic = $(this).val();
			self.Update();
		} );
	}

	// Instantiate filter classes and add them to the filter array
	function FilterControls()
	{
		filterControls.push( new AssetClassFilters( '#asset-class-filters', self ) );
		filterControls.push( new AnnualizedReturnsFilter( '#annualized-returns-filter', self ) );
		filterControls.push( new TimePeriod( '#time-period-filter', self ) );
		filterControls.push( new MorningStar( '#morningstar-ratings-filter', self ) );
		filterControls.push( new AboveLipper( '#above-lipper-filter', self ) );
		filterControls.push( new PortfolioManager( '#portfolio-manager-filter', self ) );
		
		// For debugging Chrome back button autofill bug
		// $( "#filter-collapse-clickoff-root select" ).each( function(){
		// 	console.log( $(this).find( 'option:first' ).val() );
		// 	$(this).val( $(this).find( 'option:first' ) );
		// } );

		$( '#fund-screener-filter-clear' ).on( 'click', function( e ){ 
			e.preventDefault();
			ClearFilters();
		} );
	}

	// Set filters based on URL parameters
	function InvokeInitialFilters()
	{
		var queryStrings = window.location.search.substring(1).split( "&" );
		for( var i = 0; i < queryStrings.length; i++ )
		{
			var queryItems = queryStrings[i].split( "=" );
			
			switch( queryItems[0] )
			{
				case "asset-class" :
					var assetClasses = queryItems[1].split( ',' );
					for( var j = 0; j < assetClasses.length; j++ )
					{
						if( $( '.asset-class-filter[data-asset-class="' + assetClasses[j] + '"]' ).length !== -1 )
						{
							filterControls[0].select( $( '.asset-class-filter[data-asset-class="' + assetClasses[j] + '"]' ) );
						}
					}
					break;

				case "characteristics" :
					// console.log($('#characteristics-select option').val());
					$('#characteristics-select').val(queryItems[1]);
					self.data.characteristic = queryItems[1];
					self.Update();
					break;
				
				case "funds" :
					var funds = queryItems[1].split( ',' ).join( ' ' );
					$( '#fundSearch .putnam-search-field' ).val( funds );
					self.data.searchString = funds;
					self.Update();
					break;
			}
		}
	}

	// Clears all filters
	function ClearFilters()
	{
		filterControls.forEach( function( e ){
			e.clear();
		} );

		$( '#fundSearch .putnam-search-field' ).val("");
		self.data.searchString = "";

		self.Update();
	}

	// Send the filter data to the API
	function RequestFunds( requestFilters )
	{
		// console.log( requestFilters );
		$.ajax( {
			url : '/' + environment + '/mutual-funds/fund-screener-data',
			method : 'POST',
			data : { "filters" : requestFilters },
			dataType : 'jsonp',
			async: false,
			jsonpCallback: "filter",
			crossDomain : true
		} ).done( function( data ){
			PopulateFundTable( data );
		} ).fail( function(  jqXHR, textStatus  ){
			console.error( "failed: " + textStatus );
			console.error( jqXHR );
		} );
	}

	// Uses the returned data to populate the screener table
	function PopulateFundTable( fundData )
	{
		if( !isIE11 )
			$( "#fund-screener-table" ).addClass('css-grid-table');

		var funds = fundData.funds;

		// console.log( fundData );

		var footerItems = GenerateTableFooterItems( funds );
		$( '#fund-screener-table' ).after( footerItems );

		// disabled sort function to enforce sorting applied in the model (returned by fund-screener-data end-point)  
		/*funds.sort( function( a, b ){
			 return ( a.name < b.name ) ? -1 : ( a.name > b.name ) ? 1 : 0;
		} );*/

		if( $( "#fund-screener-table" ).length )
		{
			var fundScreenerClasses = $( '#fund-screener-table' ).attr( 'class' ).split( " " ).map( function( cssClass ){
				return cssClass.indexOf( 'data-columns-' ) === 0 ? '' : cssClass;
			} );
			$( '#fund-screener-table' ).attr( 'class', fundScreenerClasses.join( " " ) );

			$( '#fund-screener-table' ).empty();
			
			if( funds.length === 0 ) return;

			var envTableClass = environment + '-fund-table';
			var dataColumnCount = funds[0].characteristicData.headers.length;
			// console.log(funds[0].characteristicData);

			$( '#fund-screener-table' ).addClass( envTableClass );
			$( '#fund-screener-table' ).addClass( 'data-columns-' + dataColumnCount );

			var elementString = "";
			if( !isIE11 )
			{
				// Generate Grid Headers
				elementString += GenerateGridHeadersString( funds, dataColumnCount );
				// Generate Grid Rows
				elementString += GenerateGridItemsString( funds, dataColumnCount );
			}
			else
			{
				// Generate IE11 HTML Table since css grid on IE11 it awful
				elementString += GenerateIE11ScreenerTable( funds, dataColumnCount );
			}

			$( '#fund-screener-table' ).append( elementString );

			if( isIE11 )
			{
				SetIE11StickyHeader();
			}
		}

		SetupPostRequestFilterAttributes();

		// if( !isIE11 )
		// {
		// 	SetupPostRequestFilterAttributes();
		// }
		// else
		// {
		// 	setTimeout( function(){
		// 		SetupPostRequestFilterAttributes();
		// 	}, 250 );
		// }
	}

	// Generates the header section of the screener table
	function GenerateGridHeadersString( funds, dataColumnCount )
	{
		var columnIndex = 0;
		
		var elementString = '<div data-column-index=' + columnIndex++ + ' class="css-table-header fund-screener-header bold">&nbsp;</div>';
		elementString += 	'<div data-column-index=' + columnIndex++ + ' data-first-sort=1 class="css-table-header fund-screener-header sort-head"><span role="button"><span class="bold">Fund</span> ' + GetMultipleShareClassString() + ' <span class="sort-arrows"></span></span></div>';
		// elementString += 	'<div data-column-index=' + columnIndex++ + ' class="css-table-header fund-screener-header bold hidden-md-down"><span role="button">Ticker <span class="sort-arrows"></span></span></div>';
		elementString += 	'<div data-column-index=' + columnIndex++ + ' data-first-sort=1 class="css-table-header fund-screener-header sort-head bold hidden-sm-down"><span role="button">Category <span class="sort-arrows"></span></span></div>';

		// Data Column Headers
		for( var i = 0; i < dataColumnCount; i++ )
		{
			elementString += '<div data-column-index=' + columnIndex++ + ' class="css-table-header fund-screener-header sort-head bold hidden-sm-down"><span role="button">' + funds[0].characteristicData.headers[i] + ' <span class="sort-arrows"></span></span></div>';
		}
		// End Data Column Headers

		elementString += '<div data-column-index=' + columnIndex++ + ' class="css-table-header fund-screener-header bold justify-content-end hidden-md-down"></div>';

		// if( environment === 'advisor' || environment === 'dcio' )
		// 	elementString += '<div data-column-index=' + columnIndex++ + ' class="css-table-header fund-screener-header bold justify-content-center hidden-md-down">Compare</div>';
		// if( environment === 'advisor' )
		// 	elementString += '<div data-column-index=' + columnIndex++ + ' class="css-table-header fund-screener-header bold justify-content-center hidden-md-down">Track</div>';

		elementString += '<div data-column-index=' + columnIndex++ + ' class="css-table-header fund-screener-header hidden"></div>'; // Placeholder snapshot for IE11 CSS Grid placement, delete when IE11 explodes

		return elementString;
	}

	// Generates the data section of the screener table
	function GenerateGridItemsString( funds, dataColumnCount )
	{
		var elementString = '';
		for( var i = 0; i < funds.length; i++ )
		{
			var collapseId = ( funds[i].ticker !== "--" ) ? funds[i].ticker : "tickerless" + i;
			var columnIndex = 0;
			elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item justify-content-center"><a class="fund-collapse-toggle" data-toggle="collapse" href="#' + collapseId + '-collapse" role="a" aria-expanded="false" aria-controls="' + collapseId + '-collapse"><span class="putnamicon putnamicon-plus"></span><span class="putnamicon putnamicon-minus"></span></a></div>';
			elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item fund-screener-item-fund-name"><div class="d-flex flex-column"><a href="/' + environment + '/mutual-funds/funds/' + funds[i].id + '/' + funds[i].shareClass + '">' + funds[i].name + '</a><span>' + funds[i].ticker + '</span></div></div>';
			// elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item hidden-md-down">' + funds[i].ticker + '</div>';
			elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item hidden-sm-down">' + funds[i].category + '</div>';

			// Data Column Items
			for( var j = 0; j < dataColumnCount; j++ )
			{
				elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item hidden-sm-down">' + GetFundDataElement( self.data.characteristic, funds[i].characteristicData.items[j] ) + '</div>';
			}
			// End Data Column Items

			var compareMarkup = "";

			if( environment === 'advisor' || environment === 'dcio' )
				compareMarkup = ( funds[i].category == "Stable Value" || funds[i].ticker == "--" || funds[i].id == "765" || !funds[i].fvCompare) ? '<span class="text-muted">Compare</span>' : '<span data-fund="' + funds[i].ticker + '" class="fund-compare-link pointer">Compare</span>';

			var trackingMarkup = ( environment === 'advisor' ) ? '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item d-flex align-items-center hidden-md-down"><div class="">Track &nbsp;</div> <input type="checkbox" class="track-fund d-block" data-id="' + funds[i].trackId + '" data-fund-id="' + funds[i].id + '" data-share-class="' + funds[i].shareClass + '"></div>' : "";

			elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item flex-column align-items-end hidden-md-down">' + compareMarkup + trackingMarkup + '</div>';

			// if( environment === 'advisor' || environment === 'dcio' )
			// 	elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item justify-content-center hidden-md-down">' +  compareMarkup + '</div>';
			// if( environment === 'advisor' )
			// 	elementString += '<div data-row-id="' + i + '" data-column-index=' + columnIndex++ + ' class="fund-screener-item justify-content-center hidden-md-down"><input type="checkbox" class="track-fund" data-id="' + funds[i].trackId + '" data-fund-id="' + funds[i].id + '" data-share-class="' + funds[i].shareClass + '"></div>';

			// Snapshot Data
			elementString += 	'<div data-row-id="' + i + '" class="fund-screener-item fund-snapshot"><div class="collapse w-100" id="' + collapseId + '-collapse">' +
									'<div class="fund-snapshot-data d-flex flex-column flex-md-row justify-content-md-between align-items-md-center px-1 px-md-2 py-1">' + 
										'<div><p><strong>Prior close</strong></p>' +
											'<div>' +
												'<strong class="mr-2">' + funds[i].snapshot.close.price + '</strong>' +
												'<span>' +
													'<span class="putnamicon putnamicon-angle-' + funds[i].snapshot.close.change + '"></span> ' +
													funds[i].snapshot.close.percent +
												'</span> | ' + 
												'<span>' +
													'<span class="putnamicon putnamicon-angle-' + funds[i].snapshot.close.change + '"></span>' +
													funds[i].snapshot.close.dollar +
												'</span>' + 
											'</div>' +
										'</div>' + 
										'<div><p><strong>52-week high</strong></p>' + 
											'<div>' +
												'<strong class="mr-2">' + funds[i].snapshot.high.price + '</strong>' +
												'<span>(' +
													funds[i].snapshot.high.date +
												')</span>' + 
											'</div>' +
										'</div>' + 
										'<div><p><strong>52-week low</strong></p>' +
											'<div>' +
												'<strong class="mr-2">' + funds[i].snapshot.low.price + '</strong>' +
												'<span>(' +
													funds[i].snapshot.low.date +
												')</span>' + 
											'</div>' +
										'</div>' + 
										'<div><p><strong>Product details</strong></p><div><a href="/' + environment + '/mutual-funds/funds/' + funds[i].id + '/' + funds[i].shareClass + '" class="btn btn-sm btn-primary">Full details</a></div></div>' + 
									'</div>' +
								'</div></div>';
		}

		return elementString;
	}

	// Generates the footer of the table that has the filter options currently selected, and the option to remove the filter options.
	// This probably should be it's own class given it's complexity. But it's reliance on the variables within this scope means it was easier to just have it be
	// a function within the FundScreener class. Maybe I'll make it it's own class someday.
	// -AC (then)
	//
	// A-HAHAHAHAHAHAHAHAHAHAHAHA
	// -AC (now)
	function GenerateTableFooterItems( funds )
	{
		var fundLength = funds.length;
		$( '#fund-screener-table-footer-container' ).each( function(){ $(this).remove(); } );
		
		var resultText = ( fundLength > 1 || fundLength == 0 ) ? "results" : "result";

		var footerWrapper = $( '<div id="fund-screener-table-footer-container"></div>' );
		var footerFilter = $( '<div id="fund-screener-table-footer" class="d-flex justify-content-start align-items-center flex-wrap">{0} {1} found</div>'.format( fundLength, resultText ) );

		var filterButtonTemplate = '<div class="fund-screener-table-footer-item">{1} <button filter-id="{0}">[<span class="putnamicon putnamicon-remove"></span>]</button></div>';
		
		var buttons = [];

		if( !cleanFilter )
		{
			// Search Keywords
			var searchKeywords = self.data.searchString.split( " " );
			for( var i = 0; i < searchKeywords.length; i++ )
			{
				if( searchKeywords[i] !== "" && searchKeywords[i] !== " " )
				{
					var keywordButton = $( filterButtonTemplate.format( searchKeywords[i], searchKeywords[i] ) );
					keywordButton.find( 'button' ).on( 'click', function(){
						self.data.searchString = self.data.searchString.replace( $(this).attr( "filter-id" ), "" );
						if( self.data.searchString === " " )
							self.data.searchString = "";

						$( '#fundSearch .putnam-search-field' ).val( self.data.searchString );
						self.Update();
					} );

					buttons.push( keywordButton );
				}
			}

			// Asset classes
			for( var i = 0; i < self.data.assetClasses.length; i++ )
			{
				var niceText = filterControls[0].getNiceText( self.data.assetClasses[i] );
				var assetClassButton = $( filterButtonTemplate.format( self.data.assetClasses[i], niceText ) );
				assetClassButton.find( 'button' ).on( 'click', function(){
					filterControls[0].selectById( $(this).attr( "filter-id" ) );
					self.Update();
				} );

				buttons.push( assetClassButton );
			}

			// Annualized Return Range MIN
			if( self.data.annualizedReturns.min != cleanDataSet.annualizedReturns.min )
			{
				var annualizedReturnMinButton = $( filterButtonTemplate.format( self.data.annualizedReturns.min + "%", self.data.annualizedReturns.min + "%" ) );

				annualizedReturnMinButton.find( 'button' ).on( 'click', function(){
					filterControls[1].clearMin( true );
					self.Update();
				} );

				buttons.push( annualizedReturnMinButton );
			}

			// Annualized Return Range MAX
			if( self.data.annualizedReturns.max != cleanDataSet.annualizedReturns.max )
			{
				var annualizedReturnMaxButton = $( filterButtonTemplate.format( self.data.annualizedReturns.max + "%", self.data.annualizedReturns.max + "%" ) );

				annualizedReturnMaxButton.find( 'button' ).on( 'click', function(){
					filterControls[1].clearMax( true );
					self.Update();
				} );

				buttons.push( annualizedReturnMaxButton );
			}

			// Time period
			if( self.data.timePeriod != cleanDataSet.timePeriod )
			{
				var niceText = filterControls[2].getNiceText( self.data.timePeriod );
				var timePeriodButton = $( filterButtonTemplate.format( self.data.timePeriod, niceText ) );

				timePeriodButton.find( 'button' ).on( 'click', function(){
					filterControls[2].clear();
					self.Update();
				} );

				buttons.push( timePeriodButton );
			}

			// Morning star
			if( self.data.morningStar != cleanDataSet.morningStar )
			{
				var morningStarButton = $( filterButtonTemplate.format( self.data.morningStar, "{0} star".format( self.data.morningStar ) ) );

				morningStarButton.find( 'button' ).on( 'click', function(){
					filterControls[3].clear();
					self.Update();
				} );

				buttons.push( morningStarButton );
			}

			// Lipper rating
			if( self.data.aboveLipper != cleanDataSet.aboveLipper )
			{
				var aboveLipperButton = $( filterButtonTemplate.format( self.data.aboveLipper, "Above Lipper median" ) );

				aboveLipperButton.find( 'button' ).on( 'click', function(){
					filterControls[4].clear();
					self.Update();
				} );

				buttons.push( aboveLipperButton );
			}

			// Manager
			if( self.data.portfolioManager != cleanDataSet.portfolioManager )
			{
				var niceText = filterControls[5].getNiceText( self.data.portfolioManager );
				var portfolioManagerButton = $( filterButtonTemplate.format( self.data.portfolioManager, niceText ) );

				portfolioManagerButton.find( 'button' ).on( 'click', function(){
					filterControls[5].clear();
					self.Update();
				} );

				buttons.push( portfolioManagerButton );
			}

			if( buttons.length > 0 )
			{
				footerFilter.append( '&nbsp;in:&nbsp;' );

				for( var i = 0; i < buttons.length; i++ )
				{
					footerFilter.append( buttons[i] );
				}
			}
		}

		footerWrapper.append( footerFilter );
		// console.log( funds );
		if( funds[0].characteristicData.headers.indexOf( "*YTD" ) !== -1 )
		{
			var ytdAsOfDate = new Date( Date.parse( funds[0].ytdAsOf ) );
			footerWrapper.append( '<div>*YTD return data is as of ' + ytdAsOfDate.toLocaleDateString("en-US") + ' or prior close</div>' );
		}

		return footerWrapper;
	}

	// Generates the Table for IE11
	function GenerateIE11ScreenerTable( funds, dataColumnCount )
	{
		var elementString = 	'<table class="table sortable-table w-100">';
			// Headers
			elementString += 		'<thead>';
			elementString += 			'<tr id="ie11-table-header">';
			elementString += 				'<th class="fund-collapse">&nbsp;</th>';
			elementString += 				'<th class="sort-head fund-selector-name" data-first-sort=1>Fund ' + GetMultipleShareClassString() + ' <span class="sort-arrows"></span></th>';
			// elementString += 				'<th class="sort-head fund-selector-ticker hidden-md-down">Ticker <span class="sort-arrows"></span></th>';
			elementString += 				'<th class="sort-head fund-selector-category hidden-md-down" data-first-sort=1>Category <span class="sort-arrows"></span></th>';

			for( var i = 0; i < dataColumnCount; i++ )
			{
				elementString += 			'<th class="sort-head fund-selector-characteristic justify-content-center hidden-sm-down">' + funds[0].characteristicData.headers[i] + '<span class="sort-arrows"></span></th>';	
			}

			// if( environment === 'advisor' || environment === 'dcio' )
			// 	elementString += 			'<th class="justify-content-center fund-selector-compare hidden-md-down">Compare</th>';
			// if( environment === 'advisor' )
			// 	elementString += 			'<th class="justify-content-center fund-selector-track hidden-md-down">Track</th>';

			elementString += 			'<th class="justify-content-center hidden-md-down"></th>';

			// Table size marker
			elementString += 			'<tr id="ie11-table-header-marker" style="display: none">';
			elementString += 				'<th class="">&nbsp;</th>';
			elementString += 				'<th class="fund-selector-name">Fund ' + GetMultipleShareClassString() + ' <span class="sort-arrows"></span></th>';
			// elementString += 				'<th class="fund-selector-ticker hidden-md-down">Ticker <span class="sort-arrows"></span></th>';
			elementString += 				'<th class="fund-selector-category hidden-md-down">Category <span class="sort-arrows"></span></th>';

			for( var i = 0; i < dataColumnCount; i++ )
			{
				elementString += 			'<th class="fund-selector-characteristic justify-content-center hidden-sm-down">' + funds[0].characteristicData.headers[i] + '<span class="sort-arrows"></span></th>';	
			}

			

			// if( environment === 'advisor' || environment === 'dcio' )
			// 	elementString += 			'<th class="justify-content-center fund-selector-compare hidden-md-down">Compare</th>';
			// if( environment === 'advisor' )
			// 	elementString += 			'<th class="justify-content-center fund-selector-track hidden-md-down">Track</th>';
			// END Table size marker

			elementString += 			'</tr>';
			elementString += 		'</thead>';

			// Items
			elementString += 		'<tbody>';

			for( var i = 0; i < funds.length; i++ )// jshint ignore:line
			{
				var collapseId = ( funds[i].ticker !== "--" ) ? funds[i].ticker : "tickerless" + i;
				elementString += 		'<tr>';
				elementString += 			'<td class="justify-content-center fund-collapse"><a data-toggle="collapse" href="#' + collapseId + '-collapse" role="a" aria-expanded="false" aria-controls="' + collapseId + '-collapse"><span class="putnamicon putnamicon-plus"></span><span class="putnamicon putnamicon-minus"></span></a></td>';
				elementString += 			'<td class="fund-selector-name"><div class="d-flex flex-column"><a href="/' + environment + '/mutual-funds/funds/' + funds[i].id + '/' + funds[i].shareClass + '">' + funds[i].name + '</a><span>' + funds[i].ticker + '</span></div></td>';
				// elementString += 			'<td class="fund-selector-ticker hidden-md-down">' + funds[i].ticker + '</td>';
				elementString += 			'<td class="fund-selector-category hidden-md-down">' + funds[i].category + '</td>';

				for( var j = 0; j < dataColumnCount; j++ )
				{
					elementString += 		'<td class="fund-selector-characteristic justify-content-center hidden-sm-down">' + GetFundDataElement( self.data.characteristic, funds[i].characteristicData.items[j] ) + '</td>';
				}

				var compareMarkup = '';
				var trackingMarkup = '';

				if( environment === 'advisor' || environment === 'dcio' )
					compareMarkup = ( funds[i].category == "Stable Value" || funds[i].ticker == "--" || funds[i].id == "765" || !funds[i].fvCompare ) ? '<span class="text-muted">Compare</span>' : '<span data-fund="' + funds[i].ticker + '" class="fund-compare-link pointer">Compare</span>';
				if( environment === 'advisor' )
					trackingMarkup = '<div class="d-flex align-items-center">Track &nbsp;<input type="checkbox" class="track-fund" data-id="' + funds[i].trackId + '" data-fund-id="' + funds[i].id + '" data-share-class="' + funds[i].shareClass + '"></div>';

				elementString += 		'<td class="fund-selector-compare text-center hidden-md-down">' + compareMarkup + trackingMarkup + '</td>';

				// if( environment === 'advisor' || environment === 'dcio' )
				// 	elementString += 		'<td class="fund-selector-compare text-center hidden-md-down">' + compareMarkup + '</td>';
				// if( environment === 'advisor' )
				// 	elementString += 		'<td class="fund-selector-track text-center hidden-md-down"><input type="checkbox" class="track-fund" data-id="' + funds[i].trackId + '" data-fund-id="' + funds[i].id + '" data-share-class="' + funds[i].shareClass + '"></td>';

				elementString += 			'<td class="fund-snapshot">';
				elementString +=				'<div class="collapse w-100" id="' + collapseId + '-collapse">';
				elementString +=					'<div class="d-flex justify-content-between align-items-center px-2 py-2">' + 
														'<div><p><strong>Prior close</strong></p>' +
															'<div>' +
																'<strong class="mr-2">' + funds[i].snapshot.close.price + '</strong>' +
																'<span>' +
																	'<span class="putnamicon putnamicon-angle-' + funds[i].snapshot.close.change + '"></span> ' +
																	funds[i].snapshot.close.percent +
																'</span> | ' + 
																'<span>' +
																	'<span class="putnamicon putnamicon-angle-' + funds[i].snapshot.close.change + '"></span>' +
																	funds[i].snapshot.close.dollar +
																'</span>' + 
															'</div>' +
														'</div>' + 
														'<div><p><strong>52-week high</strong></p>' + 
															'<div>' +
																'<strong class="mr-2">' + funds[i].snapshot.high.price + '</strong>' +
																'<span>(' +
																	funds[i].snapshot.high.date +
																')</span>' + 
															'</div>' +
														'</div>' + 
														'<div><p><strong>52-week low</strong></p>' +
															'<div>' +
																'<strong class="mr-2">' + funds[i].snapshot.low.price + '</strong>' +
																'<span>(' +
																	funds[i].snapshot.low.date +
																')</span>' + 
															'</div>' +
														'</div>' + 
														'<div><p><strong>Product details</strong></p><div><a href="/' + environment + '/mutual-funds/funds/' + funds[i].id + '/' + funds[i].shareClass + '" class="btn btn-sm btn-primary">Full details</a></div></div>' + 
													'</div>';
				elementString +=				'</div>';
				elementString += 			'</td>';

				elementString += 		'</tr>';
			}

			elementString += 		'</tbody>';

			elementString += 	'</table>';

			return elementString;
	}

	var scrollThreshold = 628;
	function SetIE11StickyHeader()
	{
		var tableTop = $( '#fund-screener-table' ).offset().top;

		$( document ).scroll( function(){

			var tableHeight = $( '#fund-screener-table' ).innerHeight();

			if( $(this).scrollTop() >= scrollThreshold )
			{
				$( '#ie11-table-header-marker' ).css( "display", "table-row" );

				$( '#ie11-table-header' ).css( "position", "fixed" );
				$( '#ie11-table-header' ).css( "top", 0 );

				if( $(this).scrollTop() > tableTop + tableHeight - $( '#ie11-table-header' ).innerHeight() )
					$( '#ie11-table-header' ).css( "top", $( '#ie11-table-header' ).innerHeight() * -1 );
			}
			else
			{
				$( '#ie11-table-header' ).css( "position", "relative" );
				$( '#ie11-table-header-marker' ).css( "display", "none" );
			}

			SetIE11HeaderWidths();
		} );

		SetIE11HeaderWidths();

		$( window ).resize( function(){
			SetIE11HeaderWidths();
		} );

	}

	function SetIE11HeaderWidths()
	{
		if( $( document ).scrollTop() >= scrollThreshold && $( '#ie11-table-header' ).innerWidth() != $( '#ie11-table-header-marker' ).innerWidth() )
		{
			for( var i = 0; i < $( '#ie11-table-header' ).find( 'th' ).length; i++ )
			{
				var width = $( $( '#ie11-table-header-marker' ).find( 'th' ).get(i) ).innerWidth();
				$( $( '#ie11-table-header' ).find( 'th' ).get(i) ).css( "width", width );
			}
		}
	}

	// Formats the display elements used for the data based on the characteristic selected
	function GetFundDataElement( type, data )
	{
		switch( type )
		{
			case 'annualized-before':
			case 'annualized-after':
			case 'cumulative-before':
			case 'cumulative-after':
			case 'volatility':
			case 'profile':
			case 'upmarket-capture-ratio':
			case 'downmarket-capture-ratio':
			case 'information-ratio':
				return '<span>' + data + '</span>';
			case 'morningstar-ratings':
				if( data != '--' )
				{
					var elementString = '<div data-sort-value="' + data + '">';
					for( var i = 0; i < parseInt(data); i++ )
					{
						elementString += '<span class="morningstar-rating putnamicon putnamicon-morningstar"></span>';
					}
					elementString += '</div>';
					return elementString;
				}
				else
					return '<span class="morningstar-rating">--</span>';
			case 'lipper-rankings':
				if( data != '--' )
				{
					console.log( data );
					var dataObj = JSON.parse( data );
					return '<span>' + dataObj.percentage + '&nbsp;(' + dataObj.rank + '/' + dataObj.count + ')</span>';
				}
				else
					return '<span>--</span>';
			default:
				return '<span>--</span>';
		}
	}

	// Sets the filter options that are limited to specific properties
	function SetupPostRequestFilterAttributes()
	{

		// Disable characteristic options depending on share class availability
		if( self.data.shareClass.indexOf( "A" ) != -1 || self.data.shareClass.indexOf( "Y" ) != -1 )
			$( "#characteristics-select option[value='volatility'], #characteristics-select option[value='upmarket-capture-ratio'], #characteristics-select option[value='downmarket-capture-ratio'], #characteristics-select option[value='information-ratio']" ).removeAttr( 'disabled' );
		else
			$( "#characteristics-select option[value='volatility'], #characteristics-select option[value='upmarket-capture-ratio'], #characteristics-select option[value='downmarket-capture-ratio'], #characteristics-select option[value='information-ratio']" ).attr( 'disabled', true );

		// Disable share class options depending on characteristic availability
		if( self.data.characteristic == 'volatility' || self.data.characteristic == 'upmarket-capture-ratio' || self.data.characteristic == 'downmarket-capture-ratio' || self.data.characteristic == 'information-ratio' )
		{
			$( "#share-class-select option" ).each( function(){ 
				if( $(this).attr( 'value' ).indexOf( "A" ) == -1 && $(this).attr( 'value' ).indexOf( "Y" ) == -1 )
					$(this).attr( 'disabled', true );
			} );
		}
		else
		{
			$( "#share-class-select option" ).each( function(){ 
				if( $(this).attr( 'value' ).indexOf( "A" ) == -1 && $(this).attr( 'value' ).indexOf( "Y" ) == -1 )
					$(this).removeAttr( 'disabled' );
			} );
		}

		if( environment == 'advisor' || environment == 'dcio' )
		{
			UpdateCompareButtons();
		}

		if( environment == 'advisor' )
		{
			UpdateTrackButtons();

			$( "#fund-screener-table" ).off( 'click', "input.track-fund", OnTrackButtonClicked );
			$( "#fund-screener-table" ).on( 'click', "input.track-fund", OnTrackButtonClicked );

			$( '#fund-tracker-list' ).off( 'click', 'a.remove-fund', OnRemoveFundButtonClicked );
			$( '#fund-tracker-list' ).on( 'click', 'a.remove-fund', OnRemoveFundButtonClicked );
		}

	}

	function OnTrackButtonClicked( e )
	{
		e.preventDefault();
		if( $(this).prop( "checked" ) )
		{
			CA.addFund( $(this), function(){
				UpdateTrackButtons();
			} );
		}
		else
		{
			CA.removeFund( $(this), function(){
				UpdateTrackButtons();
			} );
		}
	}

	function OnRemoveFundButtonClicked( e )
	{
		e.preventDefault();			
		setTimeout( function(){ 
			UpdateTrackButtons();
		}, 500 );
	}

	function UpdateCompareButtons()
	{
		$( "#fund-screener-table" ).on( 'click', ".fund-compare-link", function(){
			if($('#fundVisualizerChart #fvIframe').length){
				$('#fvIframe').remove();
			}

			var fvParams = "/#comparison/funds/chart/mountain/symbols/" + $(this).attr( 'data-fund' );
			var fvURL = $( '#fvHTML5URL' ).val();
			$( '<iframe/>', {
				name : 'fvIframe',
				id : 'fvIframe',
				src :fvURL + fvParams,
				frameborder : 0,
				scrolling : 'no',
				allow : 'microphone'
			} ).appendTo('#fundVisualizerChart');

			PG.hash="fundVisualizer";
			PG.ptnmTabAnchor();

		} );
	}

	function UpdateTrackButtons()
	{
		$.ajax({
			type : 'GET',
			url : '/advisor/getfundtrackerpref',
			dataType : 'json',
			cache : false,
			success : function( fundListObj ){
				$( '.track-fund' ).each( function(){
					$(this).prop( 'checked', false );
					if( fundListObj !== undefined && fundListObj[ $(this).attr('data-fund-id') ] !== undefined && fundListObj[ $(this).attr('data-fund-id') ].includes($(this).attr('data-share-class')) )
						$(this).prop( 'checked', true );
				} );
			},
			error : function(){
				$('#fund-tracker-list-container').empty().html('<p class="error">There was an error processing your request. Please try again later.</p>');
			}
		});
	}

	function GetMultipleShareClassString()
	{
		var shareClasses = self.data.shareClass.split( ',' );

		if( shareClasses.length == 1 ) return "";

		return "({0} share upon availability)".format( shareClasses[0] );

	}

	var requestArray = [];

	self.Update = function()
	{
		if( updateTimeout != undefined )
		{
			clearTimeout( updateTimeout );
		}

		updateTimeout = setTimeout( function(){
			SendRequest();
			updateTimeout = undefined;
		}, 1000 );
	}

	function SendRequest()
	{
		// console.log( self.data );

		var jsonData = JSON.stringify( self.data );

		var requestedData = JSON.parse( jsonData );

		RequestFunds( jsonData );
		cleanFilter = application.utilities.isEquivalent( requestedData, cleanDataSet );

		// Extra markup modifications
		if( requestedData.shareClass === "B" )
			$( '.b-shares' ).show();
		else
			$( '.b-shares' ).hide();
	}

	ApplicationObject.call( this );
}
FundScreener.prototype = Object.create( ApplicationObject.prototype );

function FundScreenerFilter( elementID, fundScreener )
{
	this.element = $( elementID );

	this.clear = function()
	{
		//console.log( "default clear" );
	}
}

// Asset Class Filter Section
function AssetClassFilters( elementID, fundScreener )
{
	FundScreenerFilter.call( elementID, fundScreener );

	var self = this;

	if( $( elementID ).length )
	{
		this.element = $( elementID );
		this.element.find( '.asset-class-filter' ).on( 'click', function(){
			self.select( $(this) );
			fundScreener.Update();
		} );
	}

	this.select = function( button )
	{
		if( self.selected == undefined )
		{
			self.selected = $(this);
			if( !button.siblings('.asset-class-filter').hasClass( 'disabled' ) )
				button.siblings('.asset-class-filter').addClass( 'disabled' );
		}
		else
		{
			button.toggleClass( 'disabled' );
		}

		var filterArray = [];
		self.element.find( '.asset-class-filter' ).each( function() {
			if( !$(this).hasClass('disabled') )
				filterArray.push( $(this).attr( 'data-asset-class' ) );
		} );

		if( filterArray.length === 0 )
			self.clear();
		else
		{
			fundScreener.data.assetClasses = filterArray;
		}
	}

	this.selectById = function( id )
	{
		this.element.find( '.asset-class-filter' ).each( function(){
			if( $(this).attr( 'data-asset-class' ) == id )
				self.select( $(this) );
		} );
	}

	this.getNiceText = function( id )
	{
		var niceText = id;
		this.element.find( '.asset-class-filter' ).each( function(){
			if( $(this).attr( 'data-asset-class' ) == id )
			{
				niceText = $(this).text();
			}
		} );

		return niceText;
	}

	this.clear = function()
	{
		if( this.element !== undefined )
		{
			this.selected = undefined;
			this.element.find( '.asset-class-filter' ).each( function(){
				$(this).removeClass( 'disabled' );
			} );
			fundScreener.data.assetClasses = [];
		}
	}
}
AssetClassFilters.prototype = Object.create( FundScreenerFilter.prototype );
AssetClassFilters.prototype.constructor = AssetClassFilters;

// Annualized Returns Filter Section
function AnnualizedReturnsFilter( elementID, fundScreener )
{
	FundScreenerFilter.call( elementID, fundScreener );

	var self = this;

	var min, max;

	if( $( elementID ).length )
	{
		self.element = $( elementID );
		self.element.find( '#annualized-returns-min' ).on( 'input', function(){
			min = $(this).val();
			updateVals();
		} );

		self.element.find( '#annualized-returns-max' ).on( 'input', function(){
			max = $(this).val();
			updateVals();
		} );
	}

	function updateVals()
	{
		if( !isNaN( parseFloat(min) ) )
			fundScreener.data.annualizedReturns.min = min.toString();
		else
			self.clearMin();

		if( !isNaN( parseFloat(max) ) )
			fundScreener.data.annualizedReturns.max = max.toString();
		else
			self.clearMax();
		
		if( min == "" )
			self.clearMin();

		if( max == "" )
			self.clearMax();

		if( parseFloat( min ) < parseFloat( max ) )
		{
			fundScreener.Update();
		}
	}

	self.clearMin = function( fullClear )
	{
		if( fullClear ) 
			self.element.find( '#annualized-returns-min' ).val( "" );

		min = -999;
		fundScreener.data.annualizedReturns.min = min.toString();
	}

	self.clearMax = function( fullClear )
	{
		if( fullClear )
			self.element.find( '#annualized-returns-max' ).val( "" );

		max = 999;
		fundScreener.data.annualizedReturns.max = max.toString();
		
	}

	self.clear = function()
	{
		if( self.element !== undefined )
		{
			self.clearMin( true );
			self.clearMax( true );
		}
	}
}
AnnualizedReturnsFilter.prototype = Object.create( FundScreenerFilter.prototype );
AnnualizedReturnsFilter.prototype.constructor = AssetClassFilters;

// Time period Filter
function TimePeriod( elementID, fundScreener )
{
	FundScreenerFilter.call( elementID, fundScreener );

	var self = this;

	if( $( elementID ).length )
	{
		this.element = $( elementID );
		fundScreener.data.timePeriod = this.element.val();
		this.element.on( 'change', function(){
			fundScreener.data.timePeriod = $(this).val();
			fundScreener.Update();
		} );
	}

	this.getNiceText = function( id )
	{
		var niceText = id;
		this.element.find( 'option' ).each( function(){
			if( $(this).val() == id )
			 niceText = $(this).text();
		} );

		return niceText;
	}

	this.clear = function()
	{
		if( this.element !== undefined )
		{
			this.element.find( 'option' ).eq(0).prop('selected', true);
			fundScreener.data.timePeriod = this.element.val();
		}
	}
}
TimePeriod.prototype = Object.create( FundScreenerFilter.prototype );
TimePeriod.prototype.constructor = AssetClassFilters;


// Morningstar filter
function MorningStar( elementID, fundScreener )
{
	FundScreenerFilter.call( elementID, fundScreener );

	var self = this;

	if( $( elementID .length) )
	{
		this.element = $( elementID );
		this.element.find( '.putnamicon-morningstar' ).on( 'click', function(){
			var msIndex = self.element.find( '.putnamicon-morningstar' ).index( $(this) );
			if( ( msIndex + 1 ) === fundScreener.data.morningStar )
			{
				resetMorningStars();
			}
			else
			{
				for( var i = 0; i < 5; i++ )
				{
					self.element.find( '.putnamicon-morningstar' ).eq(i).removeClass('active');
					if( i <= msIndex )
						self.element.find( '.putnamicon-morningstar' ).eq(i).addClass('active');
					fundScreener.data.morningStar = msIndex + 1;
				}
			}

			if( fundScreener.data.morningStar >= 5 )
				$( '.morningstar-ratings-filter-and-above' ).hide();
			else
				$( '.morningstar-ratings-filter-and-above' ).show();

			fundScreener.Update();
		} );
	}

	function resetMorningStars()
	{
		self.element.find( '.putnamicon-morningstar' ).each( function(){
			$(this).removeClass('active');
		} );
		fundScreener.data.morningStar = 0;
	}

	this.clear = function()
	{
		if( this.element !== undefined )
		{
			resetMorningStars();
		}
	}
}
MorningStar.prototype = Object.create( FundScreenerFilter.prototype );
MorningStar.prototype.constructor = AssetClassFilters;

// Lipper filter
function AboveLipper( elementID, fundScreener )
{
	FundScreenerFilter.call( elementID, fundScreener );

	if( $( elementID ).length )
	{
		this.element = $( elementID );
		this.element.on( 'change', function(){
			fundScreener.data.aboveLipper = $(this).prop( "checked" );
			fundScreener.Update();
		} );
	}

	this.clear = function()
	{
		if( this.element !== undefined )
		{
			this.element.prop( "checked", false );
			fundScreener.data.aboveLipper = false;
		}
	}
}
AboveLipper.prototype = Object.create( FundScreenerFilter.prototype );
AboveLipper.prototype.constructor = AssetClassFilters;


// Portfolio Manager filter
function PortfolioManager( elementID, fundScreener )
{
	FundScreenerFilter.call( elementID, fundScreener );

	if( $( elementID ).length )
	{
		this.element = $( elementID );
		this.element.on( 'change', function(){
			fundScreener.data.portfolioManager = $(this).val();
			fundScreener.Update();
		} );
	}

	this.getNiceText = function( id )
	{
		var niceText = id;
		this.element.find( 'option' ).each( function(){
			if( $(this).val() == id )
			 niceText = $(this).text();
		} );

		return niceText;
	}

	this.clear = function()
	{
		if( this.element !== undefined )
		{
			this.element.find( 'option' ).eq(0).prop('selected', true);
			fundScreener.data.portfolioManager = '';
		}
	}
}
PortfolioManager.prototype = Object.create( FundScreenerFilter.prototype );
PortfolioManager.prototype.constructor = AssetClassFilters;
/* jshint ignore:end */