/* jshint loopfunc:true */
function Disclosure()
{

	var self = this,
		w = $( window ).width();

	self.ready = function()
	{
		self.setupDisclosure();
	};

	self.resize = function( e )
	{
		w = $( window ).width();

		self.updateDisclosure();
	};

	self.setupDisclosure = function () {
		$('.disclosure-hide').append('<div class="hide-toggle"></div>');
		$('.content-hide').append('<div class="hide-toggle"></div>');
		$('.hide-toggle').on('click', function(event){
			$(this).parent().toggleClass('show');
			self.updateDisclosure();
		});
		self.updateDisclosure();
	};

	self.updateDisclosure = function () {
		var elem = $('.disclosure-hide, .content-hide').each(function(){
			var ele = $(this),
				isDisclosure = ele.hasClass('disclosure-hide'),
				fontSize = parseFloat(ele.css('font-size')),
				lineHeight = fontSize*1.666,
				smallHeight = isDisclosure ? 1.5 : 5.5, // Height in number of lines
				largeHeight = isDisclosure ? 1.5 : 7.5,
				tabHeight = 50, // Pixel height of tab (same as padding)
				lineOffset = parseFloat(ele.data('lineOffset')),
				disclosureContent = ele.find('.content'),
				targetMaxHeight;
			if (ele.data('fullyClose')) {
				smallHeight = largeHeight = 1.5;
			}
			if (lineOffset) {
				smallHeight += lineOffset;
				largeHeight += lineOffset;
			}
			if(ele.hasClass('show')){
				targetMaxHeight = disclosureContent.length ? disclosureContent.height()+parseFloat(ele.css('padding-top'))+parseFloat(ele.css('padding-bottom')) : ele[0].scrollHeight;
			}else if(w <= BREAKPOINT_SM){
				targetMaxHeight = ( (lineHeight * smallHeight) + tabHeight) + "px";
			}else{
				targetMaxHeight = ( (lineHeight * largeHeight) + tabHeight) + "px";
			}
			ele.css('max-height', targetMaxHeight);
		});
	};

	ApplicationObject.call( this );
}
Disclosure.prototype = Object.create( ApplicationObject.prototype );