/*
Unlike modal.js, this file is for dynamically generated modals, without the need for a <modal> tag in the DOM
*/

function CarouselModal()
{
	let self = this;

	self.carouselData = null;

	/*
		Ready
	*/
	self.ready = function()
	{
		// Collect the data from the DOM, if it exists
		let $carouselModal = $( 'CarouselModal' );

		if ( $carouselModal.length ) {  // Only do stuff if there is a CarouselModal on the page

			let carouselTitle				= $carouselModal.data( 'modal-title' ),
				carouselBodyClasses			= $carouselModal.data( 'modal-body-classes' ),
				carouselResponsiveSizing	= $carouselModal.data( 'modal-responsive-sizing' ),
				carouselShowOnLoad			= $carouselModal.data( 'modal-show-on-load' ),
				carouselPause				= $carouselModal.data( 'carousel-pause' ),
				carouselAnimation			= $carouselModal.data( 'carousel-animation' ),
				carouselInterval			= $carouselModal.data( 'carousel-interval' ),
				carouselWrap				= $carouselModal.data( 'carousel-wrap' ),
				carouselSkipCookie			= $carouselModal.data( 'skip-cookie' ),
				carouselSkipCookieDays		= $carouselModal.data( 'skip-cookie-days' ),
				carouselUseBlueConic		= $carouselModal.data( 'use-blueconic' ),
				$carouselSlides				= $carouselModal.find( 'CarouselModalSlide' ),
				carouselSlidesData			= [];

			// Loop through the slides and grab the data
			$carouselSlides.each( function( index, slide ) {
				let $slide = $( slide );

				carouselSlidesData.push( {
					slideContent:		$slide.html(),
					slideBreakpoint:	$slide.data( 'breakpoint' )
				} );
			} );

			self.setCarouselData( {
				title:				carouselTitle,
				bodyClasses:		carouselBodyClasses,
				responsiveSizing:	carouselResponsiveSizing,
				showOnLoad:			carouselShowOnLoad,
				pause:				carouselPause,
				animation:			carouselAnimation,
				interval:			carouselInterval,
				wrap:				carouselWrap,
				skipCookie:			carouselSkipCookie,
				skipCookieDays:		carouselSkipCookieDays,
				useBlueConic:		carouselUseBlueConic,
				slides:				carouselSlidesData
			} );

			if ( carouselShowOnLoad )  // If showOnLoad is true, show the modal
			{
				self.showCarouselModal();
			}

			// Delete the DOM after we've grabbed the data and stored it
			$carouselModal.remove();
		}
	};

	self.setCarouselData = function( data )
	{
		self.carouselData = data;
	}

	self.showCarouselModal = function()
	{
		// If self.carouselData has data
		if ( self.carouselData ) {

			// If carouselUseBlueConic is true, we need to grab the profile data from BlueConic
			if ( self.carouselData.useBlueConic ) {

				function doBlueConicWork() {
					// Get the BlueConic profile
					let bcProfile = blueConicClient.profile.getProfile();

					bcProfile.loadValues( self.carouselData.skipCookie, this, function()
					{
						let now			= new Date().getTime(),
							bcCookie	= bcProfile.getValues( self.carouselData.skipCookie );

						// If it's been more than self.carouselData.skipCookieDays since the cookie was set, show the modal
						if ( bcCookie === null || now - bcCookie > self.carouselData.skipCookieDays * 24 * 60 * 60 * 1000 ) {
							// Show the modal
							application.dynamicModal.show( {
								modalTitle:			self.carouselData.title,
								modalBody:			self.generateCarouselHTML( application.width ),
								modalClass:			'carousel-modal',
								modalBodyClasses:	self.carouselData.bodyClasses,
								modalFooter:		self.generateCarouselModalFooter( application.width )
							} );

							// Trigger a resize to set the carousel responsiveness
							$(window).trigger('resize');
					
							// Set up custom listeners with new DOM elements
							self.setListeners();
						}

					} );
				}

				// Is BlueConic loaded?
				if ( typeof window.blueConicClient !== 'undefined' && typeof window.blueConicClient.event !== 'undefined' && typeof window.blueConicClient.event.subscribe !== 'undefined' )
				{
					// BlueConic is already loaded
					doBlueConicWork();
				} else {
					// Not yet loaded; wait for the "onBlueConicLoaded" event
					window.addEventListener('onBlueConicLoaded', function () {
						// BlueConic is now loaded
						doBlueConicWork();
					}, false);
				}
				
			} else if ( !application.cookies.exists( self.carouselData.skipCookie ) ) {

				// Show the modal
				application.dynamicModal.show( {
					modalTitle:			self.carouselData.title,
					modalBody:			self.generateCarouselHTML( application.width ),
					modalClass:			'carousel-modal',
					modalBodyClasses:	self.carouselData.bodyClasses,
					modalFooter:		self.generateCarouselModalFooter( application.width )
				} );

				// Trigger a resize to set the carousel responsiveness
				$(window).trigger('resize');
		
				// Set up custom listeners with new DOM elements
				self.setListeners();
			}
		}
	}

	self.filterSlidesByBreakpoint = function( width, slides )
	{
		let filteredSlides		= [],
			currentBreakpoint	= null;

		// If self.carouselData.responsiveSizing is false, return slides
		if ( !self.carouselData.responsiveSizing ) {
			return slides;
		}

		// Set the current breakpoint based on width being either BREAKPOINT_XS, BREAKPOINT_SM, BREAKPOINT_MD or BREAKPOINT_LG
		if ( width <= BREAKPOINT_XS ) {
			currentBreakpoint = 'BREAKPOINT_XS';
		} else if ( width <= BREAKPOINT_SM ) {
			currentBreakpoint = 'BREAKPOINT_SM';
		} else if ( width <= BREAKPOINT_MD ) {
			currentBreakpoint = 'BREAKPOINT_MD';
		} else {
			currentBreakpoint = 'BREAKPOINT_LG';
		}

		// Loop through the slides and filter out any that don't match the current breakpoint
		for ( let i = 0; i < slides.length; i++ ) {
			let slide = slides[i];

			// If slide.slideBreakpoint contains currentBreakpoint, add it to filteredSlides
			if ( slide.slideBreakpoint.indexOf( currentBreakpoint ) > -1 ) {
				filteredSlides.push( slide );
			}
		}

		return filteredSlides;
	}

	self.generateCarouselHTML = function( width )
	{
		let carouselModalBodyHTML = '' +
			'<div id="carousel-modal-carousel" class="carousel ' + self.carouselData.animation + '" data-ride="carousel" data-touch="true" data-wrap="' + self.carouselData.wrap + '" data-interval="' + self.carouselData.interval + '">' +
			'	<div class="carousel-inner">',
			filteredSlides = self.filterSlidesByBreakpoint( width, self.carouselData.slides );

		// Loop through the slides and build the HTML
		for ( let i = 0; i < filteredSlides.length; i++ ) {
			let slide = filteredSlides[i];

			carouselModalBodyHTML += '<div class="carousel-item' + ( i === 0 ? ' active' : '' ) + '">' + slide.slideContent + '</div>';
		}

		carouselModalBodyHTML += '' +
			'	</div>' +
			'</div>';

		return carouselModalBodyHTML;
	}

	self.generateCarouselModalFooter = function( width )
	{
		let carouselModalFooterHTML = '',
			filteredSlides = self.filterSlidesByBreakpoint( width, self.carouselData.slides );

		carouselModalFooterHTML = '' +
			'<div class="col col-sm-4 col-auto px-0">' +
			'	<a href="#" class="btn btn-secondary" data-dismiss="modal" data-bs-dismiss="modal">Skip</a>' +
			'</div>' +
			'<div class="col col-auto mx-auto">' +
			'	<ol class="carousel-indicators carousel-indicators-simple-dots">';

		// Loop through the slides and build indicators
		for ( let i = 0; i < filteredSlides.length; i++ ) {
			carouselModalFooterHTML += '<li data-target="#carousel-modal-carousel" data-slide-to="' + i + '"' + ( i === 0 ? ' class="active"' : '' ) + '></li>';
		}
		
		carouselModalFooterHTML += '' +
			'	</ol>' +
			'</div>' +
			'<div class="col col-sm-4 col-auto px-0 text-right">' +
			'	<a href="#" class="btn btn-secondary carousel-modal-carousel-back hidden" data-target="#carousel-modal-carousel" data-slide="prev">Back</a>' +
			'	<a href="#" class="btn btn-primary carousel-modal-carousel-next" data-target="#carousel-modal-carousel" data-slide="next">Next</a>' +
			'</div>';

		return carouselModalFooterHTML;
	}

	self.setListeners = function()
	{
		// Find any active carousel modals
		let $activeModal		= $( '.modal.show' ),
			$activeModalDialog	= $activeModal.find( '.modal-dialog.carousel-modal' );

		$('#carousel-modal-carousel').on('slide.bs.carousel', function ( e ) {  // On slide, update the carousel indicators, back button, and next button
			let slideTo = $(e.relatedTarget).index();
			
			$activeModalDialog.find('.carousel-indicators li').removeClass('active').eq(slideTo).addClass('active');

			// If slideTo is not the first slide AND width is greater than BREAKPOINT_XS, show the back button
			if ( slideTo !== 0 && application.width >= BREAKPOINT_XS ) {
				$activeModalDialog.find('.carousel-modal-carousel-back').removeClass('hidden');
			} else {
				$activeModalDialog.find('.carousel-modal-carousel-back').addClass('hidden');
			}

			// If slideTo is the last slide, change the next button to say "Done", remove the data-target attribute, and add a data-dismiss attribute
			if ( slideTo === $activeModalDialog.find('.carousel-item').length - 1 ) {
				$activeModalDialog.find('.carousel-modal-carousel-next').html('Done').removeAttr('data-target').attr('data-dismiss', 'modal').attr('data-bs-dismiss', 'modal');
			} else {
				$activeModalDialog.find('.carousel-modal-carousel-next').html('Next').attr('data-target', '#carousel-modal-carousel').removeAttr('data-dismiss').removeAttr('data-bs-dismiss');
			}
			
		});

		// On modal hide, remember not to show again
		$activeModal.on('hide.bs.modal', function () {
			// If using BlueConic, we need to set the data in BlueConic profile properties instead
			if ( self.carouselData.useBlueConic ) {

				function doBlueConicWork() {

					// Get the BlueConic profile
					let bcProfile = blueConicClient.profile.getProfile();

					bcProfile.setValue( self.carouselData.skipCookie, new Date().getTime() );

					blueConicClient.profile.updateProfile( this, function() { /* Profile is now persistent */ });
				}

				// Is BlueConic loaded?
				if ( typeof window.blueConicClient !== 'undefined' && typeof window.blueConicClient.event !== 'undefined' && typeof window.blueConicClient.event.subscribe !== 'undefined' )
				{
					// BlueConic is already loaded
					doBlueConicWork();
				} else {
					// Not yet loaded; wait for the "onBlueConicLoaded" event
					window.addEventListener( 'onBlueConicLoaded', function () {
						// BlueConic is now loaded
						doBlueConicWork();
					}, false);
				}

			} else {  // If not using BlueConic, we need to set the cookie to true
				application.cookies.create( self.carouselData.skipCookie, 'true', self.carouselData.skipCookieDays );
			}

			// Delete body classes
			$activeModal.find('.modal-body').removeClass( self.carouselData.bodyClasses );

		});
	}

	// On resize
	self.resize = function( width )
	{
		// Find any active modals
		let $activeModal = $( '.modal.show' );

		if ( $activeModal.length ) {  // Only do stuff if there is an active modal

			let $activeModalDialog = $activeModal.find( '.modal-dialog' );

			if ( $activeModalDialog.hasClass( 'carousel-modal' ) && self.carouselData.responsiveSizing ) {  // Only do stuff if the active modal is a carousel modal with responsive sizing
				
				// Normal modal width is fine for XS and SM devices, set to lg for MD and up
				if ( width.message <= BREAKPOINT_MD ) {
					$activeModalDialog.removeClass( 'modal-lg' );
				} else {
					$activeModalDialog.addClass( 'modal-lg' );
				}

				// Generate new carousel html and replace the old carousel
				$activeModal.find( '#carousel-modal-carousel' ).replaceWith( self.generateCarouselHTML( width.message ) );

				// Generate new carousel modal footer html and replace the old carousel modal footer
				$activeModal.find( '.modal-footer' ).html( self.generateCarouselModalFooter( width.message ) );

				// Set up custom listeners with new DOM elements
				self.setListeners();
				
			}
			
		}
	}

	ApplicationObject.call( this );
}
CarouselModal.prototype = Object.create( ApplicationObject.prototype );
