  /**
 * ------------------------------------------------------------------------
 * Putnam Global Alert
 * 
 * Parameters:
 * - Alert Type (String) - Required - String of constant in GlobalAlert class (e.g. 'testAlert')
 * - Options (Object) - Optional
 * 
 * Options:
 * - dismissable (Boolean) - defaults to true
 * - storageType (String) - 'cookie' or 'blueconic', defaults to 'cookie'. NOTE: BlueConic isn't working because of it's asynchronous nature.  Need more time to devote to making this work, but I don't really think it's worth it.
 * - animationDuration (Int) - in milliseconds, defaults to 500
 * - showCount (Int) - number of times alert should show before being dismissed, defaults to 0 (shows indefinitely)
 * - cookieDuration (Int) - number of days before cookie expires, defaults to 365
 * - excludeSites (Array)
 * - includeSites (Array)
 * 
 * Example usage:
 * 		var globalAlert = new GlobalAlert('testAlert', { dismissable: false, excludeSites:['/institutional'] } );
 * 
 * In basic-layout.js:
 *		var excludeSites = ['/institutional', '/ucits', '/de/', '/uk/', '/au/', '.com.au'];
 *		self.globalAlert = new GlobalAlert('coronavirusAlert', { dismissable: false, animationDuration: 0, excludeSites:excludeSites } );
 * 
 * ------------------------------------------------------------------------
 */

function GlobalAlert( _alertType, _options )
{
	var self				= this,
		cookie				= 'cookie_global_alert_dismissed',
		showCountCookie		= 'cookie_global_alert_show_count',
		header				= $('header');

	self.currentAlertDOM;
	self.currentAlert		= _alertType;
	self.dismissable		= _options.dismissable != undefined ? _options.dismissable : true;
	self.storageType		= _options.storageType != undefined ? _options.storageType : 'cookie';
	self.animationDuration	= _options.animationDuration != undefined ? _options.animationDuration : 500;
	self.showCount			= _options.showCount != undefined ? _options.showCount : 0;
	self.cookieDuration		= _options.cookieDuration != undefined ? _options.cookieDuration : 365;
	self.format				= _options.format != undefined ? _options.format : 'banner';
	self.excludeSites		= _options.excludeSites || [];
	self.includeSites		= _options.includeSites || [];

	/**
	* ------------------------------------------------------------------------
	* Custom Alerts
	* - Functions that define each alert
	* 
	* To create a new alert:
	* 1) Add new custom function. Duplicate and modify header, body, and options. See getAlertHTML for available options.
	* 2) Add a new object to the ALERTS constant with a TYPE (String used to trigger alert) and reference to new custom function in FUNCTION.
	*
	* ------------------------------------------------------------------------
	*/

	// Returns custom alert DOM for test alert type
	var getTestAlert = function () {
		var header	= 'Important information regarding market volatility',
			body	= '<p class="mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec cursus justo leo, sit amet dictum nisl cursus ac. Vestibulum a pellentesque purus. Nunc sit amet elit magna. Aliquam id velit non tellus sollicitudin sodales. Proin venenatis purus id dolor placerat molestie. Duis consectetur nunc justo, sed ornare dui ultricies id. Morbi et blandit orci.</p>';

		return $( getAlertHTML( header, { body: body } ) );
	};

	// Returns coronavirus alert DOM for coronavirus alert type
	var getCoronavirusAlert = function () {
		var header			= 'Putnam is open and operating, and continues to provide seamless service. We have a comprehensive plan for business continuity during the coronavirus (COVID-19) crisis.',
			readMorePDF		= '/static/pdf/320994_PRM.pdf?v=4',
			alternateSites	= ['/institutional', '/ucits', '/de/', '/uk/', '/au/', '.com.au']; 

		if ( isSite(alternateSites) ) {
			readMorePDF		= '/static/pdf/321056_IN.pdf?v=1';
		}

		return $( getAlertHTML( header, { readMorePDF: readMorePDF, pdfText: 'March 27, 2020 update' } ) );
	};

	var getDiversityAlert = function () {
		var header = '<strong>Uniting for racial justice:</strong><br class="visible-xs"> A message from Putnam Investments. &nbsp;<a href="/inclusion" class="text-nowrap">Read now</a>';

		return $( getAlertHTML( header, { headerClass:'w-100 mb-0', alertClass: 'diversity' } ) );
	};

	var getProxyVoteAlert = function () {
		var header	= '',
			body	= `
				<div class="position-relative py-1">
					<a href="#" id="global-alert-close-icon" class="global-alert-close global-alert-close-icon" title="Dismiss message">
						&times;
						<span class="sr-only">Close</span>
					</a>
					<div class="d-flex align-items-center justify-content-between">
						<div class="pl-1 pr-1 pl-sm-2 pl-lg-3 pr-lg-0">
							<p class="mb-1">
								<strong class="text-800">Shareholders of Putnam Funds:</strong> Vote on important matters affecting your investment. <a href="/static/pdf/FT_Proxy_QA.pdf" target="_blank" class="text-400 nobr" id="proxy-vote-qna-pdf">Find details in this Q&A.</a> <span class="doctype">(pdf)</span>
							</p>
							<div class="d-md-flex align-items-center">
								<a href="https://www.proxyvote.com/" target="_blank" class="btn btn-primary mb-1 mb-md-0 mr-md-1 text-800" id="proxy-vote-link">Vote online</a>
								<p class="mb-0">Use the voting control number found on the proxy materials you received.</p>
							</div>
						</div>
						<div class="d-none d-md-block pr-md-1 pr-lg-2">
							<a href="https://www.proxyvote.com/" target="_blank" id="proxy-vote-link-image">
								<img src="/static/img/icon_vote.svg" alt="Vote online" width="100" height="100">
							</a>
						</div>
					</div>
				</div>
			`;

		return $( getAlertHTML(
			header,
			{
				alertClass:			'proxy-vote',
				body:				body,
				toggleDefaultOpen:	true,
				toggleBody:			false,
				hideCloseButton:	true
			}
		) );
	};

	// Alert constants
	var ALERTS = {
		TEST: {
			TYPE: 'testAlert',
			FUNCTION: getTestAlert()
		},
		CORONAVIRUS: {
			TYPE: 'coronavirusAlert',
			FUNCTION: getCoronavirusAlert()
		},
		DIVERSITY: {
			TYPE: 'diversityAlert',
			FUNCTION: getDiversityAlert()
		},
		PROXYVOTE: {
			TYPE: 'proxyVoteAlert',
			FUNCTION: getProxyVoteAlert()
		}
	};

	/**
	* ------------------------------------------------------------------------
	* Initialization
	* ------------------------------------------------------------------------
	*/

	self.ready = function()
	{
		if (self.dismissable == false || 
			(
				self.dismissable && self.getDismissed() !== self.currentAlert
			)
		){
			for ( var ALERT in ALERTS ) {
				if ( self.currentAlert == ALERTS[ALERT].TYPE ) {
					self.currentAlertDOM = ALERTS[ALERT].FUNCTION;
				}
			}
		}

		var showOnSite = true;
		if ( self.includeSites.length ) {
			showOnSite = isSite( self.includeSites );
		}
		if ( self.excludeSites.length ) {
			showOnSite = !isSite( self.excludeSites );
		}

		if ( self.currentAlertDOM != undefined && showOnSite ) {
			var showCount = self.getShowCount();

			if (
				showCount == null ||  // Show if showCount cookie doesn't exist
				(
					showCount < self.showCount ||  // Show until showCount is reached
					self.showCount == 0  // Show indefinitely
				)
			){
				self.initGlobalAlert( self.currentAlert );
			}
		}
	};

	// Initializes all global alerts based on alert type parameter. Binds respective
	self.initGlobalAlert = function( alertType )
	{
		var body = self.currentAlertDOM.find('.global-alert-body');

		if ( self.dismissable ) {
			self.currentAlertDOM.find('.global-alert-close').on( 'click', function (e) {
				var $this = $(this);

				if ( $this.attr('href') == '#' ) {  // Prevents page from jumping to top when clicking on close button, but allows links with hrefs to work
					e.preventDefault();
				}

				self.currentAlertDOM.slideUp( self.animationDuration, function () {
					$('.global-alert').remove();
				});
				self.setDismissed( cookie, alertType );
			});
		}

		self.currentAlertDOM.find('.global-alert-show-hide').on( 'click', function (e) {
			if ( e.target.dataset.toggle == 'closed' ) {
				body.slideDown( self.animationDuration );
				e.target.dataset.toggle = 'open';
			} else if ( e.target.dataset.toggle == 'open' ) {
				body.slideUp( self.animationDuration );
				e.target.dataset.toggle = 'closed';
			}
		});

		self.currentAlertDOM.insertBefore( header );

		self.currentAlertDOM.slideDown(self.animationDuration);

		// Set global alert view count cookie
		if ( self.showCount > 0 ) {  // If showCount is set to 0, alert will show indefinitely, don't track views
			var showCount = self.getShowCount();
			if ( showCount == null ) {
				showCount = 1;
			} else {
				showCount++;
			}
			self.setShowCount( showCount, alertType );
		}

		// window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } );
	}

	/**
	* ------------------------------------------------------------------------
	* getAlertHTML
	* - Returns wrapper html for custom alert types
	*
	* Parameters:
	* - header (String) - Required - Header text of alert
	* - options (Object) - Optional
	*
	* Options:
	* - body (String) - Body text of alert
	* - toggleBody (Boolean) - Enables button to show/hide body text when body text is added - defaults to true
	* - toggleDefaultOpen (Boolean) - Show body text on load when set to true - defaults to false (closed)
	* - hideCloseButton (Boolean) - Hide close button when set to true - defaults to false (visible)
	* - headerClass (String) - Add additional classes to header - defaults to h4
	* - readMorePDF (String) - Link to PDF (preferably relative path) IMPORTANT: this requires manual cache busting
	* - pdfText (String) - Copy for link to PDF (readMorePDF option) - defaults to 'Read more'
	*
	* ------------------------------------------------------------------------
	*/
	function getAlertHTML( header, options )
	{
		options.body				= options.body || undefined;
		options.toggleBody			= options.toggleBody != undefined || options.body == undefined ? options.toggleBody : true;
		options.toggleDefaultOpen	= options.toggleDefaultOpen != undefined ? options.toggleDefaultOpen : false;
		options.hideCloseButton		= options.hideCloseButton != undefined ? options.hideCloseButton : false;
		options.headerClass			= options.headerClass != undefined ? options.headerClass : 'h4';
		options.pdfText				= options.pdfText != undefined ? options.pdfText : 'Read more';
		options.alertClass			= options.alertClass != undefined ? options.alertClass : '';
		// options.readMorePDF

		var display		= 'display:none;',
			dataToggle	= 'closed',
			html		= '';

		if ( options.toggleDefaultOpen ) {
			display		= '';
			dataToggle	= 'open';
		}

		html = '<div class="global-alert d-print-none ' + options.alertClass + ' global-alert-' + self.format + '">' +
						'<div class="container py-1">' +
							'<div class="global-alert-header d-flex flex-column flex-md-row justify-content-between align-items-center">';
		if ( header != '' ) {
			html += 				'<p class="mt-0 my-sm-0 ' + options.headerClass + '">' + 
									header;
			if ( options.readMorePDF ) {
				html += 			' <span class="text-nowrap"><a href="' + options.readMorePDF + '" target="_blank">' + options.pdfText + '</a> <span class="doctype">(PDF)</span></span>';
			}
			html +=				'</p>';
		}
		html +=					'<div class="d-flex flex-row justify-content-between">';
		if ( options.toggleBody ) {
			html +=					'<button class="global-alert-show-hide btn btn-secondary mr-1 mt-1 mt-md-0" data-toggle="' + dataToggle + '">Read more</button>';
		}
		if ( self.dismissable && !options.hideCloseButton ) {
			html +=					'<button class="global-alert-close btn btn-secondary mt-1 mt-md-0">Close</button>';
		}
		html +=					'</div>' +
							'</div>';
		if ( options.body ) {
			html += 		'<div class="global-alert-body" style="' + display + '">' + options.body + '</div>';
		}
		html +=			'</div>' +
					'</div>';


		return html;
	}


	/**
	* ------------------------------------------------------------------------
	* Utility Functions
	* ------------------------------------------------------------------------
	*/

	function isSite( siteArr ) {
		for (var i = 0; i < siteArr.length; i++) {
			if ( window.location.pathname.indexOf(siteArr[i]) > -1 || window.location.origin.indexOf(siteArr[i]) > -1 ) {
				return true;
			}
		}
		return false;
	}

	// Return the dismissable state
	self.getDismissed = function()
	{
		var dismissed = null;

		if ( self.storageType == 'cookie' ) {
			dismissed = application.cookies.read( cookie );
		} else if ( self.storageType == 'blueconic' ) {
			/* BlueConic isn't working because of it's asynchronous nature.  Need more time to devote to making this work, but I don't really think it's worth it. */
			/* application.utilities.BlueConic.getProfileProperty('cookie_global_alert_dismissed').then( function( value ) {
				dismissed = application.utilities.BlueConic.getProfileProperty( cookie );
			}); */
		}

		return dismissed;
	}

	// Set the dismissable state
	self.setDismissed = function( _cookie, _value )
	{
		if ( self.storageType == 'cookie' ) {
			application.cookies.create( _cookie, _value, self.cookieDuration );
		} else if ( self.storageType == 'blueconic' ) {
			/* BlueConic isn't working because of it's asynchronous nature.  Need more time to devote to making this work, but I don't really think it's worth it. */
			/* var now = new Date().getTime(); // Get current time
			application.utilities.BlueConic.setProfileProperty( _cookie, _value + "|" + now ); */
		}
	}

	// Returns number of times alert has been shown
	self.getShowCount = function()
	{
		if ( self.storageType == 'cookie' ) {
			var showCount = application.cookies.read( showCountCookie );
		} else if ( self.storageType == 'blueconic' ) {
			/* BlueConic isn't working because of it's asynchronous nature.  Need more time to devote to making this work, but I don't really think it's worth it. */
			/* var showCount = application.utilities.BlueConic.getProfileProperty( showCountCookie ); */
		}

		return showCount;
	}

	// Set number of times alert has been shown
	self.setShowCount = function( _showCount, _alertType )
	{
		if ( self.storageType == 'cookie' ) {
			application.cookies.create( showCountCookie, _showCount, self.cookieDuration );
		} else if ( self.storageType == 'blueconic' ) {
			/* BlueConic isn't working because of it's asynchronous nature.  Need more time to devote to making this work, but I don't really think it's worth it. */
			/* application.utilities.BlueConic.setProfileProperty( showCountCookie, _alertType + "|" + _showCount ); */
		}
	}

	ApplicationObject.call( this );
}
GlobalAlert.prototype = Object.create( ApplicationObject.prototype );
