function BrightcoveVideos()
{

	var self = this;

	self.setupVideos = function( customButton  )
	{
		var $brightcoveVideos = $('video.brightcove-video, a.brightcove-image-link'),
			videoButton = '' +
				'	<button class="vjs-big-play-button" type="button" aria-live="polite" title="Play Video">' +
				'		<span class="vjs-control-text">Play Video</span>' +
				'	</button>';

		if ( 'ontouchstart' in window || navigator.maxTouchPoints ) {
			videoButton = '' +
				'	<button class="vjs-mobile-load-button" type="button" aria-live="polite" title="Load Video">' +
				'		<span class="vjs-load-text">Load video &nbsp; <span class="putnamicon putnamicon-play"></span></span>' +
				'	</button>';
		} else if ( typeof customButton !== "undefined" ) {
			videoButton = customButton;
		}

		/* Handle brightcove sidebar playlist player videos */
		var $brightcoveSidecarImageLinks	= $('a.bc-sidecar-image-link'),
			brightcoveSidecarDeeplink		= application.utilities.getParameterByName('video-id'),
			$brightcoveSidecarPlayerSharing = $('.bc-playlist-share-area');

		// Set up sharing icon links if sharing is enabled
		if ( $brightcoveSidecarPlayerSharing.length ) {
			var $brightcoveSidecarPlayerSharingLinks = $brightcoveSidecarPlayerSharing.find('a');

			$brightcoveSidecarPlayerSharingLinks.each( function() {
				var $this = $(this),
					windowLocation = window.location.href;

				if ( !brightcoveSidecarDeeplink ) {
					var firstVideoId = $brightcoveSidecarImageLinks.first().data('video-id');

					if ( !firstVideoId ) {
						console.log('no video area?');
						firstVideoId = $('.bc-macro-area video').data('video-id');
					}
					
					windowLocation = windowLocation + '?video-id=' + firstVideoId;
				}

				if ( typeof $this.data('copy') !== 'undefined' ) {
					$this.data('copy', windowLocation);
				} else {
					$this.attr('href', windowLocation);
				}

			});
		}

		if ( $brightcoveSidecarImageLinks.length ) {
			
			$brightcoveSidecarImageLinks.on( 'click', function( event ) {
				event.preventDefault();

				var $this			= $(this),
					videoId			= $this.data('video-id'),
					playerId		= 'fSGPreHed',
					playerTarget	= $this.data('player-target');

				if ( $this.data('player-id') ) {
					playerId = $this.data('player-id');
				}

				if ( typeof videoId !== "undefined" && typeof playerTarget !== "undefined" ) {
					$this.addClass('bc-sidecar-image-link-active').parent().siblings().children('a').removeClass('bc-sidecar-image-link-active');

					var playerHTML = '' +
						'<div class="brightcove-video-wrapper">' +
						'	<video id="video' + videoId + '" autoplay data-video-id="' + videoId + '" data-account="31193518001" data-player="' + playerId + '" data-embed="default" data-application-id class="video-js" controls></video>' +
						'</div>';

					$( playerTarget ).html( playerHTML );

					$.getScript( "//players.brightcove.net/31193518001/" + playerId + "_default/index.min.js" ).done( function( script, textStatus ) {
						if ( typeof gtm !== 'undefined' ) {
							if ( typeof( gtm.trackVideoWithID ) === 'function' ){
								gtm.trackVideoWithID( "video" + videoId + "_html5_api" );
							}
						}
					});

					window.history.replaceState('', '', window.location.href.split('?')[0] + '?video-id=' + videoId);

					if ( $brightcoveSidecarPlayerSharing.length ) {
						var $brightcoveSidecarPlayerSharingLinks = $brightcoveSidecarPlayerSharing.find('a');
		
						$brightcoveSidecarPlayerSharingLinks.each( function() {
							var $this = $(this);
		
							if ( typeof $this.data('copy') !== 'undefined' ) {
								$this.data('copy', window.location.href);
							} else {
								$this.attr('href', window.location.href);
							}
		
						});
					}
				}
				
			} );

			if ( brightcoveSidecarDeeplink ) {

				var $targetedSidecarImageLink = $brightcoveSidecarImageLinks.filter('[data-video-id="' + brightcoveSidecarDeeplink + '"]');

				if ( $targetedSidecarImageLink ) {
					$targetedSidecarImageLink.addClass('bc-sidecar-image-link-active');
				}
			}
			
		}

		$brightcoveVideos.each( function () {
			var $this					= $(this),
				videoID					= $this.data('video-id'),
				playerId				= 'fSGPreHed',
				cuepointURL				= $this.data('cuepoint-url'),
				cuepointTarget			= $this.data('cuepoint-target'),
				loop					= $this.data('loop'),
				loopAndPause			= $this.data('loop-and-pause'),
				playInModal				= $this.data('play-in-modal'),
				inheritClasses			= $this.attr('class'),
				inheritStyles			= $this.attr('style'),
				playInPlaylistPlayer	= $this.data('play-in-playlist-player'),
				loadVideo = $this.attr('data-video-load');

			if ( $this.data('player-id') ) {
				playerId = $this.data('player-id');
			}

			$.ajax({
				url: "https://edge.api.brightcove.com/playback/v1/accounts/31193518001/videos/" + videoID,
				headers: {
					Accept : "application/json; pk=BCpkADawqM3Vh4DXxcOnkQjQEsDRtdPYYcxMnm5xCKe7kImZn-O-o5UzisjmhDx5wq4Z8xyQwzxEM_1eS6PRGYGP0D5xl-ve6lssOwT04_9AdBeX2JDH4dqmLv0",
				},
				success: function( response ) {
					var posterURL			= response.poster,
						videoName			= response.name,
						cuepointURLHTML		= '',
						cuepointTargetHTML	= '',
						loopHTML			= '',
						loopAndPauseHTML	= '';

					if ( typeof cuepointURL !== 'undefined' ) {
						cuepointURLHTML = ' data-cuepoint-url="' + cuepointURL + '"';
					}
					if ( typeof cuepointTarget !== 'undefined' ) {
						cuepointTargetHTML = ' data-cuepoint-target="' + cuepointTarget + '"';
					}
					if ( typeof loop !== 'undefined' ) {
						loopHTML = ' data-loop="' + loop + '"';
					}
					if ( typeof loopAndPause !== 'undefined' ) {
						loopAndPauseHTML = ' data-loop-and-pause="' + loopAndPause + '"';
					}

					var playerLinkHTML;

					if ( loadVideo === false || loadVideo === "false" ) {
						playerLinkHTML = ''+
							'<button class="vjs-big-play-button" type="button" aria-live="polite" title="Load Video">' +
							'		<span class="vjs-control-text">Play Video</span>' +
							'</button>' +
							'<img src="' + posterURL + '" alt="">';
						$this.append( playerLinkHTML );
					} else if ( playInModal ) {
						playerLinkHTML = '' +
							'<a href="#" id="modal-video-' + videoID + '" class="brightcove-video-link brightcove-video-modal-link '+ inheritClasses.replace("brightcove-video", "") +'" style="'+ inheritStyles +'">' +
							'	<button class="vjs-big-play-button" type="button" aria-live="polite" title="Play Video">' +
							'		<span class="vjs-control-text">Play Video</span>' +
							'	</button>' +
							'	<img src="' + posterURL + '" alt="">' +
							'</a>' +
							'<modal class="modal-lg" data-target="modal-video-' + videoID + '" videoid="' + videoID + '"></modal>';
							/* '<modal class="modal-lg" data-target="modal-video-' + videoID + '" title="' + videoName + '" videoid="' + videoID + '"></modal>'; */ /* This includes the video title */

						$this.before( playerLinkHTML ).remove();

						application.modal.ready();
					} else {
						playerLinkHTML = '' +
							'<a href="#" class="brightcove-video-link brightcove-video-inline-link '+ inheritClasses.replace("brightcove-video", "") +'" data-player-id="' + playerId + '" data-video-id="' + videoID + '"' + cuepointURLHTML + cuepointTargetHTML + ' style="'+ inheritStyles +'">' +
							videoButton +
							'	<img src="' + posterURL + '" alt="">' +
							'</a>';

						$this.before( playerLinkHTML ).remove();
					}
				}
			});
		});
	};

	self.ready = function()
	{
		self.setupVideos();
		self.setupSidecarPlaylistHeights();

		// Setup the height manually in case other events don't fire
		setTimeout(function(){
			$(window).trigger('resize');
		}, 300);

		// Autoplay video on playlist selection (supports multiple videos per page)
		var video = {};

		$('.vjs-playlist').on( 'click', '.vjs-playlist-item', function(event) {
			event.preventDefault();
			var videoId = $(this).parents('.playlist-no-thumbs').find('video')[0].id;
			if (!video[videoId]) {
				video[videoId] = videojs(videoId);
			}
			video[videoId].play();
		});

		$( document ).on( "click", "a.brightcove-video-modal-link", function( event ) {
			event.preventDefault();
		});

		$( document ).on( "click", "a.brightcove-video-inline-link", function( event ) {
			event.preventDefault();

			var $this				= $(this),
				videoID				= $this.data('video-id'),
				playerId			= 'fSGPreHed',
				cuepointURL			= $this.data('cuepoint-url'),
				cuepointTarget		= $this.data('cuepoint-target'),
				loop				= $this.data('loop'),
				loopAndPause		= $this.data('loop-and-pause'),
				cuepointTargetHTML	= '',
				cuepointAnchorHTML	= '',
				inheritClasses		= $this.attr('class'),
				inheritStyles		= $this.attr('style'),
				$parentDiv			= $this.parent();

			if ( $this.data('player-id') ) {
				playerId = $this.data('player-id');
			}

			if ( typeof cuepointTarget !== 'undefined' ) {
				cuepointTargetHTML = ' target="' + cuepointTarget + '"';
			}

			if ( typeof cuepointURL !== 'undefined' ) {
				cuepointAnchorHTML = '<a href="' + cuepointURL + '" id="cta-' + videoID + '" class="bc-annotation hidden-xs-up"' + cuepointTargetHTML + '></a>';
			}

			var playerHTML = '' +
				'<div class="brightcove-video-wrapper '+ inheritClasses.replace("brightcove-video", "") +'" style="'+ inheritStyles +'">' +
				'	<video id="video' + videoID + '" autoplay data-video-id="' + videoID + '" data-account="31193518001" data-player="' + playerId + '" data-embed="default" data-application-id class="video-js" controls></video>' +
				cuepointAnchorHTML +
				'</div>';

			$this.before( playerHTML ).remove();

			$.getScript( "//players.brightcove.net/31193518001/" + playerId + "_default/index.min.js" ).done( function( script, textStatus ) {
				if ( typeof gtm !== 'undefined' ) {
					if ( typeof( gtm.trackVideoWithID ) === 'function' ){
						gtm.trackVideoWithID( "video" + videoID + "_html5_api" );
					}
				}

				videojs( "video" + videoID + "_html5_api" ).ready( function() {
					// After Brightcove JS is loaded, we can fire analytics or cuepoint code
					var player = this;

					//window.dispatchEvent( new Event('video.ready') );
					$(window).trigger('video.ready');

					if ( typeof loop !== 'undefined' || typeof loopAndPause !== 'undefined' ) {
						player.on("ended", function () {
							player.currentTime( 0 );

							if ( typeof loopAndPause !== 'undefined' ) {
								setTimeout( function(){ player.pause(); }, 10 );
							}
						});
					}

					if ( typeof cuepointURL !== 'undefined' ) {
						// If data-cuepoint-url was supplied, show the hidden anchor tag on top of the player when cuepoints fire.
						player.one( "loadedmetadata", function() {
							var tt = player.textTracks()[0];
							tt.oncuechange = function() {
								if ( tt.activeCues[0] !== undefined ){
									$( '#cta-' + videoID ).removeClass('hidden-xs-up');
								}
							};
						});
					}
				});
			});
		});
	};

	self.isTouchDevice = function()
	{
		return (
			('ontouchstart' in window) ||
			(navigator.maxTouchPoints > 0) ||
			(navigator.msMaxTouchPoints > 0)
		);
	};

	self.setupSidecarPlaylistHeights = function()
	{
		var $bcPlaylistSidecars = $('.bc-playlist-sidecar');

		if ( $bcPlaylistSidecars.length ) {
			$bcPlaylistSidecars.each( function () {
				var w 							= $( window ).width(),
					$this						= $(this),
					$bcPlaylistSidecarArea		= $this.find('.bc-playlist-sidecar-area'),
					$bcPlaylistSidecarMain		= $this.find('.bc-playlist-sidecar-main'),
					$bcVideoWrapper				= $this.find('.brightcove-video-wrapper'),
					$bcPlaylistSidecarMainImage	= $bcPlaylistSidecarMain.find('img'),
					tallestHeight				= $bcPlaylistSidecarMainImage.height(),
					widthOffset					= 0;

				if ( $bcVideoWrapper.outerHeight() !== undefined ) {
					tallestHeight = $bcVideoWrapper.outerHeight()
				} else {
					tallestHeight = $bcPlaylistSidecarMainImage.height();
				}

				if ( !self.isTouchDevice() ) {
					widthOffset = 14;
				}

				if ( w + widthOffset >= BREAKPOINT_SM  ) {
					$bcPlaylistSidecarArea.height( tallestHeight );
				} else {
					$bcPlaylistSidecarArea.height( 'auto' );
				}
			});
		}
	};

	self.loaded = function()
	{
		self.setupSidecarPlaylistHeights();
	};

	self.resize = function( e )
	{
		self.setupSidecarPlaylistHeights();
	};

	ApplicationObject.call( this );
}
BrightcoveVideos.prototype = Object.create( ApplicationObject.prototype );
