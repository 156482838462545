function FVTable()
{
	var	_urlOrigin 	= '',
		_urlBase 	= '/advisor/fv-charts/',
		_url 		= null;

	var self 	= this;
	
	self.tables = [];
	self.status = FVTable.READY;

	self.ready = function()
	{
		// Search for fv-tables
		self.tables = $('.fv-table');
		// Grab setup data from table
		self.tables.each(function(index, table) {
			var $table 		= $(table),
				compareArr 	= $table.data('compare') ? $table.data('compare').split(',') : [],
				type 		= $table.data('type') ? $table.data('type') : dataError('Specify data type. e.g. data-type="table"');
				origin 		= $table.data('origin') ? $table.data('origin') : '';
			// console.log('FVTable :: ', $table);
			$.ajax({
				url: getURL(origin, type) + compareArr[0],
				method: 'get'
			}).done(function(data){
				self.dataLoaded(data, index, $table, compareArr);
			}).fail(function(e) {
				dataError(e);
			});
		});
	}

	self.dataLoaded = function(data, tableIndex, $table, compareArr)
	{
		var	hasBody	 	= $table.find('tbody').length ? true : false,
			autoChart 	= $table.data('autoChart') ? $( $table.data('autoChart') )[0] : false,
			asOfTarget	= $table.data('asOf'),
			asOfDate	= [];

		// AJAX request success
		// console.log('FVTable :: Success data: ', data);
		// console.log('FVTable :: Comparison Array: ', compareArr, compareArr.length);

		// Display in comparison array order
		if (data) {
			// Loop through compare
			for (var i = 0; i < compareArr.length; i++) {
				// Get data to match compare id
				var compareData;
				for (var j = 0; j < data.length; j++) {
					if (compareArr[i] == ( data[j].tradingSymbol || data[j].investmentVehicleId ) ) {
						compareData = data[j];
					}
				}
				if (compareData) {
					// Check for body
					if (hasBody) {
						// Each fund
						var row = $table.find('tbody tr')[i];
						// Each field to compare in fund
						/* jshint ignore:start */
						$(row).find('td').each(function(indexTD, td) {
							var $td = $(td),
								compareVal = $td.attr('class');
							// Grab field matching class name
							$td.html(compareData[compareVal]);
						});
						/* jshint ignore:end */
						if (compareData.performanceAsOfDate) {
							asOfDate.push(compareData.performanceAsOfDate);
						}
					} else {
						// Else... build based on other data tag possibly.
						// Could specify fields to show for each row
						dataError('Format table properly');
					}
				} else {
					dataError('No data match.');
				}
			}
		} else if (!compareArr.length){
			dataError('No data. Check data-compare on table. Need InvestmentVehicleIDs for comparison.');
		} else {
			dataError('No data. Check InvestmentVehicleID and config file.');
		}
		self.status = (self.status == FVTable.ERROR) ? FVTable.ERROR : FVTable.SUCCESS;

		// If successful
		if (self.status == FVTable.SUCCESS) {
			if (asOfDate.length) {
				for (var k = 0; k < asOfDate.length; k++) {
					for (var l = k + 1; l < asOfDate.length; l++) {
						if (asOfDate[k] != asOfDate[l]) {
							dataError('As-of date mismatch: ' + asOfDate[k] + " " + asOfDate[l]);
						}
					}
				}
				if (self.status != FVTable.ERROR) {
					var date = new Date(asOfDate[0]);
					$(asOfTarget).html("as of " + date.toLocaleDateString('en-US'));
				}
			} else {
				dataError('No as-of date found.');
			}
			// Load auto chart if required
			if (autoChart) {
				application.autoChartController.createChart(autoChart);
			}
		}
	}

	function getURL(urlOrigin, type) 
	{
		_urlOrigin = urlOrigin;
		switch(type) {
			case 'table':
				_url = 'table/';break;
			case 'assetAllocation':
				_url = 'asset-allocation/';break;
			case 'sectors':
				_url = 'sectors/';break;
			case 'holdings':
				_url = 'holdings/';break;
			case 'performance':
				_url = 'performance/';break;
			default:
				 dataError('Unavailable data type. Choose: table, asset-allocation, sectors, holdings, or performance');
		}
		return urlOrigin + _urlBase + _url;
	}

	function dataError(e)
	{
		self.status = FVTable.ERROR;
		console.log('FVTable :: Error loading: ', e);
	}

	ApplicationObject.call( this );
}
FVTable.prototype = Object.create( ApplicationObject.prototype );

FVTable.READY 	= 'ready';
FVTable.SUCCESS	= 'success';
FVTable.ERROR 	= 'error';