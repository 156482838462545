function Forms()
{

	let self = this,
		w = $( window ).width();

	self.ready = function()
	{
		// Input empty detection
		let formControls = $('input.form-control');

		formControls.each(function(){
			let $this = $(this);
			if ($this.val() === '')
				$this.addClass('empty');
		});

		formControls.on('change keyup paste', function(){
			let _$this = $(this);
			if( _$this.val() === ''){
				_$this.addClass('empty');
			}else{
				_$this.removeClass('empty');
			}
		});

		// Validate forms that need it
		$(document).on( 'submit', 'form.needs-validation', function( e ){
			e.preventDefault();
			e.stopPropagation();

			let $thisForm		= $(this),
				$phoneInputs	= $thisForm.find('input[type="tel"]');
			
			if (
				this.checkValidity() === false ||
				(
					$phoneInputs.length > 0 &&
					!self.validatePhoneNumber( $phoneInputs, $thisForm )
				)
			) {
				$thisForm.trigger( 'formInvalid' );
			} else {
				$thisForm.trigger( 'formValid' );
			}
			$thisForm.addClass('was-validated');
		});

		// Show/hide password button
		$(document).on( 'click', '.show-hide-password-button', function(){
			let $this = $(this),
				$input = $this.parent().find('input');

			if ($input.attr('type') === 'password'){
				$input.attr('type', 'text');
				$input.trigger('focus');
				$this.attr('title', 'Hide password').find('.putnamicon').addClass('putnamicon-Eye').removeClass('putnamicon-Eye-slash');
			}else{
				$input.attr('type', 'password');
				$input.trigger('focus');
				$this.attr('title', 'Show password').find('.putnamicon').addClass('putnamicon-Eye-slash').removeClass('putnamicon-Eye');
			}
		});
	};

	/* There are more conditions than a Regex can cover, so go through those and report back validity */
	self.validatePhoneNumber = function( $phoneInputs, $form )
	{
		let phoneNumbersValid = true;

		// For each $phoneInputs
		$phoneInputs.each( function() {
			let $this					= $( this ),
				phoneNumber				= $this.val(),
				phoneNumberMinusPrefix	= phoneNumber.replace( /^\+?1?/, '' );

			// If phoneNumberMinusPrefix starts with 800, 888, 877, 866, 855, 844, or 833, it's a toll-free number and is invalid
			if ( phoneNumberMinusPrefix.match( /^(800|888|877|866|855|844|833)/ ) ) {
				console.log( 'toll-free' );

				self.setPhoneInputInvalid( $this, $form, 'Toll-free numbers are not allowed.' );
				
				phoneNumbersValid = false;
			}

		});

		// If the phone number starts with "1", the following number of digits must equal 10
		if ( phoneNumbersValid ) {  // Only do this if the phone numbers are still valid, otherwise it's wasted processing
			$phoneInputs.each( function() {
				let $this					= $( this ),
					phoneNumber				= $this.val(),
					phoneNumberMinusPrefix	= phoneNumber.replace( /^\+?1?/, '' );

				// strip out all non-numeric characters from phoneNumberMinusPrefix
				phoneNumberMinusPrefix	= phoneNumberMinusPrefix.replace( /\D/g, '' );

				if ( phoneNumberMinusPrefix.length !== 10 ) {
					console.log( 'not 10 digits' );

					self.setPhoneInputInvalid( $this, $form, 'Please enter a valid phone number.' );

					phoneNumbersValid = false;
				}
			});
		}

		return phoneNumbersValid;
	}

	self.setPhoneInputInvalid = function( $phoneInput, $form, error ) {
		// Set the input to invalid using setCustomValidity
		$phoneInput[0].setCustomValidity( error );

		// When the user changes the input, remove the invalid state
		$phoneInput.on( 'input', function() {
			$phoneInput[0].setCustomValidity( '' );
		});
		
		$form.addClass('was-validated');
	}

	ApplicationObject.call( this );
}
Forms.prototype = Object.create( ApplicationObject.prototype );
