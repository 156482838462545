function AutoChartUI( chart, config )
{
	var self = this;
	self.chart = chart;
	self.config = config;
	self.chartElement = config.data.element;
}
AutoChartUI.prototype.constructor = AutoChartUI;

AutoChartUI.prototype.renderUI = function()
{
	var self = this;

	types = self.chartElement.data('ui').split(',');

	for( var i = 0; i < types.length; i++ )
	{
		switch( types[i] )
		{
			case 'timespan':
				self.CreateTimespanUI();
				self.CreateDateRangeUI(true);
				break;

			case 'datetime':
				self.CreateTimespanUI();
				self.CreateDateRangeUI();
				break;

			case 'daterange':
				self.CreateDateRangeUI();
				break;
		}
	}
}

AutoChartUI.prototype.dateUpdated = function()
{
	var self = this;
	
	var container = self.GetOrCreateUIContainer();

	clearTimeout( self.dateChangeTimeout );
	self.dateChangeTimeout = setTimeout( function(){
		var startTime = container.find( '#start-date-cont' ).datepicker('getDate').getTime();
		var endTime = container.find( '#end-date-cont' ).datepicker('getDate').getTime();

		if( startTime != undefined && endTime != undefined && startTime <= endTime )
		{
			for( var i = 0; i < self.config.data.rawSeries.length; i++ )
			{
				var data = JSON.parse( JSON.stringify( self.config.data.rawSeries[i].data ) );
				self.config.data.series[i].data = data.filter( function( obj ){
					return obj.x >= startTime && obj.x <= endTime;
				} );
			}

			self.chart.buildChart();
		}
	}, 150 );
}

AutoChartUI.prototype.CreateTimespanUI = function()
{
	var self = this;
	
	if( self.config.data.rawSeries == undefined )
		self.config.data.rawSeries = JSON.parse( JSON.stringify( self.config.data.series ) );

	var container = self.GetOrCreateUIContainer();

	var uiContent = $(	'<div class="range-selectors d-flex mb-1 mb-lg-0 mr-lg-1">' +
							'<a id="range-max" class="range-selector range-selector-first selected">Max</a>' +
							'<a class="range-selector" id="range-10Y">10Y</a>' +
							'<a class="range-selector" id="range-5Y">5Y</a>' +
							'<a class="range-selector" id="range-3Y">3Y</a>' +
							'<a class="range-selector" id="range-1Y">1Y</a>' +
							'<a class="range-selector" id="range-6M">6M</a>' +
							'<a class="range-selector range-selector-last" id="range-3M">3M</a>' +
						'</div>');

	container.append( uiContent );

	// function dateUpdated( date )
	// {
	// 	for( var i = 0; i < self.config.data.rawSeries.length; i++ )
	// 	{
	// 		var data = JSON.parse( JSON.stringify( self.config.data.rawSeries[i].data ) );
	// 		self.config.data.series[i].data = data.filter( function( obj ){
	// 			return obj.x >= date.getTime();
	// 		} );
	// 	}
	// }

	uiContent.find( '.range-selector' ).on( 'click', function(){

		var dataPoints = self.config.data.rawSeries[0].data;
		var startDate = new Date( dataPoints[0].x );
		var endDate = new Date( dataPoints[ dataPoints.length - 1 ].x );

		var date = new Date();

		container.find( '#start-date-cont' ).datepicker( 'setEndDate', endDate );
		container.find( '#end-date-cont' ).datepicker( 'setStartDate', startDate );

		switch( $(this).attr('id') )
		{
			case 'range-10Y':
				date.setFullYear( date.getFullYear() - 10 );
				break;

			case 'range-5Y':
				date.setFullYear( date.getFullYear() - 5 );
				break;

			case 'range-3Y':
				date.setFullYear( date.getFullYear() - 3 );
				break;

			case 'range-1Y':
				date.setFullYear( date.getFullYear() - 1 );
				break;

			case 'range-6M':
				date.setMonth( date.getMonth() - 6 );
				break;

			case 'range-3M':
				date.setMonth( date.getMonth() - 3 );
				break;

			default:
				date = new Date( dataPoints[0].x );
				console.log( date.toString() );
				break;
		}

		startDate = date;

		container.find( '#start-date-cont' ).datepicker( 'update', startDate );
		container.find( '#end-date-cont' ).datepicker( 'update', endDate );

		// container.find( '#start-date-cont' ).trigger('changeDate');
		
		container.find( '#end-date-cont' ).trigger('changeDate');

		container.find( '#start-date-cont' ).datepicker( 'setEndDate', endDate );
		container.find( '#end-date-cont' ).datepicker( 'setStartDate', startDate );

		$(this).siblings().removeClass( 'selected' );
		$(this).addClass( 'selected' );

		// self.dateUpdated();

	} );
}

AutoChartUI.prototype.CreateDateRangeUI = function( hidden )
{
	hidden = ( hidden != undefined ) ? hidden : false;
	var self = this;

	if( self.config.data.rawSeries == undefined )
		self.config.data.rawSeries = JSON.parse( JSON.stringify( self.config.data.series ) );

	var container = self.GetOrCreateUIContainer();

	var display = hidden ? 'd-none' : 'd-flex';

	var uiContent = $(	'<div class="row form-group align-items-center add-on ' + display + '" style="flex:1 0 50%; min-width: 320px; max-width: 900px;">' +
							'<div class="col-12 col-sm-3">' +
								'<div id="start-date-cont" class="input-group date">' +
									'<input id="startDate" class="date-field form-control" type="text" name="startDate" maxlength="10" placeholder="mm/dd/yyyy">' +
									'<div class="input-group-addon">' +
										'<span class="putnamicon putnamicon-calendar"></span>' +
									'</div>' +
								'</div>' +
							'</div>' +
							'<div class="text-center col-form-label pl-1 pl-md-0">to</div>' +
							'<div class="col-12 col-sm-3">' +
								'<div id="end-date-cont" class="input-group date">' +
									'<input id="endDate" class="date-field form-control" type="text" name="endDate" maxlength="10" placeholder="mm/dd/yyyy">' +
									'<div class="input-group-addon">' +
										'<span class="putnamicon putnamicon-calendar"></span>' +
									'</div>' +
								'</div>' +
							'</div>' +
							'<div class="col-12 col-sm-5"><button id = "Download"  type="button" class="btn btn-primary" onclick = "exportCustomIndex()">Download daily values and allocations</button></div>'+
						'</div>' );

	container.append( uiContent );

	var dataPoints = self.config.data.rawSeries[0].data;
	var startDate = new Date( dataPoints[0].x );
	var endDate = new Date( dataPoints[ dataPoints.length - 1 ].x );

	container.find( '#start-date-cont' ).datepicker( { 
		autoclose : true,
		startDate : new Date( dataPoints[0].x ),
		endDate : new Date( dataPoints[ dataPoints.length - 1 ].x )
	} ).on( 'changeDate', function( e ){
		container.find( '.range-selectors .range-selector' ).removeClass( 'selected' );
		container.find( '#end-date-cont' ).datepicker( 'setStartDate', e.date );

		self.dateUpdated();
	} );

	container.find( '#end-date-cont' ).datepicker( { 
		autoclose : true ,
		startDate : new Date( dataPoints[0].x ),
		endDate : new Date( dataPoints[ dataPoints.length - 1 ].x )
	} )
	.on( 'changeDate', function( e ){
		container.find( '.range-selectors .range-selector' ).removeClass( 'selected' );
		container.find( '#start-date-cont' ).datepicker( 'setEndDate', e.date );

		self.dateUpdated();
	} );

	container.find( '#start-date-cont' ).datepicker( 'update', startDate );
	container.find( '#end-date-cont' ).datepicker( 'update', endDate );

	container.find( '#start-date-cont input' ).keydown(function(event) { return false;});
	container.find( '#end-date-cont input' ).keydown(function(event) { return false;});
}

AutoChartUI.prototype.GetOrCreateUIContainer = function()
{
	var self = this;

	var element = self.chartElement;

	if( element.data( 'ui-container' ) )
	{
		return element.data( 'ui-container' );
	}
	else
	{
		var uiContainerId = element.attr( 'id' ) + '-ui-container';	
		if( $( '#' + uiContainerId ).length == 0 )
		{
			self.chartElement.after( '<div id="' + uiContainerId + '" class="d-flex flex-column flex-md-row"></div>' );
		}
		
		return $( '#' + uiContainerId);
	}
}
