function FundSearch(){

	var self = this,
		w = $(window).width(),
		fundLinkBase = "individual",
		$fundSearchContainer = $('.fund-search-container'),
		$fundSearchInput = $('.fund-search-input'),
		$fundSearchIcon = $('.fund-search-icon'),
		$fundSearchSubmit = $('.fund-search-submit'),
		$fundSearchResults = $('.fund-search-results'),
		isCompare = $fundSearchResults.data('compare') ? true : false,
		isDropdown = $fundSearchResults.data('dropdown') ? true : false;

	self.ready = function(){

		if ( $fundSearchContainer.length ){

			/* If user has ever logged into Advisor site */
			if ( typeof $.cookie('ValidAdvisorCookie') !== 'undefined' || window.location.href.indexOf("/advisor") !== -1 ) {
				fundLinkBase = "advisor";
			}

			$fundSearchIcon.click( function(){
				self.submitFundSearch();
			});

			$fundSearchSubmit.click( function( e ){
				e.preventDefault();
				self.submitFundSearch();
			});

			$fundSearchInput.on( 'keyup', function (e) {
				if (e.keyCode == 13) {
					self.submitFundSearch();
				}
			});

			$fundSearchContainer.on( 'click', function(e) {
				e.stopPropagation();
			});
			$fundSearchResults.on( 'click', function(e) {
				e.stopPropagation();
			});

			$fundSearchInput.on("change paste keyup", function(e){
				// console.log("CHANGE/PASTE/KEYUP");
				if (isDropdown && e.keyCode == 8 && !$(this).val()) {
					self.triggerSearch(this, 300, "*");
				} else {
					self.triggerSearch(this);
				}
			});

			if (isDropdown) {
				$fundSearchInput.on( 'focus', function (e) {
					// console.log("FOCUS");
					if ( $(this).val() ) {
						self.triggerSearch(this, 0);
					} else {
						self.triggerSearch(this, 0, "*");
					}
				});
				$(document).on( 'click', function (e) {
					// console.log(e.target);
					if (e.target != ( $fundSearchResults || $fundSearchContainer ) ) {
						clearTimeout( $(this).data('timer') );
						self.clearSearchResults();
					}
				});
			}

		}
	};

	self.resize = function(){
		w = $(window).width();
	};

	self.triggerSearch = function( element, delay, forceSearch) {
		var $this = $(element);
		delay = delay || 300;

		clearTimeout( $this.data('timer'));

		$this.data('timer', setTimeout( function(){
			$this.removeData('timer');
			if( forceSearch ) {
				self.getSearchResults( forceSearch );
			}else if ( $this.val() !== "" && ( $this.val().length > 2 || isDropdown ) ){
				self.getSearchResults( $this.val() );
			} else if (!isDropdown) {
				self.clearSearchResults();
			}
		}, delay));
	};

	self.getSearchResults = function( query ){

		var fundSearchUrl = '/' + fundLinkBase + '/search/search-funds?limit=999&q=',
			fundResults = [];

		$.when(
			$.ajax({
				url: fundSearchUrl + query,
				dataType: 'json',
				method: 'get',
				cache: false,
				success: function(data){
					fundResults = data;
				}
			})
		).then( function(){
			if ( fundResults.length > 0 ){
				self.parseSearchResults( fundResults );
			} else {
				self.clearSearchResults();
			}
		});
		
	};

	self.parseSearchResults = function( fundResults ){
		
		if ( fundResults.length > 0 ){
			
			var fundResultsLength = fundResults.length,
				typedQuery = $fundSearchInput.eq(0).val();
				newHTML = '<hr>';
			if(isCompare) {
				newHTML = '<p class="d-flex justify-content-between"><strong>Putnam fund suggestions</strong><strong><span class="putnamicon putnamicon-lock"></span> Compare in <span class="text-normal">Fund</span>Visualizer®</strong></p>';
				newHTML += '<div class="scrollable">';
			} else {
				newHTML += '<p><strong>Putnam fund suggestions</strong></p>'
			}
			newHTML += '<ul class="list-group list-unstyled">';
			for ( var j=0; j<fundResultsLength; j++ ){
					fundLink = '<a class="px-0" href="/' + fundLinkBase + '/mutual-funds/funds/' + fundResults[j].id + '/?q=' + typedQuery + '">' + fundResults[j].n + '</a>';
				if (isCompare) {
					newHTML += '<li class="d-flex justify-content-between">' + fundLink;
					if (fundResults[j].b) {
						newHTML += '<a class="fund-compare text-right" target="_blank" href="https://www.putnam.com/advisor/tools/fundvisualizer?preselected_fund=' + fundResults[j].t + '" class="text-right">Launch ' + fundResults[j].t + '</a>';
					}
					newHTML += '</li>';
				} else {
					newHTML += '<li>' + fundLink + '</li>';
				}
			}


			if(isCompare) {
				newHTML += '</ul></div>';
				newHTML += '<div class="py-1">Not what you&rsquo;re looking for? <a href="/individual/mutual-funds" data-advisor-href="/advisor/mutual-funds">See all funds</a></div>';
			} else {
				newHTML += '</ul>';
			}
			
			$fundSearchResults.html( newHTML );
			$fundSearchResults.hasClass('hidden') ? $fundSearchResults.removeClass('hidden') : $fundSearchResults.show();
		}
		
	};

	self.clearSearchResults = function(){
		$fundSearchResults.empty();
		$fundSearchResults.hide();
	};

	self.submitFundSearch = function(){
		if ( $fundSearchInput.val() !== "" ) {
			window.location.href = "/" + fundLinkBase + "/search/?source=splash-fund-search&q=" + $fundSearchInput.val();
		} else {
			$fundSearchInput.focus();
		}
	};

	ApplicationObject.call( this );
}
FundSearch.prototype = Object.create( ApplicationObject.prototype );