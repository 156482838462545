/*
* 	Constants
*	These variables never change
*/
var BREAKPOINT_XS = 576,
	BREAKPOINT_SM = 768,
	BREAKPOINT_MD = 992,
	BREAKPOINT_LG = 1200;

/*
* 	Application
*	- Main controller for the objects of this site.
*/
var Application = function()
{

	var self = this;

	self.started = false;
	self.site = null;

	// If URL contains /advisor, /institutional, /dcio or /individual, set the site to that value, else, set it to 'public'
	if ( window.location.pathname.indexOf( '/advisor' ) > -1 ) {
		self.site = 'advisor';
	} else if ( window.location.pathname.indexOf( '/institutional' ) > -1 ) {
		self.site = 'institutional';
	} else if ( window.location.pathname.indexOf( '/dcio' ) > -1 ) {
		self.site = 'dcio';
	} else if ( window.location.pathname.indexOf( '/individual' ) > -1 ) {
		self.site = 'individual';
	} else {
		self.site = 'public';
	}

	self.ready = function()
	{
		self.ajaxContent 			= new AjaxContent();
		self.anchorScroll 			= new AnchorScroll();
		self.authentication 		= new Authentication();
		self.autoChartController	= new AutoChartController();
		self.autoNav 				= new AutoNav();
		self.brightcoveVideos 		= new BrightcoveVideos();
		self.carouselModal			= new CarouselModal();
		self.chartController 		= new ChartController();
		self.collapseTabs 			= new CollapseTabs();
		self.cookies 				= new Cookies();
		self.disclosure				= new Disclosure();
		self.dynamicModal			= new DynamicModal();
		self.externalLinkModal 		= new ExternalLinkModal();
		self.fatNav 				= new FatNav();
		self.thickWhiteNav			= new ThickWhiteNav();
		self.fatSearch 				= new FatSearch();
		self.forms 					= new Forms();
		self.fundScreener 			= new FundScreener();
		self.fundSearch 			= new FundSearch();
		self.fvTable 				= new FVTable();
		// self.globalAlert 		= new GlobalAlert('diversityAlert', { excludeSites: [ '/inclusion' ], dismissible: false, animationDuration: 0 } );
		self.modal 					= new Modal();
		self.polls 					= new Polls();
		self.responsiveCopy 		= new ResponsiveCopy();
		self.slimNav 				= new SlimNav();
		self.schema 				= new Schema();
		self.socialLinks 			= new SocialLinks();

		// Conditional functionality loaded if it's needed on the page
		if ( $( '.sticky-nav' ).length ) { self.stickyNav = new StickyNav(); }
		if ( $( document.body ).hasClass( 'back-to-top' ) ) { self.backToTop = new BackToTop(); }

		self.dispatchEvent( Event.ApplicationReady );
	};

	self.loaded = function()
	{
		setWidth( $(window).width() );
		self.started = true;
		self.dispatchEvent( Event.ApplicationLoaded );
	};

	self.resize = function( new_width )
	{
		setWidth( new_width );
	};

	self.scroll = function( scroll_position )
	{
		self.dispatchEvent( Event.ApplicationScroll, scroll_position );
	};

	function setWidth( width )
	{
		self.width = width + ( window.innerWidth - document.documentElement.clientWidth );
		self.dispatchEvent( Event.ApplicationResize, self.width );
	}

};
Application.prototype = Object.create( EventHandler.prototype );

var application = new Application();
var debug = new Debug( true );

//Application Entrypoint
( function( window, $ )
{
	//OnStart
	$( window ).on( 'load', function(){
		application.loaded();
	} );

	//OnReady
	$( document ).ready( function(){
		application.ready();
	} );

	//OnResize
	$( window ).resize( function(){
		if( application !== null )
			application.resize( $( window ).width() );
	} );

	//OnScroll
	$( window ).scroll( function(){
		if( application !== null )
			application.scroll( $( window ).scrollTop() );
	} );
})(window, jQuery);
