function AnchorScroll()
{
	var self = this,
		// Select all links with hashes. Remove links that don't actually link to anything
		$hashLinks = $('a[href*="#"]').not('[href="#"]').not('[href="#0"]');
		
	self.ready = function()
	{
		$hashLinks.click(function(event) {
			// On-page links
			if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname )
			{
				// Figure out element to scroll to
				var $this 	= $(this);
				var hash 	= this.hash;
				
				// if hash does NOT contain "/", this is used for react single page application routing, and will break
				if ( hash.indexOf('/') == -1 ) {
					var target 	= $(hash);
					target 		= target.length ? target : $('[name=' + this.hash.slice(1) + ']');
					// Disable scrolling if hash is used for other means (BS4 nav, etc)
					var noScroll= $this.hasClass('nav-link') || $this.is('.carousel-control-prev, .carousel-control-next') || ( $this.data('toggle') == 'collapse' );
					// Does a scroll target exist and scrolling isn't disabled?
					if (target.length && !noScroll) {
						// Only prevent default if animation is actually gonna happen
						event.preventDefault();
						// Get data attributes for customization
						var scrollTopPos = $this.data('offset') ? target.offset().top + Number( $this.data('offset') ) : target.offset().top;

						$('html, body').animate({
							scrollTop: scrollTopPos
						}, 1000, function() {
							// Callback after animation
							// Must change focus unless there's a positive offset (causes jumping)
							var $target = $(target);
							var doFocus = Number( $this.data('offset') ) > 0 ? false : true;
							if (doFocus) {
								$target.focus();
								if ($target.is(":focus")) { // Checking if the target was focused
									return false;
								} else {
									$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
									$target.focus(); // Set focus again
								}
							}
							if (typeof PG !== "undefined") {
								PG.hash = hash.substr(1);
								PG.ptnmTabAnchor();
							}
						});
					}
				}
			}
		});
	};

	ApplicationObject.call( this );
}
AnchorScroll.prototype = Object.create( ApplicationObject.prototype );