function Schema()
{

	var self = this,
		$schemaContainer = $('script#dynamic-schema'),
		$slimNavLinks = $("nav.slim-nav-bar ul.navbar-nav>li[data-target*='subnav']>a.nav-link");

	self.ready = function() {
		
		if ( $schemaContainer.length ) {

			//console.log('hi');
			//console.log($schemaContainer);
		}
	};

	ApplicationObject.call( this );
}
Schema.prototype = Object.create( ApplicationObject.prototype );