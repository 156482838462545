function AutoBubbleChart(config)
{
	var self 	= this;

	self.config = config;
	self.chart 	= null;

	self.xPlotLines = [];
	self.yPlotLines = [];

	self.annotations = [{}];

	// Custom config
	if (config.data.custom == 'high-low') {
		self.xPlotLines = [{
			color:'#78B659',
			width:2,
			value:0,
			zIndex:2,
			label:{
				text:'<div class="auto-chart-arrow auto-chart-arrow-up">Higher</div>',
				useHTML:true,
				verticalAlign:'top',
				rotation:360,
				y:-33,
				x:-38
			}
		},{
			width:2,
			value:0,
			zIndex:2,
			label:{
				text:'<div class="auto-chart-arrow auto-chart-arrow-down">Lower</div>',
				useHTML:true,
				verticalAlign:'bottom',
				rotation:360,
				y:12,
				x:-38
			}
		}];
		self.yPlotLines = [{
			color:'#337ABD',
			width:2,
			value:0,
			zIndex:2,
			label:{
				text:'<div class="auto-chart-arrow auto-chart-arrow-left">Lower</div>',
				useHTML:true,
				align:'left',
				y:-11,
				x:-65
			}
		},{
			width:2,
			value:0,
			zIndex:2,
			label:{
				text:'<div class="auto-chart-arrow auto-chart-arrow-right">Higher</div>',
				useHTML:true,
				align:'right',
				y:-11,
				x:65
			}
		}];
	}

	// Annotation config
	if (config.data.annotations == 'tooltip') {
		self.annotations[0].labels = [];
		for (var i = 0; i < config.data.series.length; i++) {
			 var label = {
				point:String(i),
				overflow:'none',
				text: 	'<div>' +
							'<span class="text-uppercase" style="color:{point.series.color}">{point.series.name}</span>' +
							'<hr style="margin:4px 0;">' +
							'<div class="d-flex justify-content-around">' +
								'<div class="x-point">{point.x}</div>' +
								'<span style="color:#ccc">|</span>' +
								'<div class="y-point">{point.y}</div>' +
							'</div>' +
						'</div>'
			}
			if (config.data.series[i].xOffset) {
				label.x = config.data.series[i].xOffset;
			}
			if (config.data.series[i].yOffset) {
				label.y = config.data.series[i].yOffset;
			}
			self.annotations[0].labels.push(label);
		}
		self.annotations[0].labelOptions = {
			backgroundColor:'rgba(255,255,255,0.8)',
			borderColor:'#ccc',
			useHTML:true,
			zIndex:10,
			style: {
				fontFamily: "'Source Sans Variable', system-ui, -apple-system, BlinkMacSystemFont, 'seravek', 'segoe ui', 'roboto', sans-serif",
				fontWeight:600
			}
		}
	}

	AutoChart.call(this);
}

AutoBubbleChart.prototype = Object.create( AutoChart.prototype );
AutoBubbleChart.prototype.constructor = AutoBubbleChart;

// Builds highchart donut chart based on config
AutoBubbleChart.prototype.buildChart = function()
{
	var self 	= this,
		config 	= self.config;
		
	// console.log("AutoBubbleChart :: buildChart :: config", config, "this", self);

	self.chart = new Highcharts.Chart({
		colors:config.data.colors,
		chart:{
			type:'bubble',
			renderTo:config.data.container[0],
			backgroundColor:config.data.bgColor,
			width:config.data.width,
			height:config.data.height
		},
		legend:{
			enabled:false
		},
		credits:{
			enabled:false
		},
		plotOptions:{
			bubble: {
				maxSize:config.data.maxSize,
				tooltip: {
					headerFormat: '<b>{series.name}</b><br>',
					pointFormat: '{point.x} | {point.y} | {point.z}'
				}
			}
		},
		series:config.data.series,
		annotations:self.annotations,
		xAxis: {
			gridLineWidth:1,
			gridLineDashStyle:'Dot',
			gridLineColor:'#cccccc',
			tickWidth:config.data.custom=='high-low' ? 0 : 1,
			lineWidth:config.data.custom=='high-low' ? 0 : 1,
			plotLines:self.xPlotLines,
			labels:{
				enabled:config.data.custom=='high-low' ? false : true
			}
		},
		yAxis: {
			title:{
				text:config.data.custom=='high-low' ? null : undefined
			},
			gridLineDashStyle:'Dot',
			gridLineColor:'#cccccc',
			plotLines:self.yPlotLines,
			labels:{
				enabled:config.data.custom=='high-low' ? false : true
			}
		},
		title:{
			text:config.data.title,
			align:'center',
			verticalAlign:'middle',
			y:-10
		},
		tooltip:{
			enabled:config.data.tooltip
		},
		exporting:{
			enabled:false
		}
	});
}